.aigc-main .aigc-main_body {
  text-align: center;
}

.aigc-main .aigc-main_body .img {
  width: 100%;
}

.aigc-main .aigc-main_body .tabsver .tabt {
  margin: 0.64rem 0 0.32rem;
  padding: 0 4% 0 5%;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: scroll;
}

.aigc-main .aigc-main_body .tabsver .tabt::-webkit-scrollbar {
  display: none;
}

.aigc-main .aigc-main_body .tabsver .tabt .itm {
  width: 1.6rem;
  height: 0.853333rem;
  font-size: 0.426667rem;
  color: #1990ff;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.213333rem;
  border-radius: 0.426667rem;
  background: #e8f4ff;
}

.aigc-main .aigc-main_body .tabsver .tabt .itm:last-child {
  margin-right: 0;
}

.aigc-main .aigc-main_body .tabsver .tabt .selspa {
  color: #fff;
  background: #1990ff;
}

.aigc-main .aigc-main_body .tabsver .tabb img {
  width: 90%;
  margin: 0 auto;
}