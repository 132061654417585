.feedback-wrapper {
  padding: 0 0.64rem;
  background: #f3f4f6;
  height: calc(100vh - 1.6rem);
  overflow-y: scroll;
}

.feedback-wrapper .feedback-head {
  display: flex;
  justify-content: space-between;
}

.feedback-wrapper .feedback-head .edit-wrapper {
  width: 1.066667rem;
  height: 1.066667rem;
  background-color: #409eff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedback-wrapper .feedback-head .edit-wrapper i {
  font-size: 0.533333rem;
  color: #ffffff;
}

.feedback-wrapper .module {
  box-shadow: 0 0.053333rem 0.32rem 0 rgba(0,0,0,0.16);
  padding: 0.373333rem;
  margin: 0.266667rem 0;
  border-radius: 0.133333rem;
}

.feedback-wrapper .module .module-header {
  display: flex;
  align-items: center;
  min-height: 0.533333rem;
  padding-bottom: 0.266667rem;
  margin-bottom: 0.266667rem;
  border-bottom: 0.026667rem dashed #333;
}

.feedback-wrapper .module .module-header .module-widget {
  height: 0.426667rem;
  border-left: 0.133333rem solid #0c8cf6;
}

.feedback-wrapper .module .module-header .module-title {
  margin-left: 0.266667rem;
  font-size: 0.426667rem;
  font-weight: bold;
  color: #121316;
  width: 3.066667rem;
  flex-grow: 1;
  word-break: break-all;
}

.feedback-wrapper .module .module-header .module-score {
  display: flex;
  align-self: flex-end;
  align-items: center;
}

.feedback-wrapper .module .module-description {
  color: #89909e;
  word-break: break-word;
  white-space: pre-wrap;
}

.feedback-wrapper .module .module-question {
  margin: 0.133333rem 0;
}

.feedback-wrapper .module .module-question .question-title {
  font-size: 0.373333rem;
  color: #292c32;
  font-weight: bold;
}

.feedback-wrapper .module .module-question .question-description {
  margin: 0.133333rem 0;
  color: #89909e;
  word-break: break-all;
  white-space: pre-wrap;
}

.feedback-wrapper .module .module-question .question-description-answer pre {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-all;
}

.feedback-wrapper .module .module-question .question-description-answer .red-answer {
  color: #f73131;
  min-width: 0.48rem;
  display: block;
  font-size: 0.373333rem;
}

.feedback-wrapper .module .module-question .question-radio {
  margin-right: 1.6rem;
}

.feedback-wrapper .module .module-question .option-inline {
  margin-top: 0.213333rem;
  display: inline-block;
}

.feedback-wrapper .module .interview-result {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedback-wrapper .module .interview-result .result {
  font-weight: 600;
  color: #f73131;
}

.feedback-wrapper .module .flex-module {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.feedback-wrapper .feedback-footer {
  margin: 0.533333rem 0;
  text-align: right;
}

.feedback-wrapper .text-answer {
  padding: 0.133333rem 0.4rem;
  border: 0.026667rem solid #dcdfe6;
  border-radius: 0.106667rem;
  display: block;
  min-height: 2.133333rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-all;
  margin: 0.266667rem 0;
}

.question-description {
  word-break: break-all;
  white-space: pre-wrap;
}