div {
  box-sizing: border-box;
}

@font-face {
  font-family: 'iconfont';
  src: url("//at.alicdn.com/t/font_2013799_8q8yqs0lakp.eot");
  src: url("//at.alicdn.com/t/font_2013799_8q8yqs0lakp.eot?#iefix") format("embedded-opentype"),url("//at.alicdn.com/t/font_2013799_8q8yqs0lakp.woff2") format("woff2"),url("//at.alicdn.com/t/font_2013799_8q8yqs0lakp.woff") format("woff"),url("//at.alicdn.com/t/font_2013799_8q8yqs0lakp.ttf") format("truetype"),url("//at.alicdn.com/t/font_2013799_8q8yqs0lakp.svg#iconfont") format("svg");
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 0.426667rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.005333rem;
  -moz-osx-font-smoothing: grayscale;
}

.report-content {
  position: relative;
}

.report-content .imgLoading {
  display: flex;
  background: #fff !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}

.report-content .up-and-down-wrapper {
  width: 1.28rem;
  height: 4.853333rem;
  background: #ffffff;
  box-shadow: 0 0 0.213333rem 0 rgba(120,120,120,0.35);
  border-radius: 0.64rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-content .up-and-down-wrapper .text-wrapper {
  width: 0.373333rem;
  font-size: 0.373333rem;
  padding: 0.266667rem 0;
  color: #333333;
}

.report-content .up-and-down-wrapper .up {
  flex: 1;
  display: flex;
  justify-content: center;
}

.report-content .up-and-down-wrapper .up .text-wrapper {
  display: flex;
  align-items: flex-end;
}

.report-content .up-and-down-wrapper .center {
  width: 0.746667rem;
  height: 0.026667rem;
  background-color: #dddddd;
}

.report-content .up-and-down-wrapper .down {
  display: flex;
  justify-content: center;
  flex: 1;
}

.report-content .screen-tips-container {
  transition: right ease 0.3s;
  position: fixed;
  height: 2.08rem;
  background-color: rgba(0,0,0,0.7);
  border-radius: 0.16rem 0 0 0.16rem;
  right: 0;
  top: 3.733333rem;
  display: flex;
  z-index: 2;
}

.report-content .screen-tips-container .screen-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.066667rem;
  padding: 0 0.266667rem;
}

.report-content .screen-tips-container .screen-left img {
  width: 100%;
}

.report-content .screen-tips-container .screen-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.4rem;
  color: #ffffff;
  padding-right: 0.133333rem;
}

.report-content .screen-tips-container-flod {
  right: -2rem;
}

.report-content .top-row {
  font-size: 0.48rem;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ededed;
  padding: 0.16rem;
  color: #aaaaaa;
}

.report-content .refuseDialog {
  width: 100%;
  height: 100%;
}

.report-content .refuseDialog .title {
  color: #333333;
  font-size: 0.4rem;
  font-weight: 600;
  padding: 1vh 0 1vh 0.8rem;
}

.report-content .refuseDialog .van-field {
  width: 100%;
  padding: 0 0.8rem;
  margin: 1vh auto 0 auto;
}

.report-content .refuseDialog .van-field textarea {
  background: #fafafa;
  border: 0.03rem solid #eaeaea;
}

.report-content .refuseDialog .button-group {
  width: 100%;
  padding: 0 0.8rem;
  margin: 2vh auto 0 auto;
  display: flex;
  justify-content: flex-end;
}

.report-content .refuseDialog .button-group div {
  padding: 0.18rem 0.4rem;
  font-size: 0.3rem;
}

.report-content .refuseDialog .button-group .cancle {
  border-radius: 0.06rem;
  border: 0.03rem solid #d9d9d9;
  margin-right: 0.6rem;
}

.report-content .refuseDialog .button-group .confirm {
  background-color: #1890ff;
  color: #ffffff;
}

.report-content .estimate-icon {
  position: fixed;
  width: 2rem;
  height: 2rem;
  z-index: 3 !important;
}

.report-content .estimate-icon img {
  width: 100%;
}

.report-content .share-icon {
  position: fixed;
  z-index: 3 !important;
  width: 2rem;
  height: 2rem;
}

.report-content .share-icon img {
  width: 100%;
}

.report-content .footer2 {
  width: 100%;
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  z-index: 3;
  background-color: #ffffff;
  box-shadow: 0 -0.053333rem 0.106667rem 0 rgba(103,116,140,0.1);
  padding: 0.24rem 0.4rem 0.293333rem 0.4rem;
  display: flex;
  justify-content: space-between;
}

.report-content .footer2 .other-group {
  display: flex;
  font-size: 0.32rem;
}

.report-content .footer2 .other-group img {
  width: 0.533333rem;
}

.report-content .footer2 .other-group .other-icon-item {
  text-align: center;
  white-space: nowrap;
  height: 1.066667rem;
}

.report-content .footer2 .other-group .other-icon-item+.other-icon-item {
  margin-left: 0.533333rem;
}

.report-content .footer2 .btn-group {
  margin-left: 0.266667rem;
  width: 6.933333rem;
  justify-content: space-between;
  display: flex;
  font-size: 0.4rem;
}

.report-content .footer2 .btn-group .btn-item {
  width: 2.133333rem;
  height: 1.066667rem;
  border-radius: 0.56rem;
  text-align: center;
  font-size: 0.48rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-content .footer2 .btn-group .pass {
  color: #1890ff;
  background: #ffffff;
  border: 0.026667rem solid #1890ff;
}

.report-content .footer2 .btn-group .pass-active {
  color: #ffffff;
  background: #1890ff;
  border: none;
}

.report-content .footer2 .btn-group .reject {
  color: #f04d4d;
  background: #ffffff;
  border: 0.026667rem solid #f04d4d;
}

.report-content .footer2 .btn-group .reject-active {
  color: #ffffff;
  background: #f04d4d;
  border: none;
}

.report-content .footer2 .btn-group .wait {
  border: 0.026667rem solid #aaaaaa;
  color: #333333;
}

.report-content .footer2 .btn-group .wait-active {
  color: #ffffff;
  background: #f7b500;
  border: none;
}

.report-content .footer2 .btn-group .disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.report-content .footer {
  height: 1.333333rem;
  width: 100%;
  position: fixed;
  bottom: -0.026667rem;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  z-index: 3;
}

.report-content .footer .van-button {
  width: 50%;
  height: 1.333333rem;
  border: none;
  color: #666666;
  font-size: 0.426667rem;
  border-radius: 0;
  box-sizing: border-box;
}

.report-content .footer .blue {
  color: #ffffff;
  background: #1890ff;
}

.report-content .footer .gray {
  color: #333333;
  background: #efeff4;
}

.report-content .base-info {
  overflow: hidden;
  position: relative;
  background: url("../assets/base-info/baseinfo-bg.png") no-repeat;
  background-size: auto;
  padding: 0.48rem 0.533333rem 0.32rem 0.533333rem;
  border-bottom: 0.026667rem solid #dddddd;
}

.report-content .base-info .base-info_base {
  color: #8996bf;
  font-family: PingFangSC-Regular, PingFang SC;
  margin-bottom: 0.266667rem;
}

.report-content .base-info .base-info_base .change-lanuage {
  position: absolute;
  right: 0.32rem;
  top: 0.426667rem;
  font-size: 0.373333rem;
}

.report-content .base-info .base-info_base .avatar-wrapper {
  position: absolute;
  left: 0.32rem;
  top: 0.4rem;
}

.report-content .base-info .base-info_base .avatar-wrapper .avatar {
  height: 1.813333rem;
  width: 1.813333rem;
  object-fit: cover;
  border-radius: 50%;
}

.report-content .base-info .base-info_base .avatar-wrapper .phone {
  position: absolute;
  bottom: -0.026667rem;
  right: 0;
  height: 0.693333rem;
  width: 0.693333rem;
}

.report-content .base-info .base-info_base .base_name {
  width: calc(100% - 2.933333rem);
  color: #3f4864;
  line-height: 0.586667rem;
  font-size: 0.64rem;
  margin-bottom: 0.133333rem;
  margin-left: 1.866667rem;
}

.report-content .base-info .base-info_base .base_job {
  width: calc(100% - 1.6rem);
  font-size: 0.373333rem;
  line-height: 0.533333rem;
  margin-bottom: 0.133333rem;
  margin-left: 1.92rem;
}

.report-content .base-info .base-info_base .base_detail {
  width: calc(100% - 1.6rem);
  margin-left: 1.866667rem;
  display: flex;
  font-size: 0.373333rem;
  align-items: center;
}

.report-content .base-info .base-info_base .base_detail .base_detail_item {
  display: flex;
  align-items: center;
}

.report-content .base-info .base-info_base .base_detail .base_detail_item img {
  height: 0.426667rem;
  margin-right: 0.106667rem;
}

.report-content .base-info .base-info_base .base_detail .base_detail_item .content {
  white-space: nowrap;
}

.report-content .base-info .base-info_base .base_detail .base_detail_item+.base_detail_item {
  margin-left: 0.426667rem;
}

.report-content .base-info .base-info_ai {
  color: #8996bf;
}

.report-content .base-info .base-info_ai .vip-img {
  white-space: nowrap;
}

.report-content .base-info .base-info_ai .vip-img img {
  width: 100%;
  height: 0.426667rem;
}

.report-content .base-info .base-info_ai .pr-9 {
  font-size: 0.373333rem;
  font-weight: 13.333333rem;
  padding-right: 0.106667rem;
  margin-right: 0.106667rem;
  line-height: 0.373333rem;
  min-width: 1.733333rem;
}

.report-content .base-info .base-info_ai .score {
  flex-wrap: wrap;
}

.report-content .base-info .base-info_ai .score-star {
  font-size: 0.373333rem;
  display: flex;
  line-height: 0.373333rem;
  align-items: center;
}

.report-content .base-info .base-info_ai .score-star .pr-9 {
  font-size: 0.373333rem;
  padding-right: 0.106667rem;
  line-height: 0.373333rem;
  min-width: 1.733333rem;
}

.report-content .base-info .base-info_ai .score-star .pr-10 {
  font-size: 0.373333rem;
  padding-right: 0.106667rem;
  line-height: 0.373333rem;
  margin-bottom: 0.106667rem;
}

.report-content .base-info .base-info_ai .score-star .pr-10 span {
  white-space: nowrap;
}

.report-content .base-info .base-info_ai .score-star .divider {
  width: 0.026667rem;
  max-width: 0.026667rem;
  min-width: 0.026667rem;
  background: #8996bf;
}

.report-content .base-info .base-info_ai .video-star {
  margin-bottom: 0.266667rem;
}

.report-content .base-info .base-info_ai .video-star .video-score {
  width: 45%;
}

.report-content .base-info .base-info_ai .video-star .w100p {
  width: 100%;
}

.report-content .base-info .base-info_ai .video-star .video-divider {
  margin: 0 0.213333rem 0 0.16rem;
}

.report-content .base-info .base-info_ai .video-star>div {
  display: flex;
  align-items: center;
}

.report-content .base-info .base-info_ai .disinh {
  display: inherit;
}

.report-content .base-info .base-info_ai .disinh .video-score {
  width: 100%;
}

.report-content .base-info .base-info_ai .disinh .flexd {
  display: flex;
  align-items: center;
}

.report-content .base-info .base-info_ai .disinh .flexd .van-rate {
  position: relative;
  bottom: 0.026667rem;
}

.report-content .base-info .base-info_ai .disinh .mb10 {
  margin-bottom: 0.266667rem;
  position: relative;
}

.report-content .base-info .base-info_ai .disinh .mb10 .posid {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 400;
  font-size: 0.32rem;
  color: #006afe;
}

.report-content .base-info .base-info_ai .disinh .mb10 .pr-9 {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #8996bf;
  margin-right: 0.106667rem;
}

.report-content .base-info .base-info_ai .disinh .mb10 .enpr9 {
  width: 2.4rem;
  flex: none;
}

.report-content .base-info .base-info_ai .disinh .mb10 .huase {
  font-size: 0.426667rem;
  font-weight: 500;
  color: #ffca3a;
  margin-left: 0.133333rem;
}

.report-content .base-info .base-info_ai .ai-star .ai-score {
  width: 45%;
}

.report-content .base-info .base-info_ai .ai-star .divider {
  margin-right: 0.213333rem;
  margin-left: 0.16rem;
}

.report-content .base-info .base-nfo_tags {
  color: #8996bf;
}

.report-content .base-info .tags {
  width: 94%;
  margin: 0.266667rem auto 0 auto;
}

.report-content .base-info .nofinish {
  display: block;
}

.report-content .base-info .nofinish .warning {
  line-height: 1.333333rem;
  text-align: center;
}

.report-content .base-info .nofinish .warning i {
  font-size: 1.333333rem;
}

.report-content .base-info .nofinish .text {
  font-size: 0.426667rem;
  text-align: center;
}

.report-content .tabs-list {
  width: 100%;
  background-color: #ffffff;
}

.report-content .tabfixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.report-content .fix-tabs-list {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 0.3rem 0 0 0;
  z-index: 10;
}

.report-content .tabs {
  list-style-type: none;
  display: flex;
  overflow-x: auto;
}

.report-content .tabs li {
  font-weight: 500;
  padding: 0.3rem 0rem;
  text-align: center;
  font-size: 0.38rem;
  color: #333333;
  white-space: nowrap;
  flex: 1 0 auto;
}

.report-content .tabs li div {
  padding: 0 0.2rem;
}

.report-content .tabs li:not(:last-child) div {
  border-right: 0.026667rem solid #e6e6e6;
}

.report-content .tabs .active {
  color: #14b1ff;
}

.report-content .small-tabs {
  list-style-type: none;
  display: flex;
  position: relative;
  border-bottom: 0.026667rem solid #f0f0f0;
  padding: 0.2rem 0;
}

.report-content .small-tabs li {
  flex: 1;
  text-align: center;
  font-size: 0.38rem;
  color: #cccccc;
  padding: 0.1rem 0;
}

.report-content .small-tabs .tabs__line {
  transition-duration: 0.3s;
  width: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 0.08rem;
  background-color: #14b1ff;
  border-radius: 0.08rem;
}

.report-content .small-tabs .active {
  color: #14b1ff;
}

.report-content .disnone {
  display: none;
}

.report-content .small-tabs2 {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  border-bottom: 0.026667rem solid #f0f0f0;
  padding: 0.2rem 0;
  overflow: scroll;
}

.report-content .small-tabs2 li {
  min-width: 2rem;
  text-align: center;
  font-size: 0.38rem;
  color: #cccccc;
  padding: 0.1rem 0;
}

.report-content .small-tabs2 .tabs__line {
  transition-duration: 0.3s;
  width: 1.6rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 0.08rem;
  background-color: #14b1ff;
  border-radius: 0.08rem;
}

.report-content .small-tabs2 .active {
  color: #14b1ff;
}

.report-content .small-tabs2::-webkit-scrollbar {
  display: none;
}

.report-content .tab-content {
  width: calc(100% - 0.426667rem);
  padding-bottom: 2rem;
  margin: auto;
  overflow-x: hidden;
}

.show_datepicker {
  text-align: center;
  padding-top: 0.533333rem;
  position: relative;
}

.show_datepicker img {
  margin-bottom: 0.533333rem;
}

.show_datepicker .close-icon {
  position: absolute;
  top: 0.213333rem;
  right: 0.213333rem;
}

.show_datepicker .accept {
  font-size: 0.453333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.show_datepicker .is_interview {
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #dddddd;
}

.show_datepicker .nomore {
  margin-left: 0.32rem;
  margin-top: 0.533333rem;
  margin-bottom: 0.533333rem;
}

.show_datepicker .btns {
  display: flex;
  justify-content: space-around;
}

.show_datepicker .btns .van-button {
  width: 3.733333rem;
}

#mark_label_1::after,
#mark_label_2::after {
  content: attr(data-num);
  position: relative;
  display: inline-block;
  text-align: center;
  position: relative;
  font-size: 0.4rem;
}

#allEstimateList::after {
  content: attr(data-num);
  position: relative;
  height: 0.48rem;
  background: #f56c6c;
  color: #ffffff;
  border-radius: 266.64rem;
  line-height: 0.48rem;
  text-align: center;
  font-size: 0.213333rem;
  top: -1.333333rem;
  left: 0.533333rem;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  padding: 0.053333rem 0.16rem;
}

.hidden-data-num::after {
  display: none !important;
}

.labelscore-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 3.2rem;
  line-height: 0.32rem;
  font-size: 0.32rem;
  padding: 0.266667rem;
}

.labelscore-item .labelscore-item_label {
  font-weight: 400;
  color: #8996bf;
}

.labelscore-item .labelscore-item_value {
  font-weight: 600;
  color: #333333;
}

.repeat-interview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f9fe;
  padding: 0.266667rem;
}

.repeat-interview .re-left {
  font-size: 0.426667rem;
  font-weight: bold;
  line-height: 0.426667rem;
}

.base-info .van-rate {
  flex-wrap: nowrap;
}

.nowrap {
  font-size: 0.346667rem;
  white-space: nowrap;
}