.soft__skills {
  margin: 0.426667rem auto 0;
}

.soft__skills .l2-question {
  margin: 0.533333rem auto 0 auto;
}

.soft__skills .l2-question .l2-q_t {
  margin-bottom: 0.533333rem;
}

.soft__skills .l2-question .l2-q_r {
  height: 6.933333rem;
  width: 9.6rem;
  margin: auto;
  margin-bottom: 0.533333rem;
}

.soft__skills .score-wrapper {
  margin: 0 auto 0 auto;
  padding: 0 0 0.266667rem 0;
}

.soft__skills .score-wrapper .desc {
  margin-top: 0.16rem;
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.533333rem;
}

.soft__skills .score-wrapper .info {
  margin-top: 0.106667rem;
  font-weight: 400;
  font-size: 0.373333rem;
  color: #666666;
  line-height: 0.533333rem;
}

.soft__skills .score-wrapper .process-wrapper {
  margin-top: 0.213333rem;
}

.soft__skills .score-wrapper .score-explain {
  margin-top: 0.213333rem;
  font-weight: 400;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.533333rem;
}

.soft__skills .line1 {
  font-weight: 600;
  font-size: 0.426667rem;
}

.soft__skills .ai__score__title {
  margin-top: 0.32rem;
  padding: 0 0.666667rem;
  font-size: 0.373333rem;
  color: #333333;
  font-weight: 600;
}

.soft__skills .ai__score__desc {
  font-size: 0.373333rem;
  color: #333333;
}

.soft__skills .estimate-group {
  margin-top: 0.266667rem;
}

.soft__skills .estimate-group .estimate-score-wrapper {
  margin: 0.266667rem auto;
  height: 1.6rem;
  background: #ffffff;
  box-shadow: 0.053333rem 0.053333rem 0.266667rem 0 rgba(169,193,205,0.34);
  border-radius: 0.08rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.666667rem;
}

.soft__skills .estimate-group .estimate-score-wrapper .estimate-title {
  width: 3.093333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.533333rem;
}

.soft__skills .estimate-group .estimate-score-wrapper /deep/ .el-rate .el-rate__icon {
  font-size: 0.586667rem;
  margin-right: 0;
}

.soft__skills .eCharts-content {
  width: 90%;
  margin: 0 auto;
  background-color: #fafafa;
}

.soft__skills .eCharts-content #emotion-eCharts {
  box-sizing: border-box;
  background-color: #fafafa;
  width: 100%;
  margin: 1rem auto 0 auto;
  height: 9.333333rem;
}

.soft__skills .emition-total {
  width: 100%;
  display: flex;
  height: 0.533333rem;
  margin-top: 0.266667rem;
  margin-bottom: 0.213333rem;
}

.soft__skills .emition-total .emition-item {
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.soft__skills .emition-dots {
  display: flex;
  flex-wrap: wrap;
}

.soft__skills .emition-dots .dot-item {
  width: 33%;
  display: flex;
  align-items: center;
  line-height: 1.8;
}

.soft__skills .emition-dots .dot-item .dot {
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  margin-right: 0.133333rem;
}

.soft__skills .top-emotion-analysis {
  margin-top: 0.32rem;
  font-weight: 400;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.533333rem;
}

.soft__skills .emotion-row {
  width: 100%;
  margin-top: 0.213333rem;
}

.soft__skills .emotion-row .flexd {
  display: flex;
  align-items: center;
  margin-bottom: 0.106667rem;
}

.soft__skills .emotion-row .dot {
  width: 0.213333rem;
  height: 0.213333rem;
  flex-shrink: 0;
  background: #afb6ca;
  border-radius: 50%;
}

.soft__skills .emotion-row .emotion-text {
  margin-left: 0.106667rem;
  font-weight: 400;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.533333rem;
}

.soft__skills .emotion-row .emotion-text-desc {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #666666;
  line-height: 0.533333rem;
}

.mode-img img {
  height: 0.64rem;
}