/deep/.van-dialog .van-dialog__header {
  padding-bottom: 0.426667rem;
}

/deep/.van-dialog .van-field {
  padding: 0.8rem 0.266667rem 0.8rem 0.666667rem;
}

/deep/.van-dialog .van-field__label {
  width: 2.133333rem;
}

.report-mobile {
  height: 100vh;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  font-size: 0.373333rem;
  position: relative;
}

.report-mobile .font-small {
  font-size: 0.266667rem;
}

.report-mobile .font-12 {
  font-size: 0.32rem;
}

.report-mobile .font-mini {
  font-size: 0.16rem;
  color: #666666;
  text-align: right;
  margin-right: 0.266667rem;
  margin-top: -0.133333rem;
}

.report-mobile .font-blod {
  font-weight: bold;
  font-size: 0.533333rem;
  line-height: 0.746667rem;
  letter-spacing: 0.08rem;
  text-align: center;
  margin: 0.16rem 0 0.533333rem 2.266667rem;
}

.report-mobile .font-blod span {
  width: 7.466667rem;
  display: inline-block;
}

.report-mobile .font-light {
  font-weight: 300;
}

.report-mobile .font-15 {
  font-size: 0.4rem;
}

.report-mobile .margin-bottom-10 {
  margin-bottom: 0.266667rem;
}

.report-mobile .margin-bottom-5 {
  margin-bottom: 0.133333rem;
}

.report-mobile .module {
  padding-left: 0.106667rem;
  padding-bottom: 0.533333rem;
}

.report-mobile .module /deep/ .van-cell__title span {
  font-weight: bold;
}

.report-mobile .module /deep/.van-panel__content {
  padding: 0 0 0 0.426667rem;
}

.report-mobile .module /deep/.van-cell:not(:last-child)::after {
  border-bottom-color: #ededed;
}

.report-mobile .module /deep/.van-steps .van-step__title {
  padding-right: 0.533333rem;
}

.report-mobile .module .ed-time {
  color: #666666;
}

.report-mobile .module .ed-title {
  margin: 0.266667rem 0 0.186667rem;
  color: #333333;
}

.report-mobile .module .ed-content {
  color: #999999;
  text-align: justify;
}

.report-mobile .module .top-emotion {
  padding-right: 0.533333rem;
}

.report-mobile .module .top-emotion .item_li {
  display: flex;
}

.report-mobile .module .top-emotion .item_li span:first-child {
  flex-shrink: 0;
}

.report-mobile .module .card {
  height: 2.666667rem;
  width: 3.146667rem;
  border-radius: 0.16rem;
  background-color: #f9f9f9;
  text-align: center;
  margin-top: 0.533333rem;
  margin-bottom: 0.106667rem;
  color: #333333;
}

.report-mobile .module .right-card {
  box-sizing: border-box;
  padding: 0.266667rem 0.533333rem;
}

.report-mobile .module .right-card div:first-child {
  margin-bottom: 0.4rem;
}

.report-mobile .module .right-card div:nth-child(2) {
  font-size: 0.266667rem;
  margin-bottom: 0.213333rem;
}

.report-mobile .module .right-card .van-progress /deep/ .van-progress__portion {
  background: linear-gradient(90deg, #35c3ff 0%, #1890ff 100%);
}

.report-mobile .module .right-card .van-row:last-child {
  color: #666666;
  font-size: 0.16rem;
  margin-top: 0.133333rem;
}

.report-mobile .module .ul {
  font-size: 0.32rem;
  font-weight: 300;
  color: #666666;
  list-style: disc;
  list-style-position: inside;
}

.report-mobile .module .ul li {
  margin: 0.133333rem 0;
}

.report-mobile /deep/ .module:after {
  border-bottom: 0.266667rem solid #f4f4f4;
}

.report-mobile .van-nav-bar {
  position: sticky;
  top: 0;
}

.report-mobile /deep/ .van-overlay {
  display: flex;
  align-items: center;
}

.report-mobile .wrappe img {
  width: 100%;
  object-fit: contain;
}

.report-mobile .card_info {
  margin-bottom: 0.266667rem;
}

.report-mobile .card_info .info {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.report-mobile .card_info .info img {
  width: 49%;
  margin-bottom: 0.266667rem;
  max-height: 3.466667rem;
  object-fit: contain;
}

.report-mobile section {
  height: calc(100vh - 1.466667rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.report-mobile section pre {
  word-break: break-word;
  white-space: pre-wrap;
}

.report-mobile section .spot {
  width: 0.08rem;
  height: 0.08rem;
  background: #1890ff;
  border: 0.106667rem solid #b9ddff;
  border-radius: 100%;
}

.report-mobile section .justify {
  text-align: justify;
  display: inline-block;
}

.report-mobile section .base-info {
  position: relative;
  height: 4.8rem;
  color: #ffffff;
  background: url("../assets/top@2x.png") no-repeat;
  background-size: 100% 4.8rem;
  margin-bottom: 0.266667rem;
}

.report-mobile section .base-info .logo {
  width: 0.8rem;
  position: absolute;
  top: 0.373333rem;
  left: 0.373333rem;
}

.report-mobile section .base-info .picture {
  position: absolute;
  bottom: -0.533333rem;
  left: 0.8rem;
  width: 2.133333rem;
  height: 2.133333rem;
  z-index: 1;
  box-shadow: 0 0 0.053333rem 0.133333rem rgba(255,255,255,0.4);
  border-radius: 100%;
}

.report-mobile section .base-info .info-content {
  padding-top: 0.533333rem;
  margin-bottom: 0.4rem;
}

.report-mobile section .base-info .info-content .abs {
  margin-left: 4.266667rem;
}

.report-mobile section .padne-score {
  color: #1890ff;
  font-size: 0.4rem;
}

.report-mobile section .cell {
  border-bottom: 0.026667rem solid #ededed;
  color: #333333;
  margin-top: 0.426667rem;
  padding-right: 0.533333rem;
}

.report-mobile section .cell .cell-title {
  font-size: 0.32rem;
  display: flex;
  align-items: center;
}

.report-mobile section .cell .cell-title div:first-child {
  margin-right: 0.32rem;
  width: 1.733333rem;
}

.report-mobile section .cell .cell-title div:last-child {
  flex: 1;
}

.report-mobile section .cell .cell-content {
  color: #666666;
  font-size: 0.266667rem;
  margin: 0.266667rem 0;
}

.report-mobile section .cell .cell-score {
  color: #1890ff;
  font-size: 0.373333rem;
}

.report-mobile section .cell /deep/ .van-progress {
  border-radius: 0 0.186667rem 0.186667rem 0;
}

.report-mobile section .cell /deep/ .van-progress .van-progress__pivot {
  background: transparent;
  position: unset;
  float: right;
  transform: translate(0.266667rem, -20%);
}

.report-mobile section .cell .progress-blue /deep/ .van-progress__portion {
  background: linear-gradient(90deg, #35c3ff 0%, #1890ff 100%);
}

.report-mobile section .cell .progress-red /deep/ .van-progress__portion {
  background: linear-gradient(90deg, #ffa193 0%, #ff5c44 100%);
}

.report-mobile section .cell .progress-green /deep/ .van-progress__portion {
  background: linear-gradient(90deg, #b4f096 0%, #71c74d 100%);
}

.report-mobile .footer {
  height: 1.333333rem;
  width: 100%;
  position: fixed;
  bottom: -0.026667rem;
}

.report-mobile .footer .van-button {
  width: 50%;
  height: 1.333333rem;
  border: none;
  color: #666666;
  font-size: 0.426667rem;
  border-radius: 0;
}

.report-mobile .footer .van-button:first-child {
  color: #ffffff;
  background: #1890ff;
}

.report-mobile .foot {
  padding: 1.546667rem 0.533333rem;
  background: url("../assets/footer@2x.png") no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  text-align: justify;
}

.report-mobile .foot h4 {
  color: #ffffff;
  font-size: 0.4rem;
  margin-bottom: 0.32rem;
  margin-top: 0.533333rem;
}

.report-mobile .foot div,
.report-mobile .foot p {
  font-weight: lighter;
  font-size: 0.346667rem;
}

.report-mobile .interview {
  height: 100%;
}

.report-mobile .interview .dot {
  width: 0.106667rem;
  height: 0.106667rem;
  background: #1890ff;
  border: 0.106667rem solid #b9ddff;
  border-radius: 100%;
  box-sizing: content-box;
}

.report-mobile .interview .btn-wrapper {
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  color: #1890ff;
}

.report-mobile .interview .btn-wrapper .new-btn {
  text-align: center;
  height: 1.813333rem;
  line-height: 1.813333rem;
  width: 8rem;
  margin: 0 auto;
  background: #ffffff;
  border: 0.026667rem solid #dcdfe6;
  box-shadow: 0.133333rem 0 0.133333rem 0 rgba(0,0,0,0.2);
  cursor: pointer;
}

.report-mobile .interview .title {
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.4rem;
}

.report-mobile .interview .log {
  margin: 0.8rem 2.64rem 0 0p;
}

.report-mobile .interview .log /deep/.el-timeline-item__node {
  background: #1890ff;
  border: 0.106667rem solid rgba(24,144,255,0.3);
}

.report-mobile .interview .log /deep/.el-timeline-item__tail {
  border-left: 0.026667rem solid #ededed;
}

.report-mobile .interview .log /deep/.el-icon-edit-outline,
.report-mobile .interview .log /deep/.el-icon-s-promotion {
  color: #9fd0fe;
  font-size: 0.48rem;
  cursor: pointer;
}

.report-mobile .interview .log .no-response /deep/.el-timeline-item__timestamp:after {
  content: '\672a\54cd\5e94';
  color: #fda655;
}

.report-mobile .interview .log .tip {
  position: absolute;
  top: 0;
  right: 0.026667rem;
  padding: 0.08rem;
  color: #fff;
  background: red;
  font-size: 0.32rem;
}

.report-mobile .interview .log .accept /deep/.el-timeline-item__timestamp:after {
  content: '\63a5\53d7';
  color: #5dd7ae;
}

.report-mobile .interview .log .reject /deep/.el-timeline-item__timestamp:after {
  content: '\62d2\7edd';
  color: red;
}

.report-mobile .interview .log .red {
  color: red !important;
}

.report-mobile .interview .log .green {
  color: #1985ff !important;
}

.report-mobile .interview .log .orange {
  color: orange;
}

.report-mobile .interview .log .el-card {
  background: #fafafa;
}

.report-mobile .interview .log .el-card /deep/.el-card__body {
  position: relative;
}

.report-mobile .interview .log .item {
  font-size: 0.373333rem;
  color: #333333;
  margin: 0 0 0.266667rem 0.133333rem;
}

.report-mobile .interview .log .item p {
  margin-bottom: inherit;
  margin-top: inherit;
}

.report-mobile .interview .log .item p .value {
  word-break: break-word;
}

.report-mobile .interview .log .item:last-child {
  margin-bottom: 0;
}

.report-mobile .interview .log .item:after {
  content: '';
  clear: both;
  display: block;
}

.report-mobile .interview .log .item .label {
  display: inline-block;
  text-align: right;
  margin-right: 1.066667rem;
  color: #666;
}