.pdp-test .pdp-test-content .pdp-test-report {
  width: 90%;
  margin: 0 auto;
}

.pdp-test .pdp-test-content .pdp-test-report .ptr-result {
  font-size: 0.48rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.266667rem;
}

.pdp-test .pdp-test-content .pdp-test-report table {
  width: 100%;
  border-spacing: 0;
}

.pdp-test .pdp-test-content .pdp-test-report table .not-show {
  opacity: 0;
}

.pdp-test .pdp-test-content .pdp-test-report table tr {
  position: relative;
}

.pdp-test .pdp-test-content .pdp-test-report table tr th {
  border-right: 0.026667rem solid;
  border-bottom: 0.026667rem solid;
  border-top: 0.026667rem solid;
}

.pdp-test .pdp-test-content .pdp-test-report table tr td {
  text-align: center;
  border-right: 0.026667rem solid;
  border-bottom: 0.026667rem solid;
  position: relative;
}

.pdp-test .pdp-test-content .pdp-test-report table tr .first-td {
  border-left: 0.026667rem solid;
}

.pdp-test .pdp-test-content .pdp-test-report table tr .row-first-td {
  border-left: 0.026667rem solid;
}

.pdp-test .pdp-test-content .pdp-test-report table .first-t-tr th {
  border-top: 0.026667rem solid;
}

.pdp-test .pdp-test-content .pdp-test-report table .first-t-tr th:first-child {
  border-left: 0.026667rem solid;
}

.pdp-test .pdp-test-content .pdp-test-report table .row-second-tr td {
  border-top: 0.026667rem solid;
}

.pdp-test .pdp-test-content .pdp-test-report .zj-prepare {
  margin-bottom: 0.533333rem;
  margin-top: 0.533333rem;
}

.pdp-test .pdp-test-content .no-data {
  width: 4.8rem;
  margin: 1.44rem auto 0 auto;
  text-align: center;
}

.pdp-test .pdp-test-content .no-data img {
  width: 100%;
}