video {
  width: 100%;
  height: 5.333333rem;
  margin: 0.266667rem 0;
}

.other-question {
  padding: 0.5rem 0.3rem 0 0.3rem;
}

.other-question .single-problem-estimate-wrapper {
  margin-top: 0.2rem;
}

.other-question .single-problem-estimate-wrapper .single-problem-estimate {
  border: 0.026667rem solid #cccccc;
  border-radius: 0.133333rem;
  padding: 0.2rem 0.3rem;
}

.other-question .single-problem-estimate-wrapper .single-problem-estimate .title {
  display: flex;
  align-items: center;
}

.other-question .single-problem-estimate-wrapper .single-problem-estimate .title .van-rate {
  margin-left: 0.4rem;
}

.other-question .single-problem-estimate-wrapper .single-problem-estimate /deep/ textarea {
  margin-top: 0.2rem;
  border: 0.026667rem solid #c8c9cc;
  border-radius: 0.106667rem;
  padding: 0.266667rem;
  transform: translateX(0.8);
  transform: translateX(10rpx);
  box-sizing: border-box;
}

.other-question .single-problem-estimate-wrapper .single-problem-estimate .btn-list {
  width: 100%;
  display: flex;
  color: #ffffff;
  margin-top: 0.3rem;
  text-align: center;
}

.other-question .single-problem-estimate-wrapper .single-problem-estimate .btn-list .submit {
  padding: 0.2rem 0;
  width: 2rem;
  background-color: #00aaff;
  border-radius: 0.133333rem;
}

.other-question .single-problem-estimate-wrapper .single-problem-estimate .btn-list .cancle {
  border-radius: 0.133333rem;
  padding: 0.2rem 0;
  width: 2rem;
  margin-left: 0.5rem;
  background-color: #00aaff;
}

.other-question .single-problem-estimate-wrapper .suggerlog-wrapper {
  margin-top: 0.2rem;
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
}

.other-question .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog {
  padding: 0.2rem 0;
}

.other-question .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line1 {
  display: flex;
  justify-content: space-between;
}

.other-question .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line1 .left {
  display: flex;
  align-items: center;
}

.other-question .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line1 .left .name {
  max-width: 1.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.other-question .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line1 .left .dafen {
  margin-left: 0.1rem;
}

.other-question .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line2 {
  margin-top: 0.2rem;
}

.other-question .question-type-title {
  text-align: left;
  width: 100%;
  font-size: 0.4rem;
  padding: 0.2rem 0;
  font-weight: 600;
}

.other-question .question-content {
  display: flex;
}

.other-question .sub-title {
  position: relative;
}

.other-question .noWifiTip {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.373333rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5.866667rem;
}

.other-question .noWifiTip .text {
  color: #ffffff;
}

.other-question .noWifiTip .paly-btn {
  margin-top: 0.266667rem;
  color: #ffffff;
  width: 0.8rem;
}

.other-question .noWifiTip .paly-btn img {
  width: 100%;
}

.other-question .inner-video-player {
  position: relative;
  width: 100%;
}

.other-question .small-tips {
  position: absolute;
  bottom: 20%;
  left: 0;
  color: #ffffff;
  z-index: 10;
  animation-name: enterIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  background-color: rgba(0,0,0,0.5);
}

@keyframes enterIn {
  0% {
    transform: translateX(-1000vw);
  }

  10% {
    transform: translateX(0);
  }

  95% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.other-question video {
  width: 100%;
  height: 5.333333rem;
  margin: 0.266667rem 0;
}

.other-question .score {
  padding: 0 0.533333rem;
}

.other-question .score div {
  color: #666;
}

.other-question .score .test {
  margin: 0.266667rem 0;
  line-height: 0.533333rem;
}

.other-question section .gray {
  color: #666666;
}

.other-question section .green {
  color: #6ed401;
}

.other-question section .red {
  color: #ff0000;
}

.other-question section .yellow {
  color: #f4d75e;
}

.other-question section .line-20 {
  line-height: 0.533333rem;
}

.other-question section .video {
  width: 100%;
  height: 5.333333rem;
  margin: 0.266667rem 0;
}

.other-question section pre {
  word-break: break-word;
  white-space: pre-wrap;
}

.other-question section /deep/ .van-tabs .van-tabs__line {
  background: #1890ff;
}

.other-question section /deep/ .van-tabs .van-tab--active {
  font-weight: 550;
}

.other-question section /deep/ .van-tabs .van-tabs__content {
  padding-bottom: 1.066667rem;
}

.other-question section .comlun {
  flex-direction: column;
  justify-content: space-between;
}

.other-question section /deep/ .obj-pagination {
  justify-content: center;
  position: fixed;
  bottom: 0.533333rem;
  left: 50%;
  transform: translateX(-50%);
}

.other-question section /deep/ .obj-pagination .van-pagination__page {
  flex-grow: 1;
}

.other-question section /deep/ .obj-pagination li:last-child {
  display: none;
}

.other-question section /deep/ .obj-pagination li:first-child {
  display: none;
}

.other-question section /deep/ .obj-pagination li {
  cursor: pointer;
  width: 1.066667rem;
  height: 1.066667rem;
  border-radius: 0.08rem;
  margin: 0 0.213333rem;
  border: 0.026667rem solid #ddd;
}

.other-question section .video-title {
  padding: 0 0.533333rem;
}

.other-question section .obj-title {
  font-size: 0.32rem;
  color: #666666;
}

.other-question section .obj-answer {
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  padding: 0.373333rem;
}

.other-question section .obj-answer div:first-child {
  color: #333333;
  font-weight: 500;
}

.other-question section .sub-answer {
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  margin-top: 0.266667rem;
}

.other-question section .sub-answer div:first-child {
  color: #333333;
  font-weight: 500;
}

.other-question section /deep/.van-panel .van-panel__header {
  padding-top: 0.373333rem;
  padding-bottom: 0.373333rem;
}

.other-question section /deep/.van-panel .van-panel__header .van-cell__title {
  font-size: 0.4rem;
  color: #333333;
  font-weight: 550;
}

.other-question section /deep/.van-panel .van-panel__content {
  padding: 0 0.533333rem;
}

.other-question section /deep/ .van-panel::after {
  content: none;
}

.other-question section .question /deep/ img {
  max-width: 100%;
}

.other-question section .question .video {
  max-width: 100%;
}