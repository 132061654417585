.question-content {
  position: relative;
  overflow: hidden;
}

.question-content pre {
  white-space: pre-wrap;
}

.question-content .question-content-arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 0.853333rem;
  background-image: linear-gradient(-180deg, rgba(255,255,255,0) 0%, #fff 85%);
  cursor: pointer;
}

.question-content .question-content-arrow .icon-arrow {
  font-size: 0.426667rem;
  font-weight: bold;
  color: #409eff;
  transform: rotate(270deg);
  transition: all 0.3s;
}

.question-content .question-content-arrow .icon-arrow.expand {
  transform: rotate(-270deg);
}