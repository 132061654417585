.moduleTitle {
  height: 1.28rem;
  margin-top: 0.64rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F6F7F8;
}

.moduleTitle .logou {
  width: 0.613333rem;
  height: 0.613333rem;
  margin-right: 0.133333rem;
}

.moduleTitle span {
  font-size: 0.64rem;
  font-weight: 500;
  color: #1890FF;
}

.moduleTitle .un_fold {
  width: 0.533333rem;
  height: 0.533333rem;
  position: absolute;
  right: 0.426667rem;
  cursor: pointer;
}

.moduleTitle .roate180 {
  transform: rotate(180deg);
}

.mt16 {
  margin-top: 0.426667rem;
}

.aigrade {
  margin-top: 0.426667rem;
  padding: 0.426667rem;
  background: #f3f9fe;
  border-radius: 0.213333rem;
}

.aigrade .agtle {
  font-size: 0.48rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}

.aigrade .agtag {
  width: 0.853333rem;
  height: 0.533333rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.106667rem;
  background: linear-gradient(90deg, #35c3ff 0%, #1890ff 100%);
}

.aigrade .agtit {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.586667rem;
  margin: 0.106667rem 0 0.213333rem;
}

.tattooResults {
  margin-top: 0.533333rem;
}

.tattooResults .tattooResults_content {
  font-size: 0.373333rem;
  line-height: 0.64rem;
  margin-top: 0.426667rem;
}

.tattooResults .videoScreenshotUrl {
  height: 3.84rem;
  width: 2.56rem;
  background: #ccc;
  position: relative;
  border-radius: 0.213333rem;
  margin-right: 0.266667rem;
  z-index: 1;
  cursor: pointer;
}

.tattooResults .videoScreenshotUrl .caret {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.64rem;
  line-height: 0.64rem;
  background: #666;
  border-radius: 0 0 0.213333rem 0.213333rem;
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #fff;
}

.tattooResults .videoScreenshotUrl .caret i {
  font-size: 0.373333rem;
  margin-right: 0.08rem;
}