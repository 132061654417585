.meritas-report {
  margin-top: 0.533333rem;
  padding: 0 0.106667rem;
}

.meritas-report .module-card {
  margin-bottom: 0.96rem;
}

.meritas-report .module-card .module-title {
  font-size: 0.533333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.746667rem;
  margin-bottom: 0.426667rem;
}

.meritas-report .module-card .module-title.left-icon {
  position: relative;
  padding-left: 0.24rem;
}

.meritas-report .module-card .module-title.left-icon::before {
  content: '';
  position: absolute;
  width: 0.106667rem;
  height: 0.48rem;
  background: #1890ff;
  border-radius: 0.053333rem;
  left: 0;
  top: 0.106667rem;
}

.meritas-report .module-card .module-content {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
}

.meritas-report .module-card .module-content.flex {
  display: flex;
}

.meritas-report .module-card .module-content .row {
  margin-bottom: 0.4rem;
  display: flex;
}

.meritas-report .module-card .module-content .row .col {
  flex-grow: 1;
  display: flex;
  width: 50%;
  white-space: nowrap;
}

.meritas-report .module-card .module-content .row .col .label {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #6d7278;
  line-height: 0.533333rem;
  flex-shrink: 0;
}

.meritas-report .module-card .module-content .row .col .label.fixed-width {
  width: 4.266667rem;
}

.meritas-report .module-card .module-content .row .col .content {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
}

.meritas-report .module-card .module-content .row .col .content.grow {
  flex-grow: 1;
}

.meritas-report .module-card .module-content .row .col .content.large {
  font-size: 0.586667rem;
}

.meritas-report .module-card .module-content .row .col .content.bold {
  font-weight: 500;
}

.meritas-report .module-card .module-content .row .col .content .score {
  font-weight: 600;
  margin-left: 0.4rem;
}

.meritas-report .module-card .module-content .row .col .content .triangle {
  float: right;
  border-top: 0.213333rem solid #1991ff;
  border-right: 0.133333rem solid transparent;
  border-left: 0.133333rem solid transparent;
  margin-top: 0.16rem;
  transition: transform 0.2s;
}

.meritas-report .module-card .module-content .row .col .content .triangle.fold {
  transform: rotate(90deg);
}

.meritas-report .module-card .module-content .card {
  height: 2.133333rem;
  background: #f9f7f9;
  border-radius: 0.16rem;
  padding: 0.426667rem 0.32rem;
  font-size: 0.373333rem;
  font-weight: 500;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-top: -0.133333rem;
  transition: all 0.2s;
  overflow: hidden;
}

.meritas-report .module-card .module-content .card.fold {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.meritas-report .module-card .module-content .card p {
  margin: 0;
}

.meritas-report .module-card .module-content .card p:not(:last-child) {
  margin-bottom: 0.213333rem;
}

.meritas-report .module-card .module-content .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.586667rem;
}

.meritas-report .module-card .module-content .top .detail {
  position: relative;
  font-size: 0.4rem;
  font-weight: 400;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  padding-left: 0.373333rem;
  margin-bottom: 0.24rem;
}

.meritas-report .module-card .module-content .top .detail::before {
  content: '';
  position: absolute;
  width: 0.213333rem;
  height: 0.213333rem;
  border-radius: 50%;
  left: 0;
  top: 0.16rem;
  background: #1991ff;
}

.meritas-report .module-card .module-content .top .detail .score {
  font-size: 0.4rem;
  font-weight: 600;
  color: #333333;
  margin-right: 0.346667rem;
}

.meritas-report .module-card .module-content .bottom {
  height: 6.666667rem;
}

.table-wrapper .table-wrapper_item {
  border-bottom: 0.026667rem solid #dddddd;
}

.table-wrapper .table-wrapper_item .dimension {
  font-size: 0.373333rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
}

.table-wrapper .table-wrapper_item .character {
  display: flex;
  justify-content: space-between;
}

.table-wrapper .table-wrapper_item .character .character-item {
  max-width: 40%;
  font-size: 0.32rem;
  font-weight: 400;
  color: #afb6ca;
  line-height: 0.453333rem;
  margin-top: 0.266667rem;
}

.table-wrapper .table-wrapper_item .comment {
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-top: 0.533333rem;
  padding-bottom: 0.773333rem;
}

.table-wrapper .table-wrapper_item+.table-wrapper_item {
  margin-top: 0.8rem;
}