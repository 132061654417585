.estimate-dialog .estimate-top-row {
  position: sticky;
  top: 0;
  width: 100%;
  color: #333333;
  font-size: 0.48rem;
  padding: 0.266667rem 0.533333rem 0.266667rem 0.533333rem;
  font-weight: 600;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.estimate-dialog .refuseDialog {
  padding: 0.533333rem 0.533333rem 0.533333rem 0.533333rem;
}

.estimate-dialog .refuseDialog .text-description {
  font-size: 0.35rem;
  color: #000000;
}

.estimate-dialog .refuseDialog .radio-wrapper {
  margin-top: 0.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.estimate-dialog .refuseDialog .radio-wrapper .radio-item {
  font-size: 0.35rem;
  width: 26vw;
  padding: 0.2rem 0;
  text-align: center;
  border: 0.026667rem solid #dcdfe6;
}

.estimate-dialog .refuseDialog .radio-wrapper .active {
  background-color: #14b1ff;
  color: #ffffff;
}

.estimate-dialog .refuseDialog .rate-wrapper {
  margin-top: 0.266667rem;
}

.estimate-dialog .refuseDialog .van-field {
  margin-top: 0.266667rem;
  background-color: #fafafa;
  padding: 0;
}

.estimate-dialog .refuseDialog .more-estimate-row {
  margin-top: 0.266667rem;
  text-align: right;
  color: #14b1ff;
  font-size: 0.426667rem;
}

.estimate-dialog .refuseDialog .more-estimate-row span {
  text-decoration: underline;
}

.estimate-dialog .refuseDialog .btn-wrapper {
  margin-top: 0.266667rem;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}

.estimate-dialog .refuseDialog .btn-wrapper .cancle {
  width: 50%;
  text-align: center;
  line-height: 1.333333rem;
  background: #efeff4;
  font-size: 0.35rem;
}

.estimate-dialog .refuseDialog .btn-wrapper .confirm-btn {
  font-size: 0.35rem;
  background-color: #1890ff;
  color: #ffffff;
  width: 50%;
  text-align: center;
  line-height: 1.333333rem;
}

.estimate-dialog .refuseDialog .estimate-row {
  box-sizing: border-box;
  width: 100%;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimate-dialog .refuseDialog .estimate-row .submit-btn {
  cursor: pointer;
  padding: 0.2rem;
  width: 2rem;
  color: #ffffff;
  background-color: #00aaff;
  text-align: center;
  font-size: 0.4rem;
}

.estimate-dialog .refuseDialog .desc {
  padding: 0.2rem 0;
  text-align: left;
}

.estimate-dialog .refuseDialog .textarea-wrapper {
  width: 100%;
  box-sizing: border-box;
}

.estimate-dialog .refuseDialog .textarea-wrapper /deep/ textarea {
  margin-top: 0.2rem;
  border: 0.026667rem solid #c8c9cc;
  border-radius: 0.106667rem;
  padding: 0.266667rem;
  transform: translateX(0.8);
  transform: translateX(10rpx);
  box-sizing: border-box;
}

.estimate-dialog .refuseDialog .suggerlog-wrapper {
  margin-top: 0.2rem;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
}

.estimate-dialog .refuseDialog .suggerlog-wrapper .suggerlog {
  padding: 0.2rem 0;
}

.estimate-dialog .refuseDialog .suggerlog-wrapper .suggerlog .line1 {
  display: flex;
  justify-content: space-between;
}

.estimate-dialog .refuseDialog .suggerlog-wrapper .suggerlog .line1 .left {
  display: flex;
  align-items: center;
}

.estimate-dialog .refuseDialog .suggerlog-wrapper .suggerlog .line1 .left .name {
  max-width: 1.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.estimate-dialog .refuseDialog .suggerlog-wrapper .suggerlog .line1 .left .dafen {
  margin-left: 0.1rem;
  font-weight: 600;
}

.estimate-dialog .refuseDialog .suggerlog-wrapper .suggerlog .line2 {
  margin-top: 0.2rem;
}

.estimate-dialog .refuseDialog .suggerlog-wrapper .suggerlog .line2 .pingjia {
  font-weight: 600;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper {
  margin-top: 1rem;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item {
  margin-top: 0.5rem;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .top-title-row {
  display: flex;
  justify-content: space-between;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .top-title-row .name {
  font-size: 0.35rem;
  color: #000000;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .top-title-row .date {
  font-size: 0.35rem;
  color: #666666;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper {
  margin-top: 0.3rem;
  background-color: #fafafa;
  padding: 0.3rem;
  font-size: 0.35rem;
  color: #666666;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .content-desc {
  font-size: 0.35rem;
  color: #000000;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .result-row {
  margin-top: 0.3rem;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .result-row .text {
  margin-left: 0.3rem;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .rate-row {
  margin-top: 0.3rem;
  display: flex;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .rate-row .van-rate {
  margin-left: 0.3rem;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .estimate-content-row {
  margin-top: 0.3rem;
}

.estimate-dialog .refuseDialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .estimate-content-row .text {
  margin-left: 0.3rem;
}

.estimate-dialog .refuseDialog .dialog-wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 1rem 0;
}