.question-title .objective-answer pre,
.question-answer .objective-answer pre {
  font-size: 0.426667rem;
}

.question-title pre,
.question-answer pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', 'WenQuanYi Micro Hei', sans-serif;
}

.question-title pre img,
.question-answer pre img {
  max-width: 100%;
}

.question-title .green,
.question-answer .green {
  color: #6ed401;
}

.question-title .red,
.question-answer .red {
  color: #ff0000;
}

.question-title .yellow,
.question-answer .yellow {
  color: #f4d75e;
}

.question-type {
  margin-top: 0.213333rem;
  font-weight: bold;
}

.question-answer {
  margin-top: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  border-radius: 0.133333rem;
  border: 0.026667rem dashed #dddddd;
}

.question-answer .answer-detail {
  display: flex;
  color: #c1cad9;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.question-answer .answer-detail .question-tag {
  border-radius: 0.053333rem;
  background-color: #5facff;
  color: #ffffff;
  padding: 0.08rem 0.32rem;
}

.question-answer .right-answer-wrapper .right-key {
  padding-left: 0.533333rem;
}

.option-wrapper {
  padding-left: 0.533333rem;
}