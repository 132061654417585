.cognitive-ability {
  margin-top: 0.533333rem;
}

.line1 .line1-title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.213333rem;
}

.line1 .line1-content {
  font-size: 0.373333rem;
  color: #666666;
  line-height: 0.64rem;
  margin-top: 0;
  margin-bottom: 0;
}

.indicator-wrappper {
  margin: 0.533333rem auto 0 auto;
  position: relative;
}

.indicator-wrappper .percentile-score {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.266667rem;
}

.indicator-wrappper .percentile-score span {
  color: #1890ff;
}

.score-explain {
  margin: 0.533333rem auto 0 auto;
  border-radius: 0.266667rem;
  border: 0.026667rem dashed #d6dde8;
  padding: 0.266667rem;
  line-height: 0.64rem;
  color: #666666;
  font-size: 0.373333rem;
}

.score-explain .title {
  font-weight: 600;
  color: #333333;
}

.score-explain .show-full-explain {
  text-align: right;
  cursor: pointer;
  color: #1890ff;
}

.score-explain pre {
  white-space: pre-wrap;
  word-break: break-word;
  font-family: auto;
  margin: 0.133333rem 0;
}