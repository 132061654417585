.company-awards {
  background: url("~@/assets/images/company-awards/bg.png");
  background-size: cover;
  color: #ffffff;
  text-align: center;
}

.company-awards .company-awards_img {
  margin-top: 0.533333rem;
}

.company-awards .company-awards_title {
  font-size: 0.64rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.746667rem;
  margin-bottom: 1.333333rem;
}

.company-awards .company-time {
  margin: auto;
}

.company-awards .company-time .time-line {
  display: flex;
  align-items: center;
  margin-bottom: 0.533333rem;
}

.company-awards .company-time .time-line .line-s {
  width: 0.746667rem;
  height: 0.053333rem;
  background: rgba(255,255,255,0.15);
}

.company-awards .company-time .time-line .line-item {
  display: flex;
  align-items: center;
}

.company-awards .company-time .time-line .line-item .line-dot {
  position: relative;
  cursor: pointer;
}

.company-awards .company-time .time-line .line-item .line-dot .label {
  position: absolute;
  top: -0.746667rem;
  left: -0.266667rem;
  font-size: 0.373333rem;
  font-weight: 600;
  color: rgba(255,255,255,0.85);
  line-height: 0.48rem;
}

.company-awards .company-time .time-line .line-item .line-dot .dot-o {
  width: 0.426667rem;
  height: 0.426667rem;
  background: #3ba0ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-awards .company-time .time-line .line-item .line-dot .dot-o .dot-i {
  width: 0.186667rem;
  height: 0.186667rem;
  background: rgba(255,255,255,0.65);
  border-radius: 50%;
}

.company-awards .company-time .time-line .line-item .dot-active .label {
  font-size: 0.48rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.586667rem;
}

.company-awards .company-time .time-line .line-item .dot-active .dot-o .dot-i {
  background: #ffffff;
}

.company-awards .company-time .time-line .line-item .line-l {
  flex: 1;
  height: 0.053333rem;
  background: rgba(255,255,255,0.15);
}

.company-awards .company-time .time-line .flex1 {
  flex: 1;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bgw {
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #fff 100%) !important;
}