.timeline-item {
  padding-top: 0.426667rem 0;
}

.timeline-item .item-tamplate {
  display: flex;
}

.timeline-item .item-tamplate .dot {
  width: 0.586667rem;
  display: flex;
  flex-direction: column;
  transform: translateY(0.133333rem);
}

.timeline-item .item-tamplate .dot .dot-slot {
  height: 0.106667rem;
  width: 0.106667rem;
  background: #1890ff;
  border: 0.106667rem solid #b9ddff;
  border-radius: 100%;
  box-sizing: content-box;
  transform: translateX(0.16rem);
}

.timeline-item .item-tamplate .dot .line {
  flex: auto;
  box-sizing: border-box;
  border-left: 0.053333rem solid #e4e7ed;
  transform: translateX(0.266667rem);
}

.timeline-item .item-tamplate .item-content {
  flex: auto;
  line-height: 0.64rem;
  padding: 0 0.533333rem 0 0;
}