.interview {
  overflow: hidden;
}

.interview p {
  margin: 0;
}

.interview .section1 .barnner {
  position: relative;
  max-height: 6.4rem;
  overflow: hidden;
}

.interview .section1 .barnner .banner-img {
  display: block;
  width: 100%;
  height: auto;
}

.interview .section1 .barnner .text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 6.4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interview .section1 .barnner .text-wrapper span {
  font-size: 0.64rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.906667rem;
  letter-spacing: 0.08rem;
}

.interview .content {
  padding: 1.066667rem 0.666667rem 1.866667rem 0.666667rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}

.interview .content h3 {
  margin-bottom: 0.666667rem;
  font-size: 0.4rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: center;
}

.interview .content p {
  font-size: 0.32rem;
  color: #627098;
  line-height: 0.586667rem;
}

.interview .content p i {
  font-style: normal;
}

.interview .content .box {
  margin-top: 0.666667rem;
}

.interview .content .box .level-wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.interview .content .box .level-wrap .dotted-line {
  display: none;
}

.interview .content .box .level-wrap .normal-item,
.interview .content .box .level-wrap .special-item {
  cursor: pointer;
  position: relative;
  padding: 0.266667rem;
  box-sizing: border-box;
  width: 100%;
  height: 4.266667rem;
  border: 0.026667rem solid #e1e6f4;
  margin-bottom: 0.666667rem;
  overflow: hidden;
  background: #ffffff;
  z-index: 1;
}

.interview .content .box .level-wrap .normal-item .tag,
.interview .content .box .level-wrap .special-item .tag {
  position: absolute;
  display: inline-block;
  width: 0.426667rem;
  height: 0.586667rem;
  top: 0;
  left: 0.266667rem;
}

.interview .content .box .level-wrap .normal-item .level-tip,
.interview .content .box .level-wrap .special-item .level-tip {
  display: flex;
  justify-content: space-between;
}

.interview .content .box .level-wrap .normal-item .level-tip span:first-of-type,
.interview .content .box .level-wrap .special-item .level-tip span:first-of-type {
  font-size: 0.373333rem;
  line-height: 0.453333rem;
  font-family: AppleMyungjo;
  color: #333333;
  margin-left: 0.986667rem;
}

.interview .content .box .level-wrap .normal-item .level-tip span:last-of-type,
.interview .content .box .level-wrap .special-item .level-tip span:last-of-type {
  font-size: 0.346667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 0.506667rem;
  color: #333333;
}

.interview .content .box .level-wrap .normal-item .stage,
.interview .content .box .level-wrap .special-item .stage {
  position: absolute;
  right: 0.266667rem;
  top: 0.8rem;
  height: 0.613333rem;
  line-height: 0.613333rem;
  text-align: right;
  vertical-align: middle;
}

.interview .content .box .level-wrap .normal-item .stage img,
.interview .content .box .level-wrap .special-item .stage img {
  width: 0.613333rem;
  display: inline-block;
  vertical-align: middle;
}

.interview .content .box .level-wrap .normal-item .stage i,
.interview .content .box .level-wrap .special-item .stage i {
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.32rem;
  font-weight: 400;
  color: #979797;
  line-height: 0.453333rem;
}

.interview .content .box .level-wrap .normal-item .item-wrap,
.interview .content .box .level-wrap .special-item .item-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 3.146667rem;
  margin-top: 0.266667rem;
}

.interview .content .box .level-wrap .normal-item .item-wrap .item,
.interview .content .box .level-wrap .special-item .item-wrap .item {
  display: flex;
  margin-bottom: 0.266667rem;
}

.interview .content .box .level-wrap .normal-item .item-wrap .item div:first-of-type,
.interview .content .box .level-wrap .special-item .item-wrap .item div:first-of-type {
  height: 0.48rem;
  display: flex;
  align-items: center;
  margin-right: 0.213333rem;
}

.interview .content .box .level-wrap .normal-item .item-wrap .item div:first-of-type span,
.interview .content .box .level-wrap .special-item .item-wrap .item div:first-of-type span {
  width: 0.106667rem;
  height: 0.106667rem;
  background: #d8d8d8;
}

.interview .content .box .level-wrap .normal-item .item-wrap .item div:last-of-type,
.interview .content .box .level-wrap .special-item .item-wrap .item div:last-of-type {
  font-size: 0.346667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #9fa8b8;
  line-height: 0.48rem;
}

.interview .content .box .level-wrap .normal-item:hover,
.interview .content .box .level-wrap .special-item:hover {
  transform: scale(1.1);
  box-shadow: 0.053333rem 0.053333rem 0.666667rem 0 rgba(61,87,163,0.21);
  transition: all 1s;
}

.interview .content .box .level-wrap .special-item .item-wrap>div:first-child {
  display: flex;
  justify-content: space-between;
}

.interview .content .box .level-wrap .special-item {
  margin-bottom: 0;
}