@font-face {
  font-family: 'DDRegular';
  font-weight: 400;
  src: url("~@/assets/fonts/DingTalk_JinBuTi_Regular.woff2") format("woff2"),url("~@/assets/fonts/DingTalk_JinBuTi_Regular.woff") format("woff");
  font-display: swap;
}

#app {
  font-family: system,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,PingFang SC,Segoe UI,Microsoft YaHei,wenquanyi micro hei,Hiragino Sans GB,Hiragino Sans GB W3,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  box-sizing: border-box;
}

#nav {
  padding: 0.8rem;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.van-toast {
  min-height: 0;
  width: fit-content;
}

::-webkit-scrollbar {
  width: 0.16rem;
  height: 0.16rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.16rem rgba(0,0,0,0);
  background-color: rgba(255,255,255,0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 0.106667rem;
  box-shadow: inset 0 0 0.16rem rgba(0,0,0,0);
  background-color: rgba(144,147,153,0.3);
}