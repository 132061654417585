.edition-overview {
  border-radius: 0.106667rem;
}

.edition-overview .overview-item {
  display: flex;
  color: #333333;
  background: #ffffff;
  padding: 0.533333rem 0.266667rem;
  -webkit-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
}

.edition-overview .overview-item .overview-item_content {
  width: 2.8rem;
  margin-right: 0.266667rem;
}

.edition-overview .overview-item .overview-item_content .title {
  font-size: 0.426667rem;
  font-weight: 600;
  line-height: 0.533333rem;
  margin-bottom: 0.213333rem;
}

.edition-overview .overview-item .overview-item_content .price {
  font-size: 0.32rem;
  font-weight: 400;
  line-height: 0.533333rem;
  margin-bottom: 0.213333rem;
}

.edition-overview .overview-item .overview-item_content .price .eprice {
  font-weight: 600;
  line-height: 0.533333rem;
  font-size: 0.426667rem;
}

.edition-overview .overview-item .overview-item_content .price-tip {
  font-size: 0.213333rem;
  font-weight: 400;
  color: #999999;
  line-height: 0.426667rem;
}

.edition-overview .overview-item .overview-item_content .price-tip+.price-tip {
  margin-top: 0.106667rem;
}

.edition-overview .overview-item .overview-item_content .mb12 {
  margin-bottom: 0.32rem;
}

.edition-overview .overview-item .overview-item_content .to-detail {
  display: flex;
  align-items: center;
  font-size: 0.266667rem;
  font-weight: 400;
  color: #1990ff;
  line-height: 0.426667rem;
}

.edition-overview .overview-item .overview-item_content .to-detail img {
  margin-left: 0.133333rem;
}

.edition-overview .content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.edition-overview .content .item {
  display: flex;
  font-size: 0.266667rem;
  font-weight: 400;
  color: #555555;
  width: 50%;
  align-items: center;
}

.edition-overview .content .item img {
  margin-right: 0.133333rem;
}

.edition-overview .overview-item+.overview-item {
  border-top: 0.026667rem solid #dddddd;
}