.danti {
  margin-top: 0.426667rem;
  padding: 0.426667rem;
  background: #ffffff;
  border-radius: 0.213333rem;
  border: 0.026667rem solid #dddddd;
}

.danti .khsrl {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.586667rem;
}

.danti .flexdiv {
  display: flex;
  margin: 0.213333rem 0 0.32rem;
}

.danti .flexdiv .label {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.586667rem;
  margin-right: 0.266667rem;
}

.danti .flexdiv .van-rate {
  position: relative;
  top: 0.053333rem;
}

.danti .flexdiv .huase {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #ffca3a;
  line-height: 0.533333rem;
  margin-left: 0.213333rem;
}

.danti .el-divider {
  margin: 0.426667rem 0;
}

.danti .spctit {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.586667rem;
  margin-bottom: 0.106667rem;
}

.danti .spcdtil {
  font-weight: 400;
  font-size: 0.373333rem;
  color: #666666;
  line-height: 0.586667rem;
}

.emptydiv {
  text-align: center;
}

.emptydiv .novido {
  width: 3.2rem;
  height: 3.2rem;
}

.emptydiv .tip {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #aaaaaa;
}

.tattooResults {
  font-size: 0.426667rem;
  margin-top: 0.64rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
}

.tattooResults .tattooResults_title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.533333rem;
  color: #333333;
  line-height: 0.746667rem;
  height: 0.853333rem;
}

.tattooResults .tattooResults_title>div {
  float: left;
  position: relative;
  padding: 0 0.266667rem;
}

.tattooResults .tattooResults_title>div::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.213333rem;
  background: #1890FF;
  opacity: 0.15;
  position: absolute;
  bottom: 0.053333rem;
  margin-left: -0.266667rem;
}