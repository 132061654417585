.show_tool {
  padding-top: 2.666667rem;
  overflow: hidden;
}

.fixed {
  position: fixed;
  bottom: 0;
  z-index: 110;
  width: 100%;
}

.tools {
  height: 1.813333rem;
  display: flex;
}

.tools .button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  box-shadow: 0 0 0.266667rem 0 rgba(81,88,109,0.3);
  font-size: 0.48rem;
  padding-bottom: 0.213333rem;
}

.tools .button img {
  width: 0.746667rem;
}

.tools .left {
  background: #ffffff;
  color: #4f8fff;
}

.tools .right {
  background: #4f8fff;
  color: #ffffff;
}