.htmlParse {
  position: relative;
  width: 100%;
}

.htmlParse .show-more {
  width: 100%;
  text-align: right;
  margin-bottom: 0.266667rem;
  color: #14b1ff;
}

.htmlParse .inner-video-player {
  position: relative;
}

.htmlParse pre {
  white-space: pre-wrap;
  word-break: break-word;
}

.htmlParse video {
  width: 100%;
  height: 5.333333rem;
}

.htmlParse .noWifiTip {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.373333rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5.333333rem;
}

.htmlParse .noWifiTip .text {
  color: #ffffff;
}

.htmlParse .noWifiTip .paly-btn {
  margin-top: 0.266667rem;
  color: #ffffff;
  width: 0.8rem;
}

.htmlParse .noWifiTip .paly-btn img {
  width: 100%;
}

.htmlParse .small-tips {
  position: absolute;
  bottom: 20%;
  left: 0;
  color: #ffffff;
  z-index: 10;
  animation-name: enterIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  background-color: rgba(0,0,0,0.5);
}

@keyframes enterIn {
  0% {
    transform: translateX(-1000vw);
  }

  10% {
    transform: translateX(0);
  }

  95% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}