.remark-content {
  width: 100%;
  padding-top: 1rem;
}

.remark-content .wrapper {
  width: 90%;
  margin: 0 auto;
}

.remark-content .wrapper .van-field {
  width: 100%;
  padding: 0;
  border-radius: 0.133333rem;
  border: 0.03rem solid #eaeaea;
  background: #fafafa;
  padding: 0.133333rem;
}

.remark-content .wrapper .button-group {
  width: 100%;
  margin: 2vh auto 0 auto;
  display: flex;
  justify-content: flex-end;
}

.remark-content .wrapper .button-group div {
  padding: 0.18rem 0.4rem;
  font-size: 0.3rem;
  border-radius: 0.133333rem;
}

.remark-content .wrapper .button-group .cancle {
  border-radius: 0.06rem;
  border: 0.03rem solid #d9d9d9;
  margin-right: 0.6rem;
}

.remark-content .wrapper .button-group .confirm {
  background-color: #1890ff;
  color: #ffffff;
}

.remark-content .van-step__title--active {
  color: #999999;
}

.remark-content .spot {
  width: 0.4rem;
  height: 0.4rem;
  background: #1890ff;
  border: 0.106667rem solid #b9ddff;
  border-radius: 100%;
}

.remark-content .conmunicate {
  color: #1890ff;
  margin-right: 0.266667rem;
}

.remark-content .content .box pre {
  white-space: pre-wrap;
}