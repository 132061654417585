.voice-result {
  padding: 0 0.533333rem;
}

.voice-result .title {
  font-weight: 600;
  font-size: 0.426667rem;
}

.voice-result .voice-score {
  display: flex;
  flex-wrap: wrap;
}

.voice-result .voice-score .score-item {
  padding: 0.426667rem 0.266667rem 0 0.266667rem;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.voice-result .voice-score .score-item .score-name {
  color: #333333;
  font-size: 0.373333rem;
}

.voice-result #voice-result-chart {
  box-sizing: border-box;
  background-color: #fafafa;
  width: 100%;
  height: 12.8rem;
}