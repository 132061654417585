p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.flex {
  display: flex;
}

:deep(.van-dialog) .van-dialog__content {
  padding: 0.533333rem 0.64rem 0.266667rem;
}

:deep(.van-dialog).similar-dialog .similar-list {
  max-height: 50vh;
  overflow: auto;
}

:deep(.van-dialog).similar-dialog .similar-list .similar-item .title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.586667rem;
  margin-bottom: 0.213333rem;
}

:deep(.van-dialog).similar-dialog .similar-list .similar-item .title .percent {
  color: #1890ff;
}

:deep(.van-dialog).similar-dialog .similar-list .similar-item .content {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.64rem;
}

:deep(.van-dialog).similar-dialog .similar-list .similar-item .van-divider {
  margin: 0.32rem 0;
}

:deep(.timeline-item) .dot {
  transform: translateY(0.08rem);
}

:deep(.timeline-item) .dot .line {
  transform: translateX(0.186667rem);
}

:deep(.timeline-item) .desc {
  width: 8rem;
  word-wrap: break-word;
  margin-bottom: 0.133333rem;
}

:deep(.timeline-item) .desc .text {
  font-size: 0.4rem;
  font-weight: 400;
  color: #171717;
  line-height: 0.56rem;
  margin-bottom: 0;
}

:deep(.timeline-item) .desc .text.sub {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #6d7278;
}

:deep(.timeline-item) .desc .text.sub:not(:last-child) {
  font-size: 0.346667rem;
}

:deep(.timeline-item) .outer-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.373333rem;
  height: 0.373333rem;
  background: #b9ddff;
  border-radius: 50%;
}

:deep(.timeline-item) .outer-circle .inner-circle {
  width: 0.16rem;
  height: 0.16rem;
  background: #1890ff;
  border-radius: 50%;
}

.anti-cheating {
  margin-top: 0.533333rem;
  padding: 0 0.106667rem;
}

.anti-cheating * {
  box-sizing: border-box;
}

.anti-cheating .outer-card {
  position: relative;
  background: #f2f9ff;
  padding: 0 0.32rem 0.506667rem;
  margin-bottom: 0.533333rem;
}

.anti-cheating .outer-card::before {
  content: '';
  position: absolute;
  border-right: 0.133333rem solid #005aae;
  border-top: 0.133333rem solid transparent;
  border-bottom: 0.133333rem solid #005aae;
  border-left: 0.133333rem solid transparent;
  left: 0;
  top: -0.266667rem;
}

.anti-cheating .outer-card-title {
  align-items: center;
  margin-bottom: 0.373333rem;
}

.anti-cheating .outer-card-title .label {
  height: 1.013333rem;
  font-size: 0.426667rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.586667rem;
  align-items: center;
  justify-content: center;
  background: #1890ff;
  border-radius: 0 0 0.053333rem 0.053333rem;
  padding: 0 0.24rem;
  margin-left: -0.053333rem;
  margin-top: -0.266667rem;
  z-index: 1;
}

.anti-cheating .outer-card-title .level {
  font-size: 0.586667rem;
  font-weight: 500;
  color: #ff5420;
  line-height: 0.746667rem;
  margin-left: 0.213333rem;
}

.anti-cheating .outer-card .inner-card {
  margin-bottom: 0.053333rem;
  flex-wrap: wrap;
}

.anti-cheating .outer-card .inner-card-item {
  width: calc(50% - 0.133333rem);
  background: #ffffff;
  border-radius: 0.106667rem;
  border: 0.026667rem solid rgba(24,144,255,0.25);
  padding: 0.213333rem 0.32rem;
  margin: 0 0.266667rem 0.266667rem 0;
}

.anti-cheating .outer-card .inner-card-item:nth-child(2n) {
  margin-right: 0;
}

.anti-cheating .outer-card .inner-card-item .card-title {
  font-size: 0.426667rem;
  font-weight: 600;
  margin-bottom: 0.266667rem;
  color: #333333;
}

.anti-cheating .outer-card .inner-card-item .card-title.color {
  color: #1890ff;
}

.anti-cheating .outer-card .inner-card-item .line {
  margin-bottom: 0.133333rem;
  font-size: 0.373333rem;
  line-height: 0.426667rem;
}

.anti-cheating .outer-card .inner-card-item .line .card-label {
  font-weight: 400;
}

.anti-cheating .outer-card .inner-card-item .line .card-percent {
  font-weight: 500;
}

.anti-cheating .outer-card .inner-card-item .card-count {
  font-size: 0.693333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.8rem;
}

.anti-cheating .outer-card .inner-card-item .unit {
  font-size: 0.373333rem;
  font-weight: 400;
  line-height: 0.8rem;
  margin-left: 0.08rem;
}

.anti-cheating .outer-card .tip-text {
  font-size: 0.32rem;
  font-weight: 400;
  color: #afb6ca;
  line-height: 0.453333rem;
}

.anti-cheating .module-card {
  margin-bottom: 0.533333rem;
}

.anti-cheating .module-card .module-title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.586667rem;
  margin-bottom: 0.426667rem;
}

.anti-cheating .module-card .module-title.left-icon {
  position: relative;
  padding-left: 0.24rem;
}

.anti-cheating .module-card .module-title.left-icon::before {
  content: '';
  position: absolute;
  width: 0.106667rem;
  height: 0.48rem;
  background: #1890ff;
  border-radius: 0.053333rem;
  left: 0;
  top: 0.053333rem;
}

.anti-cheating .module-card .module-title .test-icon {
  height: 0.4rem;
  margin-left: 0.133333rem;
  vertical-align: -0.08rem;
}

.anti-cheating .module-card .problem-list-item {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.32rem;
}

.anti-cheating .module-card .problem-list-item .text {
  margin-bottom: 0.32rem;
}

.anti-cheating .module-card .problem-list-item .answer-card {
  background: rgba(24,144,255,0.05);
  border-radius: 0.106667rem;
  border: 0.026667rem dashed #1890ff;
  padding: 0.24rem 0.32rem 0.32rem;
  color: #666666;
  margin-bottom: 0.32rem;
}

.anti-cheating .module-card .problem-list-item .answer-card .answer-title {
  font-weight: 600;
  color: #333333;
  margin-bottom: 0.08rem;
}

.anti-cheating .module-card .problem-list-item .similar-card {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
  padding: 0.373333rem 0.32rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.08rem;
  border: 0.026667rem solid #dddddd;
  margin-bottom: 0.213333rem;
}

.anti-cheating .module-card .problem-list-item .similar-card .percent {
  font-weight: 600;
}

.anti-cheating .module-card .problem-list-item .similar-card .btn {
  color: #1890ff;
  cursor: pointer;
}

.anti-cheating .module-card .images-container .text {
  font-size: 0.4rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.32rem;
}

.anti-cheating .module-card .page-exit-container .info-box {
  align-items: center;
  margin-bottom: 0.266667rem;
}

.anti-cheating .module-card .page-exit-container .info-box label {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #6d7278;
  line-height: 0.533333rem;
}

.anti-cheating .module-card .page-exit-container .info-box .info {
  font-size: 0.426667rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.746667rem;
}

.anti-cheating .module-card .idcard-container {
  padding: 0 0.746667rem;
}

.anti-cheating .module-card .idcard-container .cert {
  background: #f8f8fa;
  border-radius: 0.133333rem;
  border: 0.026667rem solid #d6dde8;
  margin-bottom: 0.426667rem;
  padding: 0.96rem 2.133333rem 0.746667rem;
}

.anti-cheating .module-card .idcard-container .cert img {
  display: inline-block;
  width: 4.533333rem;
  height: 2.666667rem;
  margin-bottom: 0.346667rem;
  object-fit: contain;
}

.anti-cheating .module-card .idcard-container .cert .label {
  font-size: 0.426667rem;
  font-weight: 400;
  color: #627098;
  line-height: 0.586667rem;
  text-align: center;
}

.anti-cheating .module-card .video-list .text {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #6d7278;
  line-height: 0.533333rem;
  margin-bottom: 0.213333rem;
}

.anti-cheating .module-card .video-list .video-item {
  margin-bottom: 0.32rem;
}

.anti-cheating .module-card .video-list .video-item .time-info {
  margin-bottom: 0.133333rem;
  align-items: center;
}

.anti-cheating .module-card .video-list .video-item .time-info label {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #6d7278;
  line-height: 0.533333rem;
}

.anti-cheating .module-card .video-list .video-item .time-info .time {
  font-size: 0.533333rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.746667rem;
}

.anti-cheating .module-card .image-wall {
  margin-bottom: 0.266667rem;
}

.anti-cheating .module-card .progress-container .log-list {
  margin-bottom: 0.32rem;
}