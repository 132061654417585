.mandate-presentation {
  position: relative;
}

.mandate-presentation .ai-content {
  padding: 2.4rem 0.666667rem 0;
}

.mandate-presentation .ai-content .tip {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  text-align: center;
}

.mandate-presentation .ai-content .second-tip {
  margin-top: 0.133333rem;
  font-size: 0.4rem;
  line-height: 0.56rem;
  text-align: center;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.mandate-presentation .ai-content .stage {
  margin: 0.666667rem 0 1.333333rem 0;
}

.mandate-presentation .ai-content .stage .stage-one,
.mandate-presentation .ai-content .stage .stage-two {
  display: block;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
  line-height: 0.586667rem;
}

.mandate-presentation .ai-content .stage .stage-two {
  margin-top: 0.533333rem;
}

.mandate-presentation .hr-characters-intro {
  position: relative;
  height: 9.92rem;
  background: #13397c;
  padding: 1.333333rem 0.666667rem;
  box-sizing: border-box;
}

.mandate-presentation .hr-characters-intro .left-part div:first-of-type {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.56rem;
  text-align: center;
}

.mandate-presentation .hr-characters-intro .left-part div:last-of-type {
  margin-top: 0.666667rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ffffff;
  line-height: 0.586667rem;
}

.mandate-presentation .hr-characters-intro .right-part {
  margin-top: 0.533333rem;
}

.mandate-presentation .hr-characters-intro .right-part .photo-wrap {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.mandate-presentation .hr-characters-intro .right-part .photo-wrap img {
  width: 1.973333rem;
  height: 1.973333rem;
}

.mandate-presentation .ai-team {
  padding: 1.333333rem 0 1.066667rem;
}

.mandate-presentation .ai-team .tip {
  text-align: center;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}

.mandate-presentation .ai-team>.description {
  margin-top: 0.666667rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
  line-height: 0.586667rem;
  padding: 0 0.666667rem;
}

.mandate-presentation .ai-team .scientist {
  padding: 0.533333rem 0.666667rem 0;
}

.mandate-presentation .ai-team .scientist li {
  margin-bottom: 0.533333rem;
  position: relative;
}

.mandate-presentation .ai-team .scientist li:last-child {
  margin: 0;
}

.mandate-presentation .ai-team .scientist li .left-part {
  position: absolute;
  left: 0.4rem;
  top: 0.533333rem;
}

.mandate-presentation .ai-team .scientist li .left-part img {
  display: block;
  width: 1.12rem;
  height: 1.12rem;
}

.mandate-presentation .ai-team .scientist li .right-part {
  background: #eef1f8;
  padding: 0.533333rem 0.4rem;
}

.mandate-presentation .ai-team .scientist li .right-part .name {
  font-size: 0.373333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.533333rem;
  margin-left: 1.333333rem;
  margin-top: 0.08rem;
}

.mandate-presentation .ai-team .scientist li .right-part .position {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.453333rem;
  margin-left: 1.333333rem;
}

.mandate-presentation .ai-team .scientist li .right-part .description {
  margin-top: 0.346667rem;
}

.mandate-presentation .ai-team .scientist li .right-part .description .paragraph {
  margin-bottom: 0.266667rem;
  display: flex;
}

.mandate-presentation .ai-team .scientist li .right-part .description .paragraph div:first-of-type {
  margin-right: 0.213333rem;
  text-align: center;
}

.mandate-presentation .ai-team .scientist li .right-part .description .paragraph div:first-of-type span {
  display: inline-block;
  width: 0.053333rem;
  height: 0.053333rem;
  border-radius: 0.026667rem;
  background: #627098;
}

.mandate-presentation .ai-team .scientist li .right-part .description .paragraph div:last-of-type {
  font-size: 0.266667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.373333rem;
}

.mandate-presentation .bg1 {
  width: 4.373333rem;
  position: absolute;
  top: 10.186667rem;
  left: -2.186667rem;
}