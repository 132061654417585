.menu_new {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  background: #ffffff;
  padding: 0 0.533333rem 0 0.693333rem;
  height: 4.8rem;
}

.menu_new /deep/ .el-dialog__header,
.menu_new /deep/.el-dialog__body {
  padding: 0;
}

.menu_new /deep/ .menu-list {
  padding: 0.266667rem 1.333333rem;
}

.menu_new /deep/ .menu-list .menu-nav {
  border: 0;
}

.menu_new /deep/ .menu-list .menu-nav>li {
  border-bottom: 0.026667rem solid #dddddd;
  color: #333333 !important;
  font-weight: 600;
  font-size: 0.373333rem;
}

.menu_new /deep/ .menu-list .menu-nav>li a {
  color: #333333 !important;
}

.menu_new /deep/ .menu-list .menu-nav li,
.menu_new /deep/ .menu-list .menu-nav .el-submenu__title {
  padding: 0 !important;
}

.menu_new /deep/ .menu-list .el-menu-item:focus,
.menu_new /deep/ .menu-list .el-menu-item:hover {
  background-color: #ffffff;
}

.menu_new /deep/ .menu-list .el-submenu__title:focus,
.menu_new /deep/ .menu-list .el-submenu__title:hover {
  background-color: #ffffff;
}

.menu_new /deep/ .menu-list .el-submenu .el-menu-item {
  color: #979797 !important;
  font-size: 0.346667rem;
  font-weight: 400;
  line-height: 0.8rem;
  height: 0.8rem;
}

.menu_new /deep/ .menu-list .el-submenu .el-menu {
  padding-bottom: 0.266667rem;
}

.menu_new>.menu-icon {
  float: right;
  color: #1990ff;
  font-size: 0.666667rem;
  line-height: 0.96rem;
  display: block;
}

.menu_new .menu-content .content-header {
  background: #ffffff;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.533333rem 0 0.693333rem;
}

.menu_new .menu-content .content-header>img {
  height: 0.906667rem;
}

.menu_new .menu-content .content-header .close-icon {
  font-size: 0.933333rem;
  line-height: 1.333333rem;
  color: #1990ff;
}

.menu_new .menu_menu {
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu_new .menu_menu>img {
  height: 0.96rem;
  float: left;
}

.menu_new .menu_banner {
  position: absolute;
  width: 100%;
  left: 0;
}

.menuIcon {
  position: fixed;
  bottom: 2.293333rem;
  right: 0.266667rem;
  font-size: 0.933333rem;
  width: 1.706667rem;
  height: 0.933333rem;
  line-height: px;
  background: #ebedf0;
  color: #1c1e21;
  border-radius: 0.133333rem;
  display: none;
  justify-content: center;
  align-items: center;
}

.menuIcon .sidebarMenuIcon_fgN0 {
  display: block;
}

.custom_dialog-overlay {
  z-index: 111;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.45);
}

.custom_dialog-overlay .custom_dialog {
  height: 11.093333rem;
  z-index: 10;
  top: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 9.36rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom_dialog-overlay .custom_dialog .del_btn {
  cursor: pointer;
}