.report-detail /deep/ video {
  width: 100%;
  height: 5.333333rem;
  margin: 0.266667rem 0;
}

.report-detail .score {
  padding: 0 0.533333rem;
}

.report-detail .score div {
  color: #666;
}

.report-detail .score .test {
  margin: 0.266667rem 0;
  line-height: 0.533333rem;
}

.report-detail section {
  height: calc(100vh - 1.866667rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.report-detail section .gray {
  color: #666666;
}

.report-detail section .green {
  color: #6ed401;
}

.report-detail section .red {
  color: #ff0000;
}

.report-detail section .yellow {
  color: #f4d75e;
}

.report-detail section .line-20 {
  line-height: 0.533333rem;
}

.report-detail section .video {
  width: 100%;
  height: 5.333333rem;
  margin: 0.266667rem 0;
}

.report-detail section pre {
  word-break: break-word;
  white-space: pre-wrap;
}

.report-detail section /deep/ .van-tabs .van-tabs__line {
  background: #1890ff;
}

.report-detail section /deep/ .van-tabs .van-tab--active {
  font-weight: 550;
}

.report-detail section /deep/ .van-tabs .van-tabs__content {
  padding-bottom: 1.066667rem;
}

.report-detail section .comlun {
  flex-direction: column;
  justify-content: space-between;
}

.report-detail section /deep/ .obj-pagination {
  justify-content: center;
  position: fixed;
  bottom: 0.533333rem;
  left: 50%;
  transform: translateX(-50%);
}

.report-detail section /deep/ .obj-pagination .van-pagination__page {
  flex-grow: 1;
}

.report-detail section /deep/ .obj-pagination li:last-child {
  display: none;
}

.report-detail section /deep/ .obj-pagination li:first-child {
  display: none;
}

.report-detail section /deep/ .obj-pagination li {
  cursor: pointer;
  width: 1.066667rem;
  height: 1.066667rem;
  border-radius: 0.08rem;
  margin: 0 0.213333rem;
  border: 0.026667rem solid #ddd;
}

.report-detail section .video-title {
  padding: 0 0.533333rem;
}

.report-detail section .obj-title {
  font-size: 0.32rem;
  color: #666666;
}

.report-detail section .obj-answer {
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  padding: 0.373333rem;
}

.report-detail section .obj-answer div:first-child {
  color: #333333;
  font-weight: 500;
}

.report-detail section .sub-answer {
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  margin-top: 0.266667rem;
}

.report-detail section .sub-answer div:first-child {
  color: #333333;
  font-weight: 500;
}

.report-detail section /deep/.van-panel .van-panel__header {
  padding-top: 0.373333rem;
  padding-bottom: 0.373333rem;
}

.report-detail section /deep/.van-panel .van-panel__header .van-cell__title {
  font-size: 0.4rem;
  color: #333333;
  font-weight: 550;
}

.report-detail section /deep/.van-panel .van-panel__content {
  padding: 0 0.533333rem;
}

.report-detail section /deep/ .van-panel::after {
  content: none;
}

.report-detail section .question /deep/ img {
  max-width: 100%;
}

.report-detail section .question .video {
  max-width: 100%;
}