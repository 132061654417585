.case {
  background: #fff;
}

.case .case-content {
  padding: 0 0.666667rem 1.333333rem 0.666667rem;
}

.case .case-content>.title {
  font-size: 0.64rem;
  font-weight: 600;
  line-height: 0.906667rem;
  color: #333333;
  letter-spacing: 0.08rem;
  margin-top: 0.933333rem;
  text-align: center;
  margin-bottom: 0.266667rem;
}

.case .case-content ul li .img-icon {
  width: 5.866667rem;
  height: 3.146667rem;
  overflow: hidden;
  margin: 0 auto 0.666667rem;
}

.case .case-content ul li .img-icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.case .case-content ul li .title {
  font-size: 0.4rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.266667rem;
}

.case .case-content ul li .box-txt {
  width: 100%;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.586667rem;
  margin-bottom: 0.8rem;
  text-align: justify;
}

.case .case-content ul li:last-child .box-txt {
  margin: 0;
}