.third-part-evaluation {
  position: relative;
}

.third-part-evaluation .add-exam {
  margin: 0.533333rem 0;
  text-align: center;
}

.third-part-evaluation .add-exam .button {
  border-radius: 0.213333rem;
}

.third-part-evaluation .content-wrap {
  list-style: none;
  width: 80%;
  margin: auto;
  padding-bottom: 2.666667rem;
}

.third-part-evaluation .content-wrap li {
  margin: 0.8rem 0 0;
  padding: 0.8rem;
  background: #ffffff;
  box-shadow: 0 0.053333rem 0.266667rem 0 rgba(80,98,118,0.2);
  border-radius: 0.133333rem;
}

.third-part-evaluation .content-wrap li .header .type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.third-part-evaluation .content-wrap li .header .type .left {
  display: flex;
  align-items: center;
}

.third-part-evaluation .content-wrap li .header .type .left span:first-of-type {
  width: 1.066667rem;
  height: 0.533333rem;
  line-height: 0.586667rem;
  border-radius: 0.053333rem;
  text-align: center;
  font-size: 0.32rem;
  color: #ffffff;
}

.third-part-evaluation .content-wrap li .header .type .left span:last-of-type {
  margin-left: 0.213333rem;
  font-size: 0.373333rem;
  font-weight: bold;
  color: #333333;
  min-width: 0;
  flex: 1;
}

.third-part-evaluation .content-wrap li .header .type .right i:last-of-type {
  margin-left: 0.266667rem;
}

.third-part-evaluation .content-wrap li .header .time {
  font-size: 0.32rem;
  color: #aaaaaa;
  line-height: 0.533333rem;
}

.third-part-evaluation .content-wrap li .content .name,
.third-part-evaluation .content-wrap li .content .grade {
  display: flex;
  align-items: baseline;
  font-size: 0.346667rem;
  margin-top: 0.266667rem;
  line-height: 0.4rem;
}

.third-part-evaluation .content-wrap li .content .name span:first-of-type,
.third-part-evaluation .content-wrap li .content .grade span:first-of-type {
  color: #5c5e61;
  width: 2rem;
  min-width: 2rem;
  white-space: nowrap;
}

.third-part-evaluation .content-wrap li .content .name span:last-of-type,
.third-part-evaluation .content-wrap li .content .grade span:last-of-type {
  color: black;
  text-shadow: 0 0.053333rem 0.266667rem rgba(80,98,118,0.2);
}

.third-part-evaluation .content-wrap li .content .link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.third-part-evaluation .content-wrap li .content .icon {
  margin-left: 0.266667rem;
  color: #1890ff;
  cursor: pointer;
}

.third-part-evaluation .content-wrap li .content .report {
  margin-top: 0.266667rem;
  display: flex;
  align-items: baseline;
  font-size: 0.346667rem;
}

.third-part-evaluation .content-wrap li .content .report .left-side {
  display: flex;
  align-items: baseline;
  line-height: 0.4rem;
  width: 100%;
}

.third-part-evaluation .content-wrap li .content .report .left-side .label {
  white-space: nowrap;
  width: 2rem;
  min-width: 2rem;
}

.third-part-evaluation .content-wrap li .content .report .left-side .report-url {
  color: #5c5e61;
  display: flex;
  justify-content: space-between;
  max-width: calc(100% - 2rem);
  position: relative;
  flex: 1;
}

.third-part-evaluation .content-wrap li .content .report .left-side .report-url .file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.third-part-evaluation .content-wrap li .content .report .left-side .report-url .file-btn {
  margin-left: 0.266667rem;
  cursor: pointer;
  color: #1890ff;
  white-space: nowrap;
}

.third-part-evaluation .content-wrap li .content .report .right-side .link-preview {
  margin-left: 0.266667rem;
}

.third-part-evaluation .no-data {
  text-align: center;
}

.third-part-evaluation .no-data img {
  margin: 1.6rem auto;
  width: 40%;
  height: auto;
}

.third-part-evaluation .input-form {
  position: absolute;
  top: -0.4rem;
  padding-left: 5%;
  padding-bottom: 2.666667rem;
  width: 95%;
  background: white;
}

.third-part-evaluation .input-form .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.third-part-evaluation .input-form .header .button {
  border-radius: 0.133333rem;
  padding: 0 0.4rem;
}

.third-part-evaluation .input-form .header .title {
  font-size: 0.426667rem;
  font-weight: bold;
  color: #333333;
}

.third-part-evaluation .input-form .form-content {
  margin-top: 0.533333rem;
}

.third-part-evaluation .input-form .form-content li {
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
}

.third-part-evaluation .input-form .form-content li .row-name {
  min-width: 1.333333rem;
  font-size: 0.373333rem;
  color: #333333;
}

.third-part-evaluation .input-form .form-content li .form-input {
  height: 0.96rem;
  padding-left: 0.266667rem;
  border: 0.026667rem solid #dddddd;
  flex-grow: 1;
  font-size: 0.373333rem;
}

.third-part-evaluation .input-form .form-content li .form-input::placeholder {
  color: #aaaaaa;
}

.third-part-evaluation .input-form .form-content li .type-choose {
  display: flex;
}

.third-part-evaluation .input-form .form-content li .type-choose .type {
  position: relative;
  width: 1.813333rem;
  height: 0.96rem;
  border-radius: 0.08rem;
  border: 0.026667rem solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.third-part-evaluation .input-form .form-content li .type-choose .type:last-of-type {
  margin-left: 0.533333rem;
}

.third-part-evaluation .input-form .form-content li .type-choose .type span {
  font-size: 0.373333rem;
  color: #333333;
}

.third-part-evaluation .input-form .form-content li .type-choose .type.active {
  background: #14b1ff;
}

.third-part-evaluation .input-form .form-content li .type-choose .type.active span {
  color: white;
}

.third-part-evaluation .input-form .form-content li .upload-area /deep/ .el-button--primary {
  background-color: #14b1ff;
  border-color: #14b1ff;
}

.third-part-evaluation .input-form .form-content li .upload-area /deep/ .el-upload-list__item-name {
  width: 5.333333rem;
}

.third-part-evaluation .input-form .form-content li .upload-area .upload-text-area {
  display: flex;
  align-items: center;
}

.third-part-evaluation .input-form .form-content li .upload-area .upload-text-area .el-upload__tip {
  text-align: left;
  color: silver;
  padding-left: 0.4rem;
  margin-top: unset;
}