.summary__wrapper {
  margin: auto;
}

.summary__wrapper .top__title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.533333rem;
  color: #333333;
  text-align: left;
  font-style: normal;
  padding: 0 0.213333rem;
  position: relative;
  height: 2.133333rem;
}

.summary__wrapper .top__title::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.213333rem;
  background: #1890FF;
  opacity: 0.15;
  position: absolute;
  bottom: 0;
  left: 0;
}

.summary__wrapper .row {
  align-items: center;
  line-height: 0.666667rem;
  flex-wrap: wrap;
}

.summary__wrapper .row .flexrow {
  display: flex;
  flex-direction: row;
}

.summary__wrapper .row .flexrow .lsiwu {
  font-size: 0.426667rem;
  font-weight: 500;
  color: #1890ff;
  margin-right: 0.24rem;
  line-height: 0.373333rem;
}

.summary__wrapper .row>div {
  flex: 1;
}

.summary__wrapper .row>div>* {
  height: 0.666667rem;
  display: flex;
  align-items: center;
}

.summary__wrapper .row>div .vip-img {
  width: 80%;
}

.summary__wrapper .row>div .vip-img img {
  margin: 0;
  padding: 0;
  width: 100%;
}

.summary__wrapper .row .lable-item .number {
  font-size: 0.586667rem;
  font-weight: 600;
}

.summary__wrapper .row .lable-item .score {
  font-size: 0.32rem;
}

.summary__wrapper .row .lable-item .active_icon {
  transform: rotate(90deg);
}

.summary__wrapper .row .lable-item .top-lable i {
  color: #1890ff;
}

.summary__wrapper .row.ise {
  margin-top: 0.266667rem;
  margin-bottom: 0.133333rem;
}

.summary__wrapper .row.ise .huase {
  font-size: 0.373333rem;
  font-weight: 500;
  color: #ffca3a;
  margin-left: 0.24rem;
  line-height: 0.373333rem;
}

.summary__wrapper .row2 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 0.666667rem;
  flex-wrap: wrap;
}

.summary__wrapper .row2 .lable-item {
  margin-top: 0.266667rem;
  width: 30vw;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary__wrapper .row2 .lable-item>* {
  height: 0.666667rem;
  display: flex;
  align-items: center;
}

.summary__wrapper .row2 .lable-item .vip-img {
  width: 80%;
}

.summary__wrapper .row2 .lable-item .vip-img img {
  margin: 0;
  padding: 0;
  width: 100%;
}

.summary__wrapper .row2 .lable-item .number {
  font-size: 0.586667rem;
  font-weight: 600;
}

.summary__wrapper .row2 .lable-item .score {
  font-size: 0.32rem;
}

.summary__wrapper .row2 .lable-item .top-lable i {
  color: #1890ff;
}

.summary__wrapper .xingx {
  font-size: 0.266667rem;
  font-weight: 400;
  color: #6d7278;
  margin-bottom: 0.133333rem;
}

.summary__wrapper .progres {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.summary__wrapper .progres .txt {
  font-size: 0.373333rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.133333rem;
}

.summary__wrapper .progres .txt .num {
  font-size: 0.64rem;
  font-weight: 500;
  color: #1890ff;
}

.summary__wrapper .data__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.213333rem 0 0.133333rem;
}

.summary__wrapper .data__wrapper .data__title {
  font-size: 0.373333rem;
  color: #333;
}

.summary__wrapper .data__wrapper .data__score {
  margin-left: 0.133333rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #ffca3a;
}

.summary__wrapper .enData_summary {
  margin-bottom: 0.533333rem;
}

.summary__wrapper .enData_summary .enData_left {
  min-height: 8.533333rem;
  background: #FAFAFA;
  border-radius: 0.213333rem;
  width: 100%;
  margin-top: 0.32rem;
}

.summary__wrapper .enData_summary .enData_left__score {
  padding: 0.426667rem;
  display: flex;
}

.summary__wrapper .enData_summary .enData_left__score .enData_left__Level {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.586667rem;
  text-align: left;
  font-style: normal;
  margin-right: 0.266667rem;
  line-height: 1.706667rem;
}

.summary__wrapper .enData_summary .left1 {
  width: 7.04rem;
  display: flex;
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #BFCCDA;
  line-height: 0.453333rem;
  text-align: center;
  font-style: normal;
}

.summary__wrapper .enData_summary .left2 {
  width: 8.8rem;
  margin: 0.106667rem 0 0.32rem;
  height: 0.533333rem;
  display: flex;
  align-items: center;
}

.summary__wrapper .enData_summary .left2 .enData__label {
  width: 1.6rem;
  min-width: 1.6rem;
  margin-right: 0.16rem;
  text-align: right;
  line-height: 0.373333rem;
}

.summary__wrapper .enData_summary .left2 .enData__progress {
  width: 0;
  height: 0.426667rem;
  background: linear-gradient(90deg, #F5C3FE 0%, #813DF5 100%);
  border-radius: 0 2.666667rem 2.666667rem 0;
  z-index: 1;
}

.summary__wrapper .enData_summary .left2 .enData__score {
  padding: 0 0.106667rem;
  margin-left: 0.16rem;
  background: #FFFFFF;
  box-shadow: 0 0.026667rem 0.053333rem 0 rgba(0,0,0,0.12);
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #000000;
  line-height: 0.533333rem;
  text-align: center;
  font-style: normal;
  position: relative;
}

.summary__wrapper .enData_summary .left3 {
  text-align: left;
  margin-left: -0.24rem;
}

.summary__wrapper .enData_summary .enData_left__content {
  position: relative;
}

.summary__wrapper .enData_summary .enData_left__content .enData__line {
  width: 7.04rem;
  position: absolute;
  left: 0;
  top: 0.266667rem;
  z-index: 0;
  display: flex;
  border-left: 0.026667rem solid #d8d8d8;
}

.summary__wrapper .enData_summary .enData_left__content .enData__line span:not(:last-child) {
  width: 0.026667rem;
  height: 1.066667rem;
  border-right: 0.026667rem dashed #f2f2f2;
}

.summary__wrapper .enData_summary .enData_left__tips {
  margin: 0 0.426667rem 0.213333rem;
  padding-bottom: 0.426667rem;
}

.summary__wrapper .enData_summary .enData_left__tips h3 {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.586667rem;
  text-align: left;
  font-style: normal;
  margin-bottom: 0.213333rem;
}

.summary__wrapper .enData_summary .enData_left__tips>div {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.373333rem;
  color: #666666;
  line-height: 0.586667rem;
  text-align: left;
  font-style: normal;
}

.summary__wrapper .enData_summary .enData_right {
  min-height: 8.533333rem;
  background: #FAFAFA;
  border-radius: 0.213333rem;
  width: 100%;
  margin-top: 0.32rem;
}

.summary__wrapper .enData_summary .enData_right .enData_left__content {
  position: relative;
}

.summary__wrapper .enData_summary .enData_right .enData_left__content .enData__line {
  width: 6.666667rem;
  top: 0.586667rem;
  left: 1.733333rem;
  height: 5.066667rem;
}

.summary__wrapper .enData_summary .enData_right .enData_left__content .enData__line span {
  height: 5.066667rem;
}

.summary__wrapper .enData_summary .enData_right .enData_left__content .left2:nth-of-type(3) .enData__progress {
  background: linear-gradient(90deg, #5AFFF2 0%, #00B2FF 100%);
}

.summary__wrapper .enData_summary .enData_right .enData_left__content .left2:nth-of-type(4) .enData__progress {
  background: linear-gradient(90deg, #5AFFF2 0%, #00B2FF 100%);
}

.summary__wrapper .enData_summary .enData_right .enData_left__content .left2:nth-of-type(5) .enData__progress {
  background: linear-gradient(90deg, #FFD0A2 0%, #FFA400 100%);
}

.summary__wrapper .enData_summary .enData_right .enData_left__content .left2:nth-of-type(6) .enData__progress {
  background: linear-gradient(90deg, #FFD0A2 0%, #FFA400 100%);
}

.summary__wrapper .enData_summary .enData_right .enData_left__content .left2:nth-of-type(7) .enData__progress {
  background: linear-gradient(90deg, #ADFA5F 0%, #44FEBF 100%);
}

.summary__wrapper .enData_summary .enData_right .left1 {
  width: 6.666667rem;
  margin-left: 1.653333rem;
}

.summary__wrapper .enData_summary .enData_right .left3 {
  margin-left: 1.546667rem;
}

.summary__wrapper .enData_summary .enData_right .left2 {
  width: 9.333333rem;
  min-width: 7.466667rem;
  margin: 0.453333rem 0;
}

.summary__wrapper .enData_summary .enData_right .enData__progress {
  width: 0;
}

.summary__wrapper .enData_summary .enData_right .enData_left__Level {
  line-height: 0.853333rem;
  margin-bottom: 0.266667rem;
}

.summary__wrapper .enData_summary .enData_right .enData_left__score {
  flex-direction: column;
}

.summary__wrapper .tattooResults {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333;
  display: flex;
  flex-wrap: wrap;
}

.summary__wrapper .tattooResults>div {
  margin-right: 0.426667rem;
  align-items: center;
  display: flex;
}

.summary__wrapper .tattooResults>div>span {
  min-width: 1.6rem;
}

.summary__wrapper .interview_time {
  width: 100%;
  margin: 0.266667rem 0;
  padding: 0.266667rem;
  background: #f3f9ff;
  border-radius: 0.213333rem;
}

.summary__wrapper .interview_time .use_time {
  margin-bottom: 0.266667rem;
  font-size: 0.373333rem;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary__wrapper .interview_time .use_time .trans_icon {
  color: #1890ff;
}

.summary__wrapper .interview_time .use_time .trans_icon .active_icon {
  transform: rotate(90deg);
}

.summary__wrapper .interview_time .use_time .clockimg {
  width: 0.426667rem;
  height: 0.426667rem;
  margin-right: 0.053333rem;
  position: relative;
  top: 0.08rem;
}

.summary__wrapper .interview_time .time {
  border-radius: 0.133333rem;
  font-size: 0.32rem;
  color: #6d7278;
}

.summary__wrapper .interview_time .time .begin_time span {
  font-weight: 400;
}

.summary__wrapper .interview_time .time .begin_time+.begin_time {
  margin-top: 0.133333rem;
}

.summary__wrapper .sumupo {
  padding: 0.426667rem;
  margin: 0.32rem 0 0.346667rem;
  background: rgba(24,144,255,0.05);
  border-radius: 0.213333rem;
  border: 0.026667rem solid #1890ff;
}

.summary__wrapper .sumupo .mt16 {
  margin-top: 0.426667rem;
}

.summary__wrapper .sumupo .el-divider {
  margin: 0.32rem 0;
}

.summary__wrapper .sumupo .spctit {
  font-size: 0.426667rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.213333rem;
  line-height: 0.64rem;
}

.summary__wrapper .sumupo .spcdtil {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.586667rem;
}

.summary__wrapper .sumupo .spcdtil .usitmbot {
  font-family: none;
}

.summary__wrapper .tip {
  font-weight: 400;
  font-size: 0.32rem;
  color: #666666;
  line-height: 0.453333rem;
}

.summary__wrapper .l2-question {
  margin: 0 auto;
}

.summary__wrapper .l2-question .l2-q_t {
  margin-bottom: 0.533333rem;
}

.summary__wrapper .l2-question .l2-q_r {
  height: 10.666667rem;
  width: 9.6rem;
  margin: auto;
}

.summary__wrapper .unscramble {
  padding: 0.426667rem 0.426667rem 0;
  background: #f6fafc;
  border-radius: 0.213333rem;
  border: 0.026667rem solid #ddd;
}

.summary__wrapper .unscramble .ustitl {
  color: #333333;
  font-size: 0.426667rem;
  font-weight: 500;
}

.summary__wrapper .unscramble .el-divider {
  margin: 0.32rem 0;
}

.summary__wrapper .unscramble .usitm {
  margin-bottom: 0.32rem;
}

.summary__wrapper .unscramble .usitm .usitmtop {
  color: #333333;
  font-size: 0.373333rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 0.32rem;
}

.summary__wrapper .unscramble .usitm .usitmtop .huase {
  font-size: 0.373333rem;
  font-weight: 500;
  color: #ffca3a;
  margin-left: 0.133333rem;
}

.summary__wrapper .unscramble .usitm .usitmbot {
  color: #666666;
  font-size: 0.373333rem;
  font-weight: 400;
  margin: 0.133333rem 0;
  white-space: pre-wrap;
}

.summary__wrapper .unscramble .usitm .sumup {
  padding: 0.426667rem;
  border-radius: 0.213333rem;
  background: #ffffff;
  border: 0.026667rem solid #dddddd;
}

.summary__wrapper .unscramble .usitm .sumup .spctit {
  font-size: 0.373333rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.133333rem;
}

.summary__wrapper .unscramble .usitm .sumup .spcdtil {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
}

.summary__wrapper .unscramble .usitm .sumup .spcdtil .usitmbot {
  font-family: none;
}

.summary__wrapper .unscramble .usitm .sumup .spcdtil .starimg {
  width: 0.64rem;
  height: 0.32rem;
  position: relative;
  top: 0.133333rem;
}

.summary__wrapper .unscramble .usitm .sumup .spcdtil .starimg_e {
  width: 0.826667rem;
  height: 0.346667rem;
  position: relative;
  top: 0.133333rem;
}

.summary__wrapper .unscramble .usitm .sumup .hei30 {
  height: 0.8rem;
}

.mode-img1 img {
  height: 0.48rem;
}