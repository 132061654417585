.ai-home {
  position: relative;
  overflow: hidden;
}

.ai-home /deep/ .vjs-big-play-button {
  display: none;
}

.ai-home /deep/ .el-dialog__header,
.ai-home /deep/ .el-dialog__body {
  padding: 0;
}

.ai-home p {
  margin: 0;
}

.ai-home .menuIcon {
  position: fixed;
  bottom: 2.293333rem;
  right: 0.266667rem;
  font-size: 0.933333rem;
  width: 1.706667rem;
  height: 0.933333rem;
  line-height: px;
  background: #ebedf0;
  color: #1c1e21;
  border-radius: 0.133333rem;
  display: none;
  justify-content: center;
  align-items: center;
}

.ai-home .menuIcon .sidebarMenuIcon_fgN0 {
  display: block;
}

.ai-home .header {
  width: 100%;
  position: relative;
  background: linear-gradient(#468afc, #1762fa);
  padding-top: 1.2rem;
  padding-bottom: 0.8rem;
  box-sizing: border-box;
}

.ai-home .header .banner-top {
  background: #f86065;
  color: #ffffff;
  z-index: 2;
  line-height: 0.48rem;
  padding: 0.266667rem 0;
  font-size: 0.373333rem;
}

.ai-home .header .banner-top .banner-top_info {
  width: 90%;
  line-height: 0.533333rem;
  text-align: center;
  margin: auto;
  margin-bottom: 0.213333rem;
}

.ai-home .header .banner-top .banner-top_info .img-tips {
  margin-right: 0.106667rem;
  height: 0.533333rem;
}

.ai-home .header .banner-top .banner-top_info .info-zs {
  color: #ffd58d;
  margin: 0 0.133333rem;
  font-weight: 600;
}

.ai-home .header .banner-top .view-detail {
  margin: auto;
  width: 2.133333rem;
  text-align: center;
  background: #fe9f01;
  padding: 0.133333rem 0;
  border-radius: 0.8rem;
}

.ai-home .header .word-logo {
  width: 7.973333rem;
  padding: 0 0.666667rem;
  margin-top: 1.2rem;
}

.ai-home .header .word-txt {
  color: #ffffff;
  font-size: 0.586667rem;
  padding: 0 0.666667rem;
  margin-top: 1.2rem;
  font-family: DDRegular;
}

.ai-home .header .word-line {
  margin-top: 0.24rem;
  margin-left: 0.666667rem;
  background: #3deeed;
  border-radius: 0.08rem;
  width: 1.333333rem;
  height: 0.106667rem;
}

.ai-home .header .video-player-logo {
  margin-top: 0.533333rem;
  padding: 0 0.666667rem;
  position: relative;
}

.ai-home .header .video-player-logo .video-player-banner {
  height: 100%;
  position: relative;
}

.ai-home .header .video-player-logo .video-player-banner video {
  z-index: 1;
}

.ai-home .header .video-player-logo .video-player-banner /deep/ video::-webkit-media-controls-start-playback-button {
  display: none;
}

.ai-home .header .video-player-logo .video-player-banner /deep/ .video-js .vjs-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ai-home .header .video-player-logo .unmute_btn {
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1.066667rem;
  bottom: 0.8rem;
  background: rgba(0,0,0,0.5);
  width: 2.4rem;
  height: 0.666667rem;
  line-height: 0.666667rem;
  color: #ffffff;
  font-size: 0.32rem;
  border-radius: 0.373333rem;
  cursor: pointer;
}

.ai-home .header .video-player-logo .unmute_btn img {
  margin-right: 0.133333rem;
  width: 0.453333rem;
  height: 0.4rem;
}

.ai-home .header .tip-desc {
  margin-top: 1.066667rem;
  width: 100%;
  font-size: 0.48rem;
  font-weight: 600;
  color: #a0c9fd;
  line-height: 0.72rem;
  letter-spacing: 0.053333rem;
  padding: 0 0.666667rem;
  position: relative;
}

.ai-home .header .tip-desc::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -0.4rem;
  left: 0.666667rem;
  background: #5ca4ff;
  width: calc(100% - 1.333333rem);
  height: 0.026667rem;
  opacity: 0.5;
}

.ai-home .header .video-desc {
  margin-top: 0.773333rem;
  display: flex;
  overflow-y: auto;
  width: 100%;
  padding: 0 0.666667rem;
}

.ai-home .header .video-desc::-webkit-scrollbar {
  width: 0 !important;
}

.ai-home .header .video-desc .ai-videos-list {
  display: flex;
  justify-content: space-between;
}

.ai-home .header .video-desc .ai-videos-list .ai-videos-item {
  position: relative;
  cursor: pointer;
}

.ai-home .header .video-desc .ai-videos-list .ai-videos-item .content-png {
  width: 3.253333rem;
  display: block;
}

.ai-home .header .video-desc .ai-videos-list .ai-videos-item .player-png {
  display: block;
  width: 0.8rem;
  position: absolute;
  left: 40%;
  top: 30%;
}

.ai-home .header .video-desc .ai-videos-list .ai-videos-item+.ai-videos-item {
  margin-left: 0.266667rem;
}

.ai-home .header .l4-desc {
  width: 100%;
  margin-top: 0.106667rem;
  padding: 0 0.666667rem;
}

.ai-home .header .l4-desc .tip-one,
.ai-home .header .l4-desc .tip-two {
  font-size: 0.533333rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.8rem;
}

@media screen and (min-width: 768px) {
  .ai-home .header .video-desc .ai-videos-list .ai-videos-item .content-png {
    width: 6rem;
  }

  .ai-home .header .l4-desc {
    top: 17.866667rem;
  }
}

.ai-home .description {
  padding: 0.933333rem 0.666667rem 0.32rem 0.666667rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #627098;
  line-height: 0.586667rem;
}

.ai-home .section1 h3 {
  font-size: 0.4rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  text-align: center;
}

.ai-home .section1 .mood {
  width: 8.826667rem;
  height: 2.773333rem;
  margin: 0 auto;
  position: relative;
}

.ai-home .section1 .mood .mood-btn {
  width: 2.32rem;
  height: 0.933333rem;
  background: #4f8fff;
  border-radius: 0.64rem;
  color: #ffffff;
  position: absolute;
  line-height: 0.933333rem;
  text-align: center;
  font-size: 0.32rem;
}

.ai-home .section1 .mood .mood-btn:nth-child(1) {
  left: 0;
  top: 0;
}

.ai-home .section1 .mood .mood-btn:nth-child(2) {
  right: 0;
  top: 0;
}

.ai-home .section1 .mood .mood-btn:nth-child(3) {
  left: 0;
  bottom: 0;
}

.ai-home .section1 .mood .mood-btn:nth-child(4) {
  right: 0;
  bottom: 0;
}

.ai-home .section1 .mood .mood-btn:nth-child(5) {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #006afe;
}

.ai-home .section1 .mood .line1 {
  width: 1.866667rem;
  height: 1.866667rem;
  border-top: 0.026667rem dotted #4f8fff;
  border-bottom: 0.026667rem dotted #4f8fff;
  border-right: 0.026667rem dotted #4f8fff;
  border-top-right-radius: 0.933333rem;
  border-bottom-right-radius: 0.933333rem;
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.ai-home .section1 .mood .line2 {
  width: 1.866667rem;
  height: 1.866667rem;
  border-top: 0.026667rem dotted #4f8fff;
  border-bottom: 0.026667rem dotted #4f8fff;
  border-left: 0.026667rem dotted #4f8fff;
  border-top-left-radius: 0.933333rem;
  border-bottom-left-radius: 0.933333rem;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.ai-home .section1 .arrow {
  text-align: center;
  margin: 0.24rem auto 0;
}

.ai-home .section1 .arrow img {
  width: 0.666667rem;
  height: 0.96rem;
}

.ai-home .section1 .card {
  padding: 0.4rem 0.586667rem;
}

.ai-home .section1 .card .card-left {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 1.2rem;
  margin-bottom: 0.4rem;
  position: relative;
}

.ai-home .section1 .card .card-left .card-title {
  margin-bottom: 0.16rem;
  font-size: 0.293333rem;
  font-weight: 600;
  color: #4f8fff;
  line-height: 0.4rem;
}

.ai-home .section1 .card .card-left p {
  color: #b5becf;
  font-size: 0.24rem;
  font-weight: 500;
  line-height: 0.346667rem;
  margin: 0;
}

.ai-home .section1 .card .card-left>img {
  width: 1.626667rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ai-home .section1 .card:last-child .card-left {
  text-align: left;
}

.ai-home .section1 .card:last-child .card-right {
  height: 2.88rem;
}

.ai-home .section1 .card .card-right {
  box-sizing: border-box;
  background: #4f8fff;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 2.613333rem;
}

.ai-home .section1 .card .card-right .expression {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-home .section1 .card .card-right .expression .expression-left {
  text-align: center;
  position: relative;
  font-size: 0.213333rem;
  line-height: 0.346667rem;
}

.ai-home .section1 .card .card-right .expression .expression-left .expression-bottom {
  margin-top: 0.16rem;
}

.ai-home .section1 .card .card-right .expression .expression-left .expression-line {
  width: 2.56rem;
  height: 0.026667rem;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ai-home .section1 .card .card-right .expression .expression-equal {
  margin: 0 0.24rem;
}

.ai-home .section1 .card .card-right .expression-result {
  width: 3.866667rem;
  height: 0.613333rem;
  line-height: 0.613333rem;
  text-align: center;
  font-size: 0.24rem;
  background: #ff984f;
  border-radius: 0.613333rem;
  margin: 0.453333rem auto 0;
}

.ai-home .section1 .card .card-right .expression-result i {
  font-size: 0.4rem;
  font-style: normal;
}

.ai-home .section1 .card .card-right .txt-content {
  text-align: center;
}

.ai-home .section1 .card .card-right .txt-content .txt-title {
  font-size: 0.32rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.453333rem;
  margin-bottom: 0.08rem;
}

.ai-home .section1 .card .card-right .txt-content .txt-detail {
  width: 5.28rem;
  margin: 0 auto 0.2rem;
  font-size: 0.213333rem;
  color: #ffffff;
  line-height: 0.373333rem;
}

.ai-home .section1 .card .card-right .txt-content ul {
  display: inline-block;
  margin: 0 auto;
  font-size: 0.213333rem;
}

.ai-home .section1 .card .card-right .txt-content ul tr th,
.ai-home .section1 .card .card-right .txt-content ul tr td {
  width: 1.333333rem;
  height: 0.48rem;
  line-height: 0.48rem;
  text-align: center;
  box-sizing: border-box;
}

.ai-home .section1 .card .card-right .txt-content ul tr th>.star-five,
.ai-home .section1 .card .card-right .txt-content ul tr td>.star-five {
  width: 0.186667rem;
  margin-right: 0.026667rem;
}

.ai-home .section1 .card .card-right .txt-content ul tr th>.star-five:last-child,
.ai-home .section1 .card .card-right .txt-content ul tr td>.star-five:last-child {
  margin-right: 0;
}

.ai-home .section1 .title-label {
  display: flex;
  justify-content: center;
  padding-top: 0.933333rem;
  padding-bottom: 0.133333rem;
}

.ai-home .section1 .title-label .label-btn {
  width: 2.32rem;
  height: 0.933333rem;
  line-height: 0.933333rem;
  background: #006afe;
  border-radius: 0.64rem;
  color: #fff;
  text-align: center;
}

.ai-home .section2 {
  background: #ffffff;
  padding: 0 0.586667rem;
}

.ai-home .section2>.content {
  margin: 0 auto;
  padding: 1.493333rem 0 1.893333rem 0;
}

.ai-home .section2>.content h3 {
  color: #333333;
  margin-bottom: 1.333333rem;
  text-align: center;
  font-size: 0.4rem;
  font-weight: 600;
  line-height: 0.56rem;
}

.ai-home .section2>.content .solution-process {
  position: relative;
}

.ai-home .section2>.content .solution-process .box1 {
  width: 100%;
}

.ai-home .section2>.content .solution-process .box1 .left {
  border: 0.026667rem dotted #a9c8ff;
  border-bottom: transparent;
  padding-bottom: 0.533333rem;
}

.ai-home .section2>.content .solution-process .box1 .left .title {
  width: 2.32rem;
  height: 0.613333rem;
  line-height: 0.613333rem;
  text-align: center;
  background: #1890ff;
  font-size: 0.32rem;
  font-weight: 600;
  color: #ffffff;
  border-radius: 0.32rem;
  margin: 0.4rem auto 0.533333rem;
}

.ai-home .section2>.content .solution-process .box1 .left ul {
  display: flex;
  justify-content: space-around;
}

.ai-home .section2>.content .solution-process .box1 .left ul li {
  width: 2.133333rem;
  height: 2.133333rem;
  border-radius: 1.066667rem;
  text-align: center;
  background: #ecf2ff;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.533333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-home .section2>.content .solution-process .box1 .right {
  width: 100%;
  height: 0.32rem;
  overflow: hidden;
  position: relative;
}

.ai-home .section2>.content .solution-process .box1 .right .line1 {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 0.64rem;
  height: 0.026667rem;
  border-top: 0.026667rem dotted #a9c8ff;
  overflow: hidden;
  transform-origin: 0 0;
  transform: rotate(30deg);
}

.ai-home .section2>.content .solution-process .box1 .right .line2 {
  display: block;
  width: 0.64rem;
  height: 0.026667rem;
  border-top: 0.026667rem dotted #a9c8ff;
  overflow: hidden;
  transform-origin: 0.64rem 0;
  transform: rotate(-30deg);
  position: absolute;
  right: 0;
}

.ai-home .section2>.content .solution-process .box2 {
  width: 100%;
  height: 8rem;
  border: 0.026667rem dotted #a9c8ff;
  padding: 0.24rem 0.186667rem 0 0.186667rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
}

.ai-home .section2>.content .solution-process .box2 .box2-item {
  width: 45%;
  height: 3.466667rem;
  border-radius: 0.08rem;
  background: rgba(79,143,255,0.1);
}

.ai-home .section2>.content .solution-process .box2 .box2-item .title {
  height: 1.066667rem;
  background: #1890ff;
  border-radius: 0.08rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.ai-home .section2>.content .solution-process .box2 .box2-item .box2-item-content {
  height: 2.4rem;
  padding: 0.16rem 0 0.106667rem 0;
}

.ai-home .section2>.content .solution-process .box2 .box2-item .box2-item-content .option {
  text-align: center;
  font-size: 0.24rem;
  color: #333333;
  line-height: 0.346667rem;
  position: relative;
  text-align: left;
  max-width: 2.266667rem;
  margin: 0 auto;
  padding: 0.093333rem 0;
}

.ai-home .section2>.content .solution-process .box2 .box2-item .box2-item-content .option::before {
  content: '';
  width: 0.106667rem;
  height: 0.106667rem;
  border-radius: 0.053333rem;
  display: block;
  position: absolute;
  left: -0.266667rem;
  top: 0.213333rem;
  background: #006afe;
}

.ai-home .section2>.content .solution-process .icons {
  width: 100%;
  height: 1.12rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ai-home .section2>.content .solution-process .icons img {
  width: 0.48rem;
  height: 0.666667rem;
}

.ai-home .section2>.content .solution-process .box3 {
  width: 100%;
  height: 1.866667rem;
  border: 0.026667rem dotted #a9c8ff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ai-home .section2>.content .solution-process .box3 .companyItem {
  text-align: center;
  width: 2rem;
}

.ai-home .section2>.content .solution-process .box3 .companyItem img {
  display: block;
  margin: 0 auto;
  width: 0.613333rem;
  height: 0.613333rem;
}

.ai-home .section2>.content .solution-process .box3 .companyItem:first-child img {
  width: 1.013333rem;
}

.ai-home .section2>.content .solution-process .box3 .companyItem p {
  width: 100%;
  height: 0.853333rem;
  background: #4f8fff;
  color: #ffffff;
  border-radius: 0.08rem;
  margin-top: 0.08rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.32rem;
  font-weight: 500;
  line-height: 0.4rem;
}

.ai-home .section3 {
  padding: 0 0.666667rem;
}

.ai-home .section3 .left-part {
  width: 100%;
}

.ai-home .section3 .left-part .tip {
  font-size: 0.4rem;
  line-height: 0.56rem;
  font-weight: 600;
  color: #333333;
  text-align: center;
}

.ai-home .section3 .left-part .content {
  width: 100%;
  font-size: 0.32rem;
  line-height: 0.586667rem;
  color: #627098;
  margin-top: 0.933333rem;
  margin-bottom: 0.8rem;
}

.ai-home .section3 .right-part {
  position: relative;
  width: 100%;
}

.ai-home .section3 .right-part .bg {
  width: 100%;
  height: 5.04rem;
}

.ai-home .section3 .right-part .player-png {
  width: 1.68rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.ai-home .section4 {
  height: 8.906667rem;
  overflow: hidden;
  position: relative;
  margin-top: 1.386667rem;
}

@keyframes breath {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.ai-home .section4 .image_bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  animation: breath 5s ease-in-out 100ms infinite;
}

.ai-home .section4 .image_bg img {
  width: 100%;
}

.ai-home .section4 h3 {
  text-align: center;
  font-size: 0.4rem;
  color: #ffffff;
  line-height: 0.56rem;
  margin: 0.933333rem auto 0.746667rem;
  font-weight: 400;
}

.ai-home .section4 .testimony-box {
  width: 86.67%;
  height: 5.76rem;
  background: rgba(0,0,0,0.34);
  border-radius: 1.146667rem 0 1.146667rem 0;
  margin: 0 auto;
}

.ai-home .section4 .testimony-box /deep/ .el-carousel {
  height: 100%;
}

.ai-home .section4 .testimony-box .testimony-box-item {
  padding: 1.2rem 0.4rem 0.746667rem 0.4rem;
  box-sizing: border-box;
}

.ai-home .section4 .testimony-box .testimony-box-item p {
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.506667rem;
}

.ai-home .section4 .testimony-box .testimony-box-item .footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: absolute;
  bottom: 0.533333rem;
  right: 0.4rem;
}

.ai-home .section4 .testimony-box .testimony-box-item .footer .name {
  height: 0.453333rem;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.453333rem;
}

.ai-home .section4 .testimony-box .testimony-box-item .footer>img {
  width: 0.88rem;
  margin: 0 0.266667rem 0 0.32rem;
}

.ai-home .section4 .testimony-box .testimony-box-item .footer .line {
  width: 1.333333rem;
  height: 0.026667rem;
  background: #ffffff;
}

.ai-home .section5 {
  padding: 1.333333rem 0.48rem;
  background: #2e75e6;
}

.ai-home .section5 .c-title {
  font-size: 0.693333rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 1.466667rem;
  text-align: center;
}

.ai-home .section5 .customer-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ai-home .section5 .customer-wrapper .item-box {
  display: flex;
  margin-bottom: 0.533333rem;
  justify-content: center;
}

.ai-home .section5 .customer-wrapper .item-box .item {
  margin-right: 0.4rem;
  width: calc(33% - 0.4rem);
  height: 1.253333rem;
  background: #ffffff;
  border-radius: 0.213333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-home .section5 .customer-wrapper .item-box .item img {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.ai-home .section5 .customer-wrapper .item-box .item:last-child {
  margin-right: 0;
}

.ai-home .section6 {
  width: 100%;
  height: 3.2rem;
  background: #4f8fff;
  display: flex;
}

.ai-home .section6 .item-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ai-home .section6 .item-box .iso-img img {
  width: 1.173333rem;
}

.ai-home .section6 .item-box .iso-text {
  margin-top: 0.4rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #ffffff;
}

.ai-home .section6 .item-box p {
  font-size: 0.32rem;
  font-weight: 400;
  color: #ffffff;
  margin: 0.106667rem 0;
  padding: 0;
}

.ai-home .section6 .item-box .split {
  position: absolute;
  width: 0.026667rem;
  height: 2.4rem;
  right: 0;
  background: #ffffff;
}

.ai-home>.footer {
  margin-top: 1.6rem;
  padding-bottom: 0.4rem;
}

.ai-home>.footer .qrcode-box {
  display: flex;
}

.ai-home>.footer .qrcode {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.ai-home>.footer .qrcode .qrcode-img {
  text-align: center;
}

.ai-home>.footer .qrcode .qrcode-img img {
  width: 3.866667rem;
}

.ai-home>.footer .qrcode .qrcode-text {
  margin-top: 0.266667rem;
  font-size: 0.426667rem;
  font-weight: 400;
  color: #000000;
  text-align: center;
}

.ai-home>.footer .copyright {
  text-align: center;
  margin: 1.333333rem 0 0;
  font-size: 0.266667rem;
  font-weight: 400;
  color: #a6a6a6;
}

.ai-home>.footer .copyright p {
  margin: 0;
}

.ai-home>.footer .copyright .dot {
  padding: 0 0.133333rem;
}

.ai-home>.footer .copyright a {
  color: #a6a6a6;
}

.ai-home .bg1 {
  position: absolute;
  width: 4rem;
  height: 4rem;
  top: 16.266667rem;
  left: -2rem;
  z-index: -1;
}

.ai-home .advantage {
  position: relative;
  margin-top: 0.266667rem;
}

.ai-home .advantage .bg2 {
  width: 95%;
  margin-left: 5%;
  height: auto;
  height: auto;
}

.ai-home .advantage .circle-1,
.ai-home .advantage .circle-2 {
  position: absolute;
  width: 0.96rem;
  height: 0.96rem;
}

.ai-home .advantage .circle-1 {
  top: 0.533333rem;
  right: 10%;
  animation: mymove1 5s infinite;
}

.ai-home .advantage .circle-2 {
  bottom: 1.333333rem;
  left: 30%;
  animation: mymove2 3s infinite;
}

@keyframes mymove1 {
  0% {
    top: 0.533333rem;
  }

  25% {
    top: 0.266667rem;
  }

  75% {
    top: 0.8rem;
  }

  100% {
    top: 0.533333rem;
  }
}

@keyframes mymove2 {
  0% {
    bottom: 1.333333rem;
  }

  25% {
    bottom: 1.066667rem;
  }

  75% {
    bottom: 1.6rem;
  }

  100% {
    bottom: 1.333333rem;
  }
}

.ai-home .video-popup {
  overflow: hidden;
  min-height: 5.626667rem;
}

.ai-home .video-popup .unmute_btn {
  position: absolute;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0.266667rem;
  bottom: 0.8rem;
  background: rgba(0,0,0,0.5);
  padding: 0.08rem 0.16rem;
  width: 2.4rem;
  white-space: nowrap;
  line-height: 0.666667rem;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.426667rem;
}

.ai-home .video-popup .unmute_btn img {
  margin-right: 0.133333rem;
  width: 0.32rem;
}

.ai-home .video-popup .video-dialog_close {
  position: absolute;
  bottom: -1.866667rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2027;
  font-size: 1.12rem;
  color: #ffffff;
}