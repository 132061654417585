p,
h4 {
  margin: 0;
}

:deep(.van-dialog).photo-wall-dialog {
  top: 50%;
}

:deep(.van-dialog).photo-wall-dialog .van-dialog__content {
  padding: 0.426667rem;
}

:deep(.van-dialog).photo-wall-dialog .photo-list {
  height: 55vh;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

:deep(.van-dialog).photo-wall-dialog .photo-list .image-card {
  width: calc(33.3% - 0.133333rem);
  height: 2.666667rem;
  margin: 0 0.133333rem 0.426667rem 0;
}

:deep(.van-dialog).photo-wall-dialog .photo-list .image-card .image {
  width: 100%;
  height: calc(100% - 0.533333rem);
  object-fit: cover;
  vertical-align: middle;
  cursor: pointer;
}

:deep(.van-dialog).photo-wall-dialog .photo-list .image-card .image-date {
  text-align: center;
  font-size: 0.346667rem;
  font-weight: 400;
  color: #6d7278;
  line-height: 0.346667rem;
  margin-top: 0.186667rem;
}

:deep(.van-tabs) .van-tabs__line {
  background-color: #409eff;
}

:deep(.van-tabs) .van-tabs__content {
  margin-top: 0.266667rem;
}

.custom-image-viewer .wrapper {
  display: flex;
  align-items: center;
}

.custom-image-viewer .wrapper .control {
  width: 1.466667rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.373333rem;
}

.custom-image-viewer .wrapper .control .icon {
  font-size: 0.586667rem;
  color: #999999;
  cursor: not-allowed;
}

.custom-image-viewer .wrapper .control .icon.enable {
  color: #1990ff;
  cursor: pointer;
}

.custom-image-viewer .wrapper .container {
  width: calc(100% - 2.933333rem);
  padding: 0 0.4rem;
  overflow: hidden;
}

.custom-image-viewer .wrapper .container .button-container {
  text-align: center;
  margin-bottom: 0.533333rem;
}

.custom-image-viewer .wrapper .container .button-container .button {
  font-size: 0.426667rem;
  font-weight: 500;
  color: #1990ff;
  text-decoration: underline;
  line-height: 0.586667rem;
  cursor: pointer;
}

.custom-image-viewer .wrapper .container .image-container {
  display: flex;
}

.custom-image-viewer .wrapper .container .image-container .image-card {
  width: 3.786667rem;
  height: 2.88rem;
  margin-right: 0.48rem;
  flex-shrink: 0;
  transition: margin 0.3s, scale 0.3s;
}

.custom-image-viewer .wrapper .container .image-container .image-card:last-child {
  margin-right: 0;
}

.custom-image-viewer .wrapper .container .image-container .image-card.active {
  scale: 1.05;
}

.custom-image-viewer .wrapper .container .image-container .image-card.active .image {
  border: 0.053333rem solid #1990ff;
}

.custom-image-viewer .wrapper .container .image-container .image-card .image {
  width: 100%;
  height: calc(100% - 0.746667rem);
  object-fit: cover;
  vertical-align: middle;
  cursor: pointer;
}

.custom-image-viewer .wrapper .container .image-container .image-card .image-date {
  text-align: center;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #6d7278;
  line-height: 0.533333rem;
  margin-top: 0.16rem;
}

.custom-image-viewer .wrapper .container .image-container .image-card.fold {
  width: 100%;
  height: 4rem;
}

.custom-image-viewer .wrapper .container .image-container .image-card.fold .image-date {
  font-size: 0.453333rem;
  margin-top: 0.16rem;
}

.custom-image-viewer .wrapper .container .image-container .image-card.fold.active {
  scale: 1;
}