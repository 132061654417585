.l2-q_sp {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background-image: linear-gradient(to right, #bde7fe, #c9f4f4);
  border-radius: 0.266667rem;
}

.l2-q_sp .sp-i {
  flex: 1;
  height: 0.533333rem;
  display: flex;
}

.l2-q_sp .sp-i .sp-i_l {
  width: 0.053333rem;
  min-width: 0.053333rem;
  max-width: 0.053333rem;
  background: #ffffff;
}

.l2-q_sp .first {
  border-radius: 50% 0 0 50%;
  width: 0;
  min-width: 0;
}

.l2-q_sp .last {
  border-radius: 0 50% 50% 0;
  width: 0;
  min-width: 0;
}

.l2-q_sp .buoy {
  width: 20%;
  height: 0.906667rem;
  background: #44d7b6;
  color: #ffffff;
  position: absolute;
  left: -1.066667rem;
  box-shadow: 0 0.053333rem 0.266667rem 0 #44d7b6;
  text-align: center;
  border-radius: 1.333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}