.program {
  position: relative;
}

.program .bg {
  width: 100%;
  height: 17.066667rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.program .section1 .barnner {
  position: relative;
  padding: 0 0.666667rem;
  overflow: hidden;
}

.program .section1 .barnner .text-wrapper .left {
  overflow: hidden;
  color: #fff;
}

.program .section1 .barnner .text-wrapper .left .title {
  font-size: 0.64rem;
  font-weight: 600;
  line-height: 0.906667rem;
  letter-spacing: 0.08rem;
  position: relative;
  margin-top: 2.746667rem;
  text-align: center;
}

.program .section1 .barnner .text-wrapper .left .title p {
  margin: 0;
}

.program .section1 .barnner .text-wrapper .left .title .line1,
.program .section1 .barnner .text-wrapper .left .title .line2 {
  display: none;
}

.program .section1 .barnner .text-wrapper .left .detail {
  margin-top: 0.666667rem;
  font-size: 0.32rem;
  font-weight: 400;
  line-height: 0.586667rem;
}

.program .section1 .barnner .text-wrapper .right {
  display: inline-block;
  width: 100%;
  margin-top: 1.333333rem;
}

.program .section1 .barnner .text-wrapper .right>img {
  width: 100%;
  height: 5.253333rem;
}

.program .section2 {
  padding: 1.333333rem 0.666667rem 1.493333rem;
}

.program .section2 h3 {
  font-size: 0.4rem;
  color: #ffffff;
  line-height: 0.56rem;
  text-align: center;
  font-weight: 600;
}

.program .section2 ul {
  margin: 0.826667rem auto 0;
  z-index: 1;
}

.program .section2 ul li {
  width: 100%;
  height: 4.266667rem;
  background: #ffffff;
  border-radius: 0.106667rem;
  border: 0.026667rem solid #e1e6f4;
  padding: 0.4rem 0.72rem 0.64rem 0.746667rem;
  box-sizing: border-box;
  margin-bottom: 0.666667rem;
  overflow: hidden;
}

.program .section2 ul li p {
  margin: 0;
}

.program .section2 ul li:first-child {
  height: 3.386667rem;
}

.program .section2 ul li:last-child {
  height: 3.813333rem;
  margin: 0;
}

.program .section2 ul li .title {
  font-size: 0.373333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.533333rem;
  position: relative;
}

.program .section2 ul li .title::after {
  content: '';
  display: block;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 0.04rem;
  background: #333333;
  position: absolute;
  left: -0.133333rem;
  top: 0.266667rem;
}

.program .section2 ul li .detail {
  font-size: 0.32rem;
  color: #aaaaaa;
  line-height: 0.453333rem;
  margin-top: 0.4rem;
}

.program .section3 {
  padding: 0 0.666667rem;
}

.program .section3 .content h3 {
  font-size: 0.4rem;
  font-weight: 600;
  color: #333333;
  text-align: center;
  line-height: 0.56rem;
}

.program .section3 .content .box {
  margin-top: 0.666667rem;
  overflow: hidden;
}

.program .section3 .content .box .left {
  width: 100%;
}

.program .section3 .content .box .left img {
  width: 100%;
  display: block;
}

.program .section3 .content .box .right {
  width: 100%;
  padding-top: 0.933333rem;
  padding-bottom: 3.04rem;
  float: left;
  font-size: 0.32rem;
  color: #627098;
  line-height: 0.586667rem;
}

.program .bg1 {
  position: absolute;
  left: 0;
  bottom: 13.04rem;
  width: 5.733333rem;
  height: 11.466667rem;
  overflow: hidden;
}

.program .bg1 img {
  width: 5.733333rem;
  height: 11.466667rem;
  position: relative;
  left: 0;
  top: 0;
}