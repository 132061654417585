.el-carousel_awards {
  margin: auto;
  border-radius: 0.133333rem;
}

.el-carousel_awards .el-carousel__container {
  height: 4.8rem;
  box-shadow: 0 0.213333rem 0.426667rem 0 rgba(0,35,67,0.05);
}

.el-carousel_awards .el-carousel__container .el-carousel__item {
  display: flex;
  background: url("~@/assets/images/company-awards/awards_card_bg@2x.png");
  background-size: cover;
}

.el-carousel_awards .el-carousel__container .el-carousel__item .l {
  padding: 0 0.266667rem 0 0.133333rem;
}

.el-carousel_awards .el-carousel__container .el-carousel__item .l img {
  width: 3.2rem;
}

.el-carousel_awards .el-carousel__container .el-carousel__item .r {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.133333rem;
}

.el-carousel_awards .el-carousel__container .el-carousel__item .r .tips {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #555555;
  line-height: 0.48rem;
  text-align: left;
  display: flex;
  align-items: baseline;
}

.el-carousel_awards .el-carousel__container .el-carousel__item .r .tips .dot-blue {
  width: 0.133333rem;
  height: 0.133333rem;
  background: #ffffff;
  border: 0.106667rem solid #1990ff;
  border-radius: 50%;
  margin-right: 0.266667rem;
}