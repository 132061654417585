.answer-text {
  padding: 0.32rem 0.426667rem;
  border-radius: 0.106667rem;
  border: 0.026667rem solid #DDDDDD;
  color: #333333;
}

.answer-text .khsrl {
  font-weight: 600;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.64rem;
}

.answer-text .khsrl .hsspan {
  font-weight: 400;
  margin-left: 0.213333rem;
}

.answer-text .khsrl .unlock {
  float: right;
  font-weight: normal;
  color: #1890FF;
  line-height: 0.64rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer-text .khsrl .unlock .un_fold {
  width: 0.373333rem;
  height: 0.373333rem;
  margin-left: 0.133333rem;
}

.answer-text .khsrl .unlock .roate180 {
  transform: rotate(180deg);
}

.answer-text .el-divider {
  margin: 0.213333rem 0;
}

.answer-text .rich-text {
  word-break: break-word;
  font-weight: 400;
  font-size: 0.373333rem;
  color: #666666;
  line-height: 0.64rem;
  margin-top: 0.08rem;
}