.about {
  overflow: hidden;
}

.about .section1 {
  position: relative;
}

.about .section1 .barnner {
  position: relative;
  max-height: 6.4rem;
  overflow: hidden;
}

.about .section1 .barnner .banner-img {
  display: block;
  width: 100%;
  height: auto;
}

.about .section1 .barnner .text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 6.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about .section1 .barnner .text-wrapper span {
  display: block;
  font-size: 0.64rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.906667rem;
  letter-spacing: 0.08rem;
}

.about .section2 {
  padding: 1.066667rem 0.666667rem 1.333333rem;
}

.about .section2 .title {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.666667rem;
  text-align: center;
}

.about .section2 p {
  margin: 0;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #627098;
  line-height: 0.586667rem;
}

.about .section4 {
  padding: 1.333333rem 0.666667rem;
}

.about .section4 .title {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  text-align: center;
}

.about .section4 .scientist {
  margin-top: 0.666667rem;
}

.about .section4 .scientist li {
  margin-bottom: 0.533333rem;
  box-shadow: 0 0 0.213333rem 0 rgba(13,20,50,0.2);
  border-radius: 0.106667rem;
  position: relative;
}

.about .section4 .scientist li:last-child {
  margin: 0;
}

.about .section4 .scientist li .left-part {
  position: absolute;
  left: 0.4rem;
  top: 0.533333rem;
}

.about .section4 .scientist li .left-part img {
  display: block;
  width: 1.12rem;
  height: 1.12rem;
}

.about .section4 .scientist li .right-part {
  padding: 0.533333rem 0.4rem;
}

.about .section4 .scientist li .right-part .name {
  font-size: 0.373333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.533333rem;
  margin-left: 1.333333rem;
  margin-top: 0.08rem;
}

.about .section4 .scientist li .right-part .position {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.453333rem;
  margin-left: 1.333333rem;
}

.about .section4 .scientist li .right-part .description {
  margin-top: 0.346667rem;
}

.about .section4 .scientist li .right-part .description .paragraph {
  margin-bottom: 0.266667rem;
  display: flex;
}

.about .section4 .scientist li .right-part .description .paragraph div:first-of-type {
  margin-right: 0.213333rem;
  text-align: center;
}

.about .section4 .scientist li .right-part .description .paragraph div:first-of-type span {
  display: inline-block;
  width: 0.053333rem;
  height: 0.053333rem;
  border-radius: 0.026667rem;
  background: #627098;
}

.about .section4 .scientist li .right-part .description .paragraph div:last-of-type {
  font-size: 0.266667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.373333rem;
}