.offline-interview .card {
  min-height: 7.466667rem;
  margin: 0 auto;
  padding: 0.64rem 0.533333rem;
}

.offline-interview .card .first_line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.066667rem;
}

.offline-interview .card .first_line .interview_round {
  line-height: 0.586667rem;
}

.offline-interview .card .first_line .interview_round .round_label {
  width: 1.813333rem;
  height: 0.533333rem;
  background: #44d7b6;
  box-shadow: 0 0.053333rem 0.266667rem 0 rgba(80,98,118,0.2);
  border-radius: 0.053333rem;
  font-size: 0.373333rem;
  color: #ffffff;
  padding: 0 0.16rem;
  margin-right: 0.266667rem;
}

.offline-interview .card .first_line .interview_round .round_name {
  height: 0.586667rem;
  line-height: 0.586667rem;
  font-size: 0.426667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.586667rem;
  text-shadow: 0 0.053333rem 0.266667rem rgba(80,98,118,0.2);
}

.offline-interview .card .first_line .interview_round .round_name+.round_name {
  margin-left: 0.266667rem;
}

.offline-interview .card .first_line .interview_round::before {
  content: '';
  display: inline-block;
  width: 0.213333rem;
  height: 0.213333rem;
  background: #4f8fff;
  box-shadow: 0 0.053333rem 0.266667rem 0 rgba(80,98,118,0.2);
  border-radius: 50%;
  position: relative;
  right: 0.133333rem;
}

.offline-interview .card .first_line .create_time {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 0.533333rem;
  text-shadow: 0 0.053333rem 0.266667rem rgba(80,98,118,0.2);
}

.offline-interview .card .bottom {
  padding: 0.266667rem 0;
  background: #f8f8f8;
  border-radius: 0.133333rem;
}

.offline-interview .card .interview_mark {
  padding-bottom: 0.266667rem;
  border-bottom: 0.026667rem solid #dddddd;
}

.offline-interview .card .line_item {
  display: flex;
  margin-bottom: 0.266667rem;
  margin-left: 0.266667rem;
}

.offline-interview .card .line_item .label {
  min-width: 2.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.533333rem;
  text-shadow: 0 0.053333rem 0.266667rem rgba(80,98,118,0.2);
  white-space: nowrap;
}

.offline-interview .card .line_item .result {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.533333rem;
  background: rgba(0,181,80,0.69);
  border-radius: 0.053333rem;
  text-shadow: 0 0.053333rem 0.266667rem rgba(80,98,118,0.2);
  padding: 0 0.266667rem;
}

.offline-interview .card .line_item .content {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
  text-shadow: 0 0.053333rem 0.266667rem rgba(80,98,118,0.2);
}

.offline-interview .card .line_item .content .interview_remark+.interview_remark {
  margin-top: 0.106667rem;
}

.offline-interview .card+.card {
  border-top: 0.266667rem solid #f3f3f3;
}