.attach-info .info-list {
  padding: 0 0.533333rem;
}

.attach-info .title {
  font-size: 0.373333rem;
  color: #333;
}

.attach-info .attach-list .attach-item {
  padding: 0.213333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attach-info .attach-list .attach-item .name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 0.48rem;
}

.attach-info .attach-list .attach-item .button {
  cursor: pointer;
  color: #e02020;
}

.attach-info .attach-list .attach-item .preview {
  color: #1890ff;
}

.attach-info .bd-list .bd-item {
  line-height: 0.746667rem;
}

.attach-info .bd-list .bd-item .label {
  margin-right: 0.266667rem;
}

.attach-info .no-data {
  text-align: center;
}

.attach-info .no-data img {
  margin: 1.6rem auto;
  width: 40%;
  height: auto;
}