.resume-content {
  padding: 0.2rem 0;
}

.resume-content .origin-resume-wrapper {
  min-height: 100vh;
  overflow: scroll;
  position: relative;
}

.resume-content .origin-resume-wrapper i {
  position: absolute;
  top: 0.133333rem;
  right: 0.32rem;
  font-size: 0.48rem;
  color: #ffffff;
  background: rgba(51,51,51,0.5);
  width: 0.853333rem;
  height: 0.853333rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.resume-content .origin-resume-wrapper .imgPrev {
  width: 100vw;
  overflow: hidden;
}

.resume-content .origin-resume-wrapper .imgPrev figure {
  margin: 0;
}

.resume-content .origin-resume-wrapper .imgPrev img {
  width: 100vw;
  overflow: hidden;
}

.priview_image {
  width: 100vw;
}

.image_popup {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}