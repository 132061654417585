.font-small {
  font-size: 0.266667rem;
}

.font-12 {
  font-size: 0.32rem;
}

.font-mini {
  font-size: 0.16rem;
  color: #666666;
  text-align: right;
  margin-right: 0.266667rem;
  margin-top: -0.133333rem;
}

.font-blod {
  font-weight: bold;
  font-size: 0.533333rem;
  line-height: 0.746667rem;
  letter-spacing: 0.08rem;
  text-align: center;
  margin: 0.16rem 0 0.533333rem 2.266667rem;
}

.font-blod span {
  width: 7.466667rem;
  display: inline-block;
}

.font-light {
  font-weight: 300;
}

.font-15 {
  font-size: 0.4rem;
}

.margin-bottom-10 {
  margin-bottom: 0.266667rem;
}

.margin-bottom-5 {
  margin-bottom: 0.133333rem;
}

.module {
  padding-left: 0.106667rem;
  padding-bottom: 0.533333rem;
}

.module /deep/ .van-cell__title span {
  font-weight: bold;
}

.module /deep/.van-panel__content {
  padding: 0 0 0 0.426667rem;
}

.module /deep/.van-cell:not(:last-child)::after {
  border-bottom-color: #ededed;
}

.module /deep/.van-steps .van-step__title {
  padding-right: 0.533333rem;
}

.module .ed-time {
  color: #666666;
}

.module .ed-title {
  margin: 0.266667rem 0 0.186667rem;
  color: #333333;
}

.module .ed-content {
  color: #999999;
  text-align: justify;
}

.module .top-emotion {
  padding-right: 0.533333rem;
}

.module .top-emotion .item_li {
  display: flex;
}

.module .top-emotion .item_li span:first-child {
  flex-shrink: 0;
}

.module .card {
  height: 2.666667rem;
  width: 3.146667rem;
  border-radius: 0.16rem;
  background-color: #f9f9f9;
  text-align: center;
  margin-top: 0.533333rem;
  margin-bottom: 0.106667rem;
  color: #333333;
}

.module .right-card {
  box-sizing: border-box;
  padding: 0.266667rem 0.533333rem;
}

.module .right-card div:first-child {
  margin-bottom: 0.4rem;
}

.module .right-card div:nth-child(2) {
  font-size: 0.266667rem;
  margin-bottom: 0.213333rem;
}

.module .right-card .van-progress /deep/ .van-progress__portion {
  background: linear-gradient(90deg, #35c3ff 0%, #1890ff 100%);
}

.module .right-card .van-row:last-child {
  color: #666666;
  font-size: 0.16rem;
  margin-top: 0.133333rem;
}

.module .ul {
  font-size: 0.32rem;
  font-weight: 300;
  color: #666666;
  list-style: disc;
  list-style-position: inside;
}

.module .ul li {
  margin: 0.133333rem 0;
}

/deep/ .module:after {
  border-bottom: 0.266667rem solid #f4f4f4;
}