.l2-question {
  margin-top: 0.533333rem;
}

.l2-question .l2-q_t {
  margin-bottom: 0.533333rem;
}

.l2-question .l2-q_ranking {
  margin-bottom: 0.533333rem;
}

.l2-question .l2-q_ranking .l2-q_sp {
  display: flex;
  align-items: center;
  margin: auto;
  width: 8.106667rem;
  position: relative;
  background-image: linear-gradient(to right, #bde7fe, #c9f4f4);
  border-radius: 0.533333rem;
}

.l2-question .l2-q_ranking .l2-q_sp .sp-i {
  width: 0.053333rem;
  min-width: 0.053333rem;
  height: 0.533333rem;
  background: #ffffff;
}

.l2-question .l2-q_ranking .l2-q_sp .first {
  border-radius: 50% 0 0 50%;
  width: 0;
  min-width: 0;
}

.l2-question .l2-q_ranking .l2-q_sp .last {
  border-radius: 0 50% 50% 0;
  width: 0;
  min-width: 0;
}

.l2-question .l2-q_ranking .l2-q_sp .buoy {
  width: 22%;
  height: 0.906667rem;
  background: #44d7b6;
  color: #ffffff;
  position: absolute;
  left: -1.066667rem;
  box-shadow: 0 0.053333rem 0.266667rem 0 #44d7b6;
  text-align: center;
  border-radius: 1.333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l2-question .l2-q_ranking .l2-q_sp .sp-i+.sp-i {
  margin-left: 0.373333rem;
}

.l2-question .l2-q_r {
  height: 6.933333rem;
  width: 9.6rem;
  margin: auto;
  margin-bottom: 0.533333rem;
}

.l2-question .l2-title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 0.426667rem;
}

.l2-question .l2-q_item .stem {
  margin-bottom: 0.266667rem;
}

.l2-question .l2-q_item .tip {
  margin-bottom: 0.266667rem;
}

.l2-question .l2-q_item .score .total {
  display: flex;
  align-items: center;
}

.l2-question .l2-q_item .score .total .detail-bt {
  cursor: pointer;
  color: #1890ff;
}

.l2-question .l2-q_item .score .weight {
  margin-bottom: 0.266667rem;
  font-weight: 600;
}

.l2-question .l2-q_item .score .detail .score-wrapper {
  margin: 0.533333rem 0 0 0;
  padding: 0 0 0.266667rem 0;
}

.l2-question .l2-q_item .score .detail .score-wrapper .desc {
  margin-top: 0.533333rem;
  color: #afb6ca;
  padding-left: 0.533333rem;
}

.l2-question .l2-q_item .score .detail .score-wrapper .info {
  display: none;
  margin-top: 0.213333rem;
  color: #333333;
  padding: 0 0.533333rem;
}

.l2-question .l2-q_item .score .detail .score-wrapper .process-wrapper {
  margin-top: 0.16rem;
}

.l2-question .l2-q_item .score .detail .score-wrapper .process-wrapper-other {
  padding: 0 0.533333rem;
}

.l2-question .l2-q_item .score .detail .score-wrapper .score-explain {
  margin-top: 0.213333rem;
  padding-left: 0.533333rem;
  color: #afb6ca;
}

.l2-question .l2-q_item .score .detail .score-wrapper:hover {
  box-shadow: 0.053333rem 0.106667rem 0.266667rem 0 rgba(169,193,205,0.34);
}

.l2-question .l2-q_item .score .detail .score-wrapper:hover .info {
  display: block;
}

.l2-question .l2-q_item .score .detail .score-wrapper-download {
  box-shadow: 0.053333rem 0.106667rem 0.266667rem 0 rgba(169,193,205,0.34);
  padding-top: 0.266667rem;
}

.l2-question .l2-q_item .score .detail .score-wrapper-download .desc {
  margin-top: 0;
}

.l2-question .l2-q_item .score .detail .score-wrapper-download .info {
  display: block;
}