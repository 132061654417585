.user {
  font-size: 0.426667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  padding-left: 0.4rem;
}

.select_option {
  display: flex;
  justify-content: space-between;
  min-height: 1.066667rem;
  width: 100%;
  padding: 0.4rem 0;
  padding-left: 0.266667rem;
  border-bottom: 0.026667rem solid #dddddd;
}

.select_option .option_left {
  display: flex;
  align-items: center;
}

.select_option .option_left .float_left {
  height: 1.066667rem;
  width: 1.066667rem;
  border: 0.026667rem solid;
  border-radius: 1.2rem;
  text-align: center;
  background: #1890ff;
  line-height: 1.066667rem;
  color: #ffffff;
  font-size: 0.373333rem;
  margin-left: 0.266667rem;
}

.select_option .option_left .float_right {
  margin-left: 0.266667rem;
}

.select_option .option_left .float_right div {
  line-height: 0.48rem;
  margin-bottom: 0.106667rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select_option .option_left .float_right .user_name {
  font-size: 0.373333rem;
  font-weight: 600;
  color: #333333;
}

.select_option .option_left .float_right .user_type {
  font-size: 0.32rem;
  font-weight: 400;
  color: #aaaaaa;
}

.select_option .option_right {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 1.013333rem;
  margin-right: 0.533333rem;
}

.selected_foot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.6rem;
  background: #ffffff;
  z-index: 1;
  padding: 0 0.533333rem;
  font-size: 0.373333rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected_foot .btn {
  border: 0.026667rem solid #1890ff;
  color: #1890ff;
  padding: 0.266667rem 0.266667rem;
  border-radius: 0.8rem;
  line-height: 0.373333rem;
}

.remark_dialog {
  padding: 0.266667rem 0.4rem;
}

.remark_dialog .van-dialog__content .title {
  margin-bottom: 0.266667rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remark_dialog .van-dialog__content .van-cell {
  border: 0.026667rem solid #dddddd;
  border-radius: 0.133333rem;
}

.remark_dialog .van-dialog__content .confirm_btn {
  margin-top: 0.266667rem;
  display: flex;
  flex-direction: row-reverse;
}