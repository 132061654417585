.professional-character {
  margin-top: 0.533333rem;
}

.bar-wrapper {
  margin: 0 auto;
  background-color: #f0f3f7;
  overflow: hidden;
  border-radius: 0.24rem;
  position: relative;
  width: 2.666667rem;
  height: 0.426667rem;
}

.bar-wrapper .bar-content {
  position: absolute;
  height: 0.426667rem;
  border-radius: 0.24rem;
}

.bar-wrapper .blue {
  background-color: #14b1ff;
}

.bar-wrapper .red {
  background-color: #ff0000;
}

.number {
  width: 2.666667rem;
  margin: 0 auto;
  font-size: 0.32rem;
  color: #333333;
}

.eCharts-content {
  width: 100%;
}

.eCharts-content #eCharts-wrapper {
  box-sizing: border-box;
  width: 100%;
  margin: 1rem auto 0 auto;
  height: 8rem;
  display: flex;
  justify-content: center;
}

.summary-note-wrapper {
  margin: 0.426667rem auto 0 auto;
  border: 0.026667rem dashed #d6dde8;
  color: #333333;
  padding: 0.4rem 0.266667rem;
  font-size: 0.373333rem;
  border-radius: 0.133333rem;
}

.summary-note-wrapper .note-title {
  font-size: 0.373333rem;
  font-weight: 600;
}

.summary-note-wrapper .note-content {
  margin-top: 0.266667rem;
  font-size: 0.373333rem;
  line-height: 0.64rem;
}

.table-wrapper .table-wrapper_item {
  border-bottom: 0.026667rem solid #dddddd;
}

.table-wrapper .table-wrapper_item .dimension {
  font-size: 0.373333rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
}

.table-wrapper .table-wrapper_item .character {
  display: flex;
  justify-content: space-between;
}

.table-wrapper .table-wrapper_item .character .character-item {
  max-width: 40%;
  font-size: 0.32rem;
  font-weight: 400;
  color: #afb6ca;
  line-height: 0.453333rem;
  margin-top: 0.266667rem;
}

.table-wrapper .table-wrapper_item .comment {
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-top: 0.533333rem;
  padding-bottom: 0.773333rem;
}

.table-wrapper .table-wrapper_item+.table-wrapper_item {
  margin-top: 0.8rem;
}

.character-title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.266667rem;
}

.character-content {
  line-height: 0.64rem;
  font-size: 0.373333rem;
  color: #333333;
}