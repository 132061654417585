.ai-vidio {
  position: relative;
  height: 100vh;
}

.ai-vidio .header {
  width: 100%;
  height: 6.4rem;
  position: relative;
}

.ai-vidio .header .img-bg {
  width: 100%;
}

.ai-vidio .header .logo {
  position: absolute;
  top: 0.533333rem;
  left: 0.666667rem;
  height: 1.12rem;
}

.ai-vidio .header .text {
  position: absolute;
  font-size: 0.693333rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  top: 2.133333rem;
  width: 100%;
}

.ai-vidio .header .text p {
  width: 100%;
  text-align: center;
  margin: 0;
}

.ai-vidio .content-wrapper {
  padding-top: 1.706667rem;
  position: relative;
  padding-bottom: 2.133333rem;
}

.ai-vidio .content-wrapper .title-box {
  position: absolute;
  top: 0.8rem;
  z-index: 2;
  width: 100%;
}

.ai-vidio .content-wrapper .title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 1.6rem;
  margin: 0 auto;
  background: linear-gradient(90deg, #09f 0%, #0064fa 100%);
  border-radius: 0.64rem 0 0.64rem 0;
}

.ai-vidio .content-wrapper .title span {
  font-size: 0.64rem;
  font-weight: 500;
  color: #ffffff;
}

.ai-vidio .content-wrapper .content-box {
  margin: 0 5%;
  padding: 1.066667rem 5%;
  background: #ffffff;
  box-shadow: 0 0.053333rem 0.213333rem 0 rgba(0,0,0,0.06);
  border-radius: 0.106667rem;
}

.ai-vidio .content-wrapper .content-box .text-tags {
  display: flex;
  margin: 0.4rem 0;
  justify-content: space-between;
}

.ai-vidio .content-wrapper .content-box .text-tags .tag {
  width: calc(50% - 0.213333rem);
  padding: 0.4rem;
  background: #e1eeff;
  border-radius: 0.133333rem;
  font-size: 0.426667rem;
  font-weight: 700;
  color: rgba(0,0,0,0.7);
  text-align: center;
}

.ai-vidio .content-wrapper .content-box p {
  margin: 0;
  padding: 0;
}

.ai-vidio .content-wrapper .content-box .text {
  margin-top: 0.8rem;
  font-size: 0.426667rem;
  font-weight: 400;
  color: #000000;
  line-height: 0.746667rem;
}