.process-wrapper {
  display: flex;
  margin-top: 0.16rem;
}

.process-wrapper .process-item {
  flex: 1;
  height: 0.533333rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.process-wrapper .process-item:not(:first-child) {
  margin-left: 0.053333rem;
}