.ats {
  overflow: hidden;
  position: relative;
}

.ats .section1 .barnner {
  position: relative;
  max-height: 6.4rem;
  overflow: hidden;
}

.ats .section1 .barnner .banner-img {
  display: block;
  width: 100%;
  height: auto;
}

.ats .section1 .barnner .text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 6.4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ats .section1 .barnner .text-wrapper span {
  font-size: 0.64rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.906667rem;
  letter-spacing: 0.08rem;
}

.ats .content {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0.986667rem;
}

.ats .content .floor {
  padding-top: 1.066667rem;
}

.ats .content .floor h3 {
  margin-bottom: 0.613333rem;
  font-size: 0.4rem;
  font-weight: 600;
  color: #333333;
  line-height: 1.12rem;
  text-align: center;
}

.ats .content .floor p {
  font-size: 0.32rem;
  font-weight: 400;
  color: #627098;
  line-height: 0.586667rem;
  width: 89.33%;
  margin: 0 auto 0.64rem;
}

.ats .content .floor img {
  width: 89.33%;
  display: block;
  margin: 0 auto;
}

.ats .bg1 {
  position: absolute;
  left: 0;
  bottom: 7.04rem;
  width: 2.186667rem;
  height: 4.373333rem;
  overflow: hidden;
}

.ats .bg1 img {
  height: 100%;
  position: relative;
  left: -2.186667rem;
  top: 0;
}