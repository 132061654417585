.player {
  width: 100%;
}

.player .video-js {
  width: 100%;
  height: 5.333333rem;
}

.player .video-js video {
  width: 100%;
}

.player .video-js .vjs-big-play-button {
  display: none;
}

.player .video-js .vjs-control-bar {
  display: flex;
  visibility: visible;
  opacity: 1;
}

.obj-answer pre p {
  margin: 0 !important;
}