.interviewPlanContent .log-wrapper {
  width: 100%;
  padding: 0.6rem 0.5rem;
}

.interviewPlanContent .log-wrapper .time-title {
  width: 100%;
  display: flex;
  padding-left: 0.266667rem;
  align-items: center;
}

.interviewPlanContent .log-wrapper .time-title .week {
  color: #333333;
  font-weight: 600;
  font-size: 0.426667rem;
  margin-right: 0.266667rem;
}

.interviewPlanContent .log-wrapper .time-title .week .interviewDate {
  margin-left: 0.133333rem;
}

.interviewPlanContent .log-wrapper .time-title .responseStatus {
  width: 1.386667rem;
  font-size: 0.32rem;
  text-align: center;
  color: #ffffff;
  border-radius: 0.053333rem;
  text-align: center;
  padding: 0.053333rem 0;
  margin-right: 0.16rem;
}

.interviewPlanContent .log-wrapper .time-title .interview-mode {
  width: 2.4rem;
  padding: 0.026667rem 0;
  border-radius: 0.053333rem;
  font-size: 0.32rem;
  text-align: center;
}

.interviewPlanContent .log-wrapper .time-title .online {
  color: #1890ff;
  border: 0.026667rem solid #1890ff;
}

.interviewPlanContent .log-wrapper .time-title .offline {
  color: #44d7b6;
  border: 0.026667rem solid #44d7b6;
}

.interviewPlanContent .log-wrapper .time-title .accept {
  background-color: #00b550;
}

.interviewPlanContent .log-wrapper .time-title .reject {
  background-color: #f15959;
}

.interviewPlanContent .log-wrapper .time-title .cancle {
  background-color: #e02020;
}

.interviewPlanContent .log-wrapper .time-title .no-response {
  background-color: #f7b500;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan {
  margin-top: 0.426667rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .detail-row {
  width: 100%;
  padding-left: 0.266667rem;
  display: flex;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .detail-row .detail-label {
  width: 2.666667rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .detail-row .detail-content {
  width: calc(100% - 2.933333rem);
  color: #5c5e61;
  font-weight: 600;
  font-size: 0.373333rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .detail-row:not(:first-child) {
  margin-top: 0.266667rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper {
  background-color: #f8f8f8;
  width: 100%;
  padding: 0.266667rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row {
  display: flex;
  color: #5c5e61;
  font-size: 0.373333rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row .interviewer-label {
  width: 2.666667rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row .interviewer-content {
  width: calc(100% - 2.666667rem);
  font-weight: 600;
  word-break: break-all;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row .interviewer-content .tips {
  width: 100%;
  color: #aaaaaa;
  font-size: 0.32rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row .interviewer-content .interviewResult-text {
  color: #333333;
  font-weight: 600;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row .interviewer-content .zn-interview-feedback {
  display: flex;
  align-items: center;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row .interviewer-content .unfinish {
  border: 0.026667rem solid #627098;
  color: #627098;
  padding: 0.026667rem 0.266667rem;
  border-radius: 0.053333rem;
  display: block;
  font-weight: 500;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row .interviewer-content .unfinish-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row .interviewer-content .unfinish-wrapper .unfinish {
  border: 0.026667rem solid #627098;
  color: #627098;
  padding: 0.026667rem 0.266667rem;
  border-radius: 0.053333rem;
  display: block;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .interviewer-row:not(:first-child) {
  margin-top: 0.266667rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper-plan .interviewer-info-list-wrapper .hr {
  width: 100%;
  height: 0.026667rem;
  background-color: #dddddd;
  margin-top: 0.133333rem;
  min-height: 0.026667rem;
}

.interviewPlanContent .log-wrapper .red {
  color: red !important;
}

.interviewPlanContent .log-wrapper .green {
  color: #1985ff !important;
}

.interviewPlanContent .log-wrapper .orange {
  color: orange;
}

.interviewPlanContent .log-wrapper .van-step__title--active {
  color: #999999 !important;
}

.interviewPlanContent .log-wrapper .spot {
  width: 0.266667rem;
  height: 0.266667rem;
  background: #1890ff;
  border-radius: 100%;
  box-sizing: content-box;
}

.interviewPlanContent .log-wrapper .row1 {
  display: flex;
  justify-content: space-between;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 {
  margin-top: 0.3rem;
  background: #fafafa;
  border-radius: 0.213333rem;
  padding: 0.5rem 0 0.5rem 0.3rem;
  position: relative;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .tip {
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  width: 1.893333rem;
  height: 1.68rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .tip span {
  transform: rotate(45deg) translateY(0.24rem) translateX(0.266667rem);
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.32rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item {
  font-size: 0.373333rem;
  margin: 0 0 0.533333rem 0.266667rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item .edit-content {
  display: flex;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item .edit-content .van-radio--horizontal {
  margin-right: 0.2rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item .feedback-content textarea {
  border: 0.03rem solid #eaeaea;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item:last-child {
  margin-bottom: 0;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item:after {
  content: '';
  clear: both;
  display: block;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item .value {
  margin-left: 0.533333rem;
  color: #333333;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item .value .interviewUrl {
  width: 2rem;
  display: inline-block;
  border: none;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item .value .van-cell {
  padding: 0;
  margin-top: 0.3rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .item .value .van-cell textarea {
  border: 0.03rem solid #eaeaea;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .submit-item {
  font-size: 0.373333rem;
  width: 100%;
  padding-left: 0.266667rem;
  margin: 0 0 0.533333rem 0;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .submit-item .row1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .submit-item .row1 .label {
  color: #999999;
  width: 1.493333rem;
  white-space: nowrap;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .submit-item .row1 .value {
  width: 100%;
  padding-left: 0.533333rem;
  color: #333333;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .submit-item .row1 .value .van-radio-group {
  display: flex;
  justify-content: space-between;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .submit-item .van-cell {
  padding: 0;
  margin-top: 0.3rem;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .submit-item .van-cell textarea {
  border: 0.03rem solid #eaeaea;
}

.interviewPlanContent .log-wrapper .detail-wrapper2 .submit-item .row3 {
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-end;
}

.interviewPlanContent .description {
  color: #333333;
  text-align: center;
  font-size: 0.3rem;
  line-height: 0.6rem;
}

.interviewPlanContent .goUpdateDatePick {
  font-size: 0.4rem;
  width: 4.2rem;
  margin: 1rem auto 0 auto;
  background-color: #1890ff;
  text-align: center;
  color: #ffffff;
  padding: 0.2rem 0;
}

.interviewPlanContent .nocandidate-wrapper .nocandidate {
  text-align: center;
}

.interviewPlanContent .nocandidate-wrapper .nocandidate img {
  width: 6.666667rem;
  height: 4.96rem;
}

.interviewPlanContent .nocandidate-wrapper .nocandidate .text {
  margin-top: 0.533333rem;
  color: #666666;
  text-align: center;
  font-size: 0.533333rem;
}

.interviewPlanContent .copy-text {
  color: #1890ff;
  margin-left: 0.4rem;
  cursor: pointer;
  white-space: nowrap;
}

.interviewPlanContent .feed-btn {
  color: #1890ff;
  cursor: pointer;
}

.interviewPlanContent .disabled {
  color: #c0c4cc !important;
  cursor: not-allowed !important;
}