.psychology-wrapper {
  margin-top: 0.533333rem;
}

.psychology-wrapper .wrapper-text {
  font-size: 0.426667rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.266667rem;
}

.psychology-wrapper .wrapper-content {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.64rem;
}

.psychology-wrapper .psychology-line1 {
  margin-top: 0.426667rem;
}

.psychology-wrapper .psychology-line1 .pre-text {
  font-weight: 600;
  color: #111f2c;
}

.psychology-wrapper .psychology-line1 .mental-score-status {
  margin-left: 0.266667rem;
}

.psychology-wrapper .psychology-line1 .mental-score-status .score-status {
  color: #1890ff;
}

.psychology-wrapper .psychology-line2 {
  margin-top: 0.4rem;
}

.psychology-wrapper .psychology-line2 .pre-text {
  font-weight: 600;
  color: #111f2c;
}

.psychology-wrapper .psychology-lable-wrapper {
  margin-top: 0.8rem;
}

.psychology-wrapper .psychology-lable-wrapper .show-detail-score {
  text-align: right;
  color: #1890ff;
  cursor: pointer;
}

.psychology-wrapper .psychology-lable-wrapper .lable-wrapper-title {
  width: 3.146667rem;
  height: 0.533333rem;
  line-height: 0.533333rem;
  border-radius: 0.266667rem;
  text-align: center;
  color: #ffffff;
}

.psychology-wrapper .psychology-lable-wrapper .spanTitle {
  padding: 0.053333rem 0.533333rem;
}

.psychology-wrapper .psychology-lable-wrapper .positive {
  background-color: #56aeff;
}

.psychology-wrapper .psychology-lable-wrapper .nagative {
  background-color: #f36d55;
}

.psychology-wrapper .psychology-lable-wrapper .lable-row {
  margin-top: 0.266667rem;
}

.psychology-wrapper .psychology-lable-wrapper .lable-row .lable-title {
  font-weight: 600;
}

.psychology-wrapper .psychology-lable-wrapper .lable-row .green {
  color: #00ad47;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper {
  margin-top: 0.266667rem;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-text {
  font-weight: 600;
  color: #333333;
  margin-bottom: 0.16rem;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content {
  width: 100%;
  display: flex;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .bar-item {
  flex: 1;
  margin-left: 0.106667rem;
  width: 1.84rem;
  height: 0.533333rem;
  text-align: center;
  line-height: 0.533333rem;
  border-radius: 0.053333rem;
  cursor: pointer;
  color: #ffffff;
  font-weight: 600;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .color1 {
  background: #ffeae3;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .color2 {
  background: #fcf2df;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .color3 {
  background: #bde7fe;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .color4 {
  background: #c9f4f4;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .color1-active {
  background: #f36d55;
  box-shadow: 0 0.053333rem 0.266667rem 0 #ff9683;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .color2-active {
  background: #ffaa5b;
  box-shadow: 0 0.053333rem 0.266667rem 0 rgba(238,116,72,0.5);
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .color3-active {
  background: #56aeff;
  box-shadow: 0 0.053333rem 0.266667rem 0 rgba(24,144,255,0.5);
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .color4-active {
  background: #44d7b6;
  box-shadow: 0 0.053333rem 0.266667rem 0 rgba(0,217,199,0.5);
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .score-bar-content .bar-item:first-child {
  margin-left: 0;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .bar-tips {
  margin-top: 0.266667rem;
  display: flex;
  justify-content: space-between;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .bar-tips .bar-tips-text {
  max-width: 40%;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #afb6ca;
  line-height: 0.453333rem;
}

.psychology-wrapper .psychology-lable-wrapper .score-bar-wrapper .score-bar .bar-introduction {
  margin-top: 0.266667rem;
}