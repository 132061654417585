video {
  width: 100%;
  height: 5.333333rem;
  margin: 0.266667rem 0;
}

.sub-title {
  position: relative;
}

.noWifiTip {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.373333rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5.866667rem;
}

.noWifiTip .text {
  color: #ffffff;
}

.noWifiTip .paly-btn {
  margin-top: 0.266667rem;
  color: #ffffff;
  width: 0.8rem;
}

.noWifiTip .paly-btn img {
  width: 100%;
}

.inner-video-player {
  position: relative;
  width: 100%;
}

.small-tips {
  position: absolute;
  bottom: 20%;
  left: 0;
  color: #ffffff;
  z-index: 10;
  animation-name: enterIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  background-color: rgba(0,0,0,0.5);
}

@keyframes enterIn {
  0% {
    transform: translateX(-1000vw);
  }

  10% {
    transform: translateX(0);
  }

  95% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

video {
  width: 100%;
  height: 5.333333rem;
  margin: 0.266667rem 0;
}

.score {
  padding: 0 0.533333rem;
}

.score div {
  color: #666;
}

.score .test {
  margin: 0.266667rem 0;
  line-height: 0.533333rem;
}

section .gray {
  color: #666666;
}

section .green {
  color: #6ed401;
}

section .red {
  color: #ff0000;
}

section .yellow {
  color: #f4d75e;
}

section .line-20 {
  line-height: 0.533333rem;
}

section .video {
  width: 100%;
  height: 5.333333rem;
  margin: 0.266667rem 0;
}

section pre {
  word-break: break-word;
  white-space: pre-wrap;
}

section /deep/ .van-tabs .van-tabs__line {
  background: #1890ff;
}

section /deep/ .van-tabs .van-tab--active {
  font-weight: 550;
}

section /deep/ .van-tabs .van-tabs__content {
  padding-bottom: 1.066667rem;
}

section .comlun {
  flex-direction: column;
  justify-content: space-between;
}

section /deep/ .obj-pagination {
  justify-content: center;
  position: fixed;
  bottom: 0.533333rem;
  left: 50%;
  transform: translateX(-50%);
}

section /deep/ .obj-pagination .van-pagination__page {
  flex-grow: 1;
}

section /deep/ .obj-pagination li:last-child {
  display: none;
}

section /deep/ .obj-pagination li:first-child {
  display: none;
}

section /deep/ .obj-pagination li {
  cursor: pointer;
  width: 1.066667rem;
  height: 1.066667rem;
  border-radius: 0.08rem;
  margin: 0 0.213333rem;
  border: 0.026667rem solid #ddd;
}

section .video-title {
  padding: 0 0.533333rem;
}

section .obj-title {
  font-size: 0.32rem;
  color: #666666;
}

section .obj-answer {
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  padding: 0.373333rem;
}

section .obj-answer div:first-child {
  color: #333333;
  font-weight: 500;
}

section .sub-answer {
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
  margin-top: 0.266667rem;
}

section .sub-answer div:first-child {
  color: #333333;
  font-weight: 500;
}

section /deep/.van-panel .van-panel__header {
  padding-top: 0.373333rem;
  padding-bottom: 0.373333rem;
}

section /deep/.van-panel .van-panel__header .van-cell__title {
  font-size: 0.4rem;
  color: #333333;
  font-weight: 550;
}

section /deep/.van-panel .van-panel__content {
  padding: 0 0.533333rem;
}

section /deep/ .van-panel::after {
  content: none;
}

section .single-problem-estimate-wrapper {
  margin-top: 0.2rem;
}

section .single-problem-estimate-wrapper .single-problem-estimate {
  border: 0.026667rem solid #cccccc;
  border-radius: 0.133333rem;
  padding: 0.2rem 0.3rem;
}

section .single-problem-estimate-wrapper .single-problem-estimate .title {
  display: flex;
  align-items: center;
}

section .single-problem-estimate-wrapper .single-problem-estimate .title .van-rate {
  margin-left: 0.4rem;
}

section .single-problem-estimate-wrapper .single-problem-estimate /deep/ textarea {
  margin-top: 0.2rem;
  border: 0.026667rem solid #c8c9cc;
  border-radius: 0.106667rem;
  padding: 0.266667rem;
  transform: translateX(0.8);
  transform: translateX(10rpx);
  box-sizing: border-box;
}

section .single-problem-estimate-wrapper .single-problem-estimate .btn-list {
  width: 100%;
  display: flex;
  color: #ffffff;
  margin-top: 0.3rem;
  text-align: center;
}

section .single-problem-estimate-wrapper .single-problem-estimate .btn-list .submit {
  padding: 0.2rem 0;
  width: 2rem;
  background-color: #00aaff;
  border-radius: 0.133333rem;
}

section .single-problem-estimate-wrapper .single-problem-estimate .btn-list .cancle {
  border-radius: 0.133333rem;
  padding: 0.2rem 0;
  width: 2rem;
  margin-left: 0.5rem;
  background-color: #00aaff;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper {
  margin-top: 0.2rem;
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog {
  padding: 0.2rem 0;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line1 {
  display: flex;
  justify-content: space-between;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line1 .left {
  display: flex;
  align-items: center;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line1 .left .name {
  max-width: 1.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line1 .left .dafen {
  margin-left: 0.1rem;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .suggerlog .line2 {
  margin-top: 0.2rem;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .rate-log_item {
  margin-bottom: 0.133333rem;
  display: flex;
  align-items: center;
  position: relative;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .rate-log_item.justify-bt {
  justify-content: space-between;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .rate-log_item .l2-ai {
  display: flex;
  align-items: center;
  margin-right: 0.533333rem;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .rate-log_item .l2-ai .label {
  width: 1.733333rem;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .rate-log_item .l2-ai .huase {
  font-weight: 500;
  color: #FFCA3A;
  margin-left: 0.133333rem;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .rate-log_item .detail {
  color: #1890ff;
  cursor: pointer;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .popover-content .l2-item+.l2-item {
  margin-top: 0.266667rem;
}

section .single-problem-estimate-wrapper .suggerlog-wrapper .popover-content .weight {
  font-weight: 400;
  margin: 0.08rem 0 0.266667rem;
  color: #1890ff;
}

section .question-wrapper /deep/ img {
  max-width: 100%;
}

section .question-wrapper .video {
  max-width: 100%;
}

section .question-wrapper .question-content {
  display: flex;
  font-weight: bold;
  font-size: 0.426667rem;
  color: #333333;
  line-height: 0.64rem;
}

section .detail {
  font-weight: 400;
  font-size: 0.32rem;
  color: #666666;
  line-height: 0.533333rem;
  margin-top: 0.213333rem;
}

section .detail .content-arrow {
  margin-left: 0.16rem;
  transition: transform 0.3s;
}

section .detail .content-arrow.is-expand {
  transform: rotate(180deg);
}

section .popover-content {
  margin-top: 0.32rem;
}

section .popover-content .l2-item+.l2-item {
  margin-top: 0.32rem;
}

section .popover-content .l2-label {
  font-weight: 400;
  font-size: 0.426667rem;
  color: #6D7278;
  line-height: 0.533333rem;
}

section .popover-content .font14 {
  font-size: 0.373333rem;
}

section .popover-content .font14 .van-rate {
  margin-left: 0.133333rem;
}

section .popover-content .mb8 {
  margin-bottom: 0.213333rem;
}

section .popover-content .weight {
  font-weight: 400;
  margin: 0.08rem 0 0.266667rem;
  color: #1890ff;
}

section .manmual-total-esimate {
  padding: 0.6rem 0.4rem;
}

section .manmual-total-esimate .title {
  color: #14b1ff;
  text-align: center;
  font-size: 0.4rem;
  font-weight: 600;
}

section .manmual-total-esimate .desc {
  padding: 0.2rem 0;
  text-align: left;
  font-size: 0.35rem;
}

section .manmual-total-esimate .radio-wrapper {
  margin-top: 0.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

section .manmual-total-esimate .radio-wrapper .radio-item {
  font-size: 0.3rem;
  width: 26vw;
  padding: 0.2rem 0;
  text-align: center;
  border: 0.026667rem solid #dcdfe6;
}

section .manmual-total-esimate .radio-wrapper .active {
  background-color: #14b1ff;
  color: #ffffff;
}

section .manmual-total-esimate .estimate-row {
  margin-top: 0.3rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

section .manmual-total-esimate .estimate-row .van-rate {
  margin-left: 0.2rem;
}

section .manmual-total-esimate .estimate-row .submit-btn {
  cursor: pointer;
  padding: 0.2rem;
  width: 2rem;
  color: #ffffff;
  background-color: #00aaff;
  text-align: center;
  font-size: 0.4rem;
}

section .manmual-total-esimate .textarea-wrapper {
  width: 100%;
  box-sizing: border-box;
}

section .manmual-total-esimate .textarea-wrapper /deep/ textarea {
  margin-top: 0.2rem;
  border: 0.026667rem solid #c8c9cc;
  border-radius: 0.106667rem;
  padding: 0.266667rem;
  transform: translateX(0.8);
  transform: translateX(10rpx);
  box-sizing: border-box;
}

section .manmual-total-esimate .btn-list {
  width: 100%;
  display: flex;
  color: #ffffff;
  margin-top: 0.3rem;
  text-align: center;
}

section .manmual-total-esimate .btn-list .submit {
  padding: 0.2rem 0;
  width: 2rem;
  background-color: #00aaff;
  border-radius: 0.133333rem;
}

section .manmual-total-esimate .btn-list .cancle {
  border-radius: 0.133333rem;
  padding: 0.2rem 0;
  width: 2rem;
  margin-left: 0.5rem;
  background-color: #00aaff;
}

section .manmual-total-esimate .suggerlog-wrapper {
  margin-top: 0.2rem;
  border: 0.026667rem dashed #dddddd;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
}

section .manmual-total-esimate .suggerlog-wrapper .suggerlog {
  padding: 0.2rem 0;
}

section .manmual-total-esimate .suggerlog-wrapper .suggerlog .result-row {
  font-weight: 600;
}

section .manmual-total-esimate .suggerlog-wrapper .suggerlog .line1 {
  margin-top: 0.2rem;
  display: flex;
  justify-content: space-between;
}

section .manmual-total-esimate .suggerlog-wrapper .suggerlog .line1 .left {
  display: flex;
  align-items: center;
}

section .manmual-total-esimate .suggerlog-wrapper .suggerlog .line1 .left .name {
  max-width: 1.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

section .manmual-total-esimate .suggerlog-wrapper .suggerlog .line1 .left .dafen {
  margin-left: 0.1rem;
  font-weight: 600;
}

section .manmual-total-esimate .suggerlog-wrapper .suggerlog .line2 {
  margin-top: 0.2rem;
}

section .manmual-total-esimate .suggerlog-wrapper .suggerlog .line2 .pingjia {
  font-weight: 600;
}