.cell {
  padding-right: 0.533333rem;
  border-bottom: 0.026667rem solid #ededed;
  color: #333333;
  margin-top: 0.426667rem;
}

.cell .font-12 {
  font-size: 0.32rem;
}

.cell .font-light {
  font-weight: 300;
}

.cell .cell-title {
  font-size: 0.32rem;
  display: flex;
  align-items: center;
}

.cell .cell-title div:first-child {
  margin-right: 0.32rem;
  width: 1.333333rem;
}

.cell .cell-title div:last-child {
  flex: 1;
}

.cell .cell-content {
  color: #666666;
  font-size: 0.266667rem;
  margin: 0.266667rem 0;
}

.cell .cell-score {
  color: #1890ff;
  font-size: 0.373333rem;
}

.cell .van-progress {
  border-radius: 0 0.186667rem 0.186667rem 0;
}

.cell .progress-blue /deep/ .van-progress__portion {
  background: linear-gradient(90deg, #35c3ff 0%, #1890ff 100%);
}

.cell .progress-red /deep/ .van-progress__portion {
  background: linear-gradient(90deg, #ffa193 0%, #ff5c44 100%);
}

.cell .progress-green /deep/ .van-progress__portion {
  background: linear-gradient(90deg, #b4f096 0%, #71c74d 100%);
}