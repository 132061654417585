.ai-solution {
  overflow: hidden;
}

.ai-solution .section1 {
  position: relative;
}

.ai-solution .section1 .barnner {
  position: relative;
  max-height: 6.4rem;
  overflow: hidden;
}

.ai-solution .section1 .barnner .banner-img {
  display: block;
  width: 100%;
  height: auto;
}

.ai-solution .section1 .barnner .text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 6.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ai-solution .section1 .barnner .text-wrapper span {
  display: block;
  font-size: 0.64rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.906667rem;
  letter-spacing: 0.08rem;
}

.ai-solution .section2 .content1 {
  padding: 1.253333rem 0 0.72rem;
}

.ai-solution .section2 .content1 .text {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  letter-spacing: 0.053333rem;
  text-align: center;
}

.ai-solution .section2 .content1 .map {
  margin-top: 0.48rem;
}

.ai-solution .section2 .content1 .map img {
  display: block;
}

.ai-solution .section2 .content2 .text-wrapper {
  padding: 1.333333rem 0.666667rem;
  background: #f2f4f8;
}

.ai-solution .section2 .content2 .text-wrapper .text-title {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.48rem;
  text-align: center;
}

.ai-solution .section2 .content2 .text-wrapper .image-box {
  text-align: center;
  width: 82.46%;
  margin: 0 auto;
}

.ai-solution .section2 .content2 .text-wrapper .image-box img {
  display: block;
}

.ai-solution .section2 .content2 .text-wrapper .list-wrapper {
  margin-top: 0.346667rem;
}

.ai-solution .section2 .content2 .text-wrapper .list-wrapper .list-row {
  margin-bottom: 0.266667rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.ai-solution .section2 .content2 .text-wrapper .list-wrapper .list-row:last-child {
  margin: 0;
}

.ai-solution .section2 .content2 .text-wrapper .list-wrapper .list-row .list-col {
  display: inline-block;
  font-size: 0.32rem;
  font-weight: 400;
  width: 45%;
  color: #333333;
  line-height: 0.453333rem;
  letter-spacing: 0.026667rem;
}

.ai-solution .section2 .content2 .text-wrapper .list-wrapper .list-row .list-col::before {
  display: inline-block;
  content: '';
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.106667rem;
  background: url("./images/hook.png");
  background-size: cover;
}

.ai-solution .section-txt {
  padding-top: 1.333333rem;
}

.ai-solution .section-txt>.title {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  text-align: center;
}

.ai-solution .section-txt .conet-wrapper {
  padding-top: 0.666667rem;
  text-align: center;
}

.ai-solution .section-txt .conet-wrapper .text-wrapper {
  padding-top: 0.133333rem;
}

.ai-solution .section-txt .conet-wrapper .text-wrapper .g-text-box {
  padding-top: 0.533333rem;
}

.ai-solution .section-txt .conet-wrapper .text-wrapper .g-text-box>p {
  margin: 0;
}

.ai-solution .section-txt .conet-wrapper .text-wrapper .g-text-box .label {
  font-size: 0.373333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 0.533333rem;
  color: #a5acbd;
  margin-bottom: 0.266667rem;
}

.ai-solution .section-txt .conet-wrapper .text-wrapper .g-text-box .desc {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.586667rem;
}