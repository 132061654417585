.resume-tags {
  display: flex;
  align-items: baseline;
  margin-top: 0.266667rem;
}

.resume-tags .left {
  width: fit-content;
  white-space: nowrap;
}

.resume-tags .labels {
  flex: auto;
  display: flex;
  justify-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  padding-right: 0.746667rem;
}

.resume-tags .labels .more {
  position: absolute;
  right: 0;
  top: 0.08rem;
  cursor: pointer;
}

.resume-tags .labels .label-tag .label-name {
  border-radius: 0.08rem;
  border: 0.026667rem solid #8996bf;
  padding: 0.053333rem 0.266667rem;
  margin-bottom: 0.133333rem;
  margin-right: 0.133333rem;
  display: flex;
  align-items: center;
  min-height: 0.373333rem;
}

.resume-tags .labels .label-tag .label-name span {
  font-size: 0.32rem;
}

.resume-tags .labels .label-tag .red-tag {
  border-color: #ff4848;
  color: #ff4848;
}

.resume-tags .labels .isExpand {
  opacity: 1;
}

.resume-tags .labels .isUnexpand {
  opacity: 0;
}