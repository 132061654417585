.general-resume .container {
  padding: 0 0.2rem;
}

.general-resume .title {
  display: flex;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
  align-items: center;
}

.general-resume .title .title-prefix {
  margin-right: 0.213333rem;
  width: 0.106667rem;
  height: 0.426667rem;
  background: #d8d8d8;
}

.general-resume .content {
  padding: 0.266667rem;
  font-size: 0.373333rem;
}

.general-resume .content .content-title {
  margin-right: 0.213333rem;
}

.general-resume .content .content-col {
  margin-bottom: 0.213333rem;
}

.general-resume .content .content-col span {
  word-break: break-all;
}

.general-resume .complex-content {
  margin-top: 0.533333rem;
  margin-bottom: 0.533333rem;
}

.general-resume .complex-content .complex-title {
  line-height: 0.853333rem;
  min-height: 0.853333rem;
  background: #f4f4f6;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.general-resume .complex-content .complex-title .complex-title-time {
  font-size: 0.373333rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
  width: 4rem;
  margin-left: 0.533333rem;
  margin-right: 0.533333rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
}

.general-resume .complex-content .complex-title .complex-title-text {
  font-size: 0.373333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.853333rem;
  width: 100%;
  padding-right: 0.24rem;
}

.general-resume .complex-content .complex-title .complex-title-text .school_rank {
  color: #ffffff;
  font-size: 0.373333rem;
  font-family: ArialMT;
  color: #ffffff;
  background: linear-gradient(180deg, #7eeece 0%, #0f7dff 100%);
  border-radius: 0.053333rem;
  line-height: 0.426667rem;
  margin-left: 0.373333rem;
  padding: 0 0.213333rem 0 0.133333rem;
}

.general-resume .complex-content .complex-title .complex-title-text .school_rank .school_top {
  font-size: 0.213333rem;
  font-weight: 400;
}

.general-resume .complex-content .complex-title .complex-title-text .school_rank .school_num {
  font-style: italic;
}

.general-resume .complex-content .complex-content-body {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding-left: 0.32rem;
}

.general-resume .complex-content .complex-content-body .body-title {
  text-align: left;
  padding: 0.213333rem 0 0.213333rem 0.266667rem;
  margin-right: 0.533333rem;
  width: 2.4rem;
}

.general-resume .complex-content .complex-content-body .body-content {
  flex: 1;
  padding: 0.213333rem 0;
  white-space: pre-line;
  word-break: break-word;
}