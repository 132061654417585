.five-year-dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.25);
}

.five-year-dialog .img {
  width: 8.933333rem;
  height: 10.666667rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.five-year-dialog .img img {
  width: 100%;
}

.five-year-dialog .img .close-btn {
  position: absolute;
  cursor: pointer;
  font-size: 0.426667rem;
  padding: 0.213333rem;
  border-radius: 50%;
  background: rgba(0,0,0,0.45);
  color: #ffffff;
  font-weight: 600;
  bottom: -1.066667rem;
}