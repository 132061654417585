.edition-contrast {
  margin: auto;
}

.edition-contrast .headers {
  display: flex;
}

.edition-contrast .headers .header {
  height: 1.76rem;
  width: 9.066667rem;
  padding: 0 0.133333rem 0 0.4rem;
  background: rgba(25,144,255,0.06);
  font-size: 0.373333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.746667rem;
  display: flex;
  align-items: center;
}

.edition-contrast .headers .header1 {
  width: 7.2rem;
  text-align: center;
  padding: 0;
  justify-content: center;
}

.edition-contrast .headers .header+.header {
  margin-left: 0.133333rem;
}

.edition-contrast .moudles .module .m-header {
  display: flex;
  height: 1.706667rem;
  margin: auto;
}

.edition-contrast .moudles .module .l-item {
  display: flex;
  height: 1.28rem;
  -webkit-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
}

.edition-contrast .moudles .module .l-item .item {
  width: 7.2rem;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edition-contrast .moudles .module .l-item .l-label {
  width: 9.066667rem;
  background: #ffffff;
  justify-content: left;
  padding: 0 0.133333rem 0 0.4rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #666666;
}

.edition-contrast .moudles .module .l-item .m-title {
  font-size: 0.32rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  border-bottom: 0.026667rem solid #eeeeee;
}

.edition-contrast .moudles .module .l-item .mb {
  margin-bottom: 0.106667rem;
}

.edition-contrast .moudles .module .l-item .item+.item {
  margin-left: 0.133333rem;
}

.edition-contrast .moudles .module .l-item:hover {
  background: #ffffff;
  transform: scale(1, 1.1);
  box-shadow: 0 0.053333rem 0.426667rem 0 rgba(0,35,67,0.08);
}

.edition-contrast .moudles .module .l-item:hover .m-title {
  border: 0;
}

.edition-contrast .moudles .module .l-item:hover .item {
  background: #ffffff;
}