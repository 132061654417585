.empty_page {
  color: #c5c5c5;
  font-size: 0.426667rem;
  user-select: none;
  text-align: center;
}

.empty_page .no-data-img {
  padding-top: 2.293333rem;
  margin: 0 auto;
  width: 4.8rem;
}

.empty_page .no-data-img img {
  width: 100%;
}