.feedback-wrapper .feedback-body {
  padding: 0 0.64rem;
  height: calc(100vh - 3.146667rem);
  overflow-y: scroll;
  overflow-x: hidden;
  background: #f3f4f6;
}

.feedback-wrapper .feedback-body .module {
  box-shadow: 0 0.053333rem 0.32rem 0 rgba(0,0,0,0.16);
  padding: 0.373333rem;
  margin: 0.266667rem 0;
  border-radius: 0.133333rem;
  background: #ffffff;
}

.feedback-wrapper .feedback-body .module .module-header {
  display: flex;
  align-items: center;
  min-height: 0.533333rem;
  padding-bottom: 0.266667rem;
  margin-bottom: 0.266667rem;
  border-bottom: 0.026667rem dashed #333;
}

.feedback-wrapper .feedback-body .module .module-header .module-widget {
  height: 0.426667rem;
  border-left: 0.133333rem solid #0c8cf6;
}

.feedback-wrapper .feedback-body .module .module-header .module-title {
  margin-left: 0.266667rem;
  font-size: 0.426667rem;
  font-weight: bold;
  color: #121316;
  width: 3.066667rem;
  flex-grow: 1;
  word-break: break-all;
  white-space: pre-wrap;
}

.feedback-wrapper .feedback-body .module .module-header .module-score {
  display: flex;
  align-self: flex-end;
  align-items: center;
}

.feedback-wrapper .feedback-body .module .module-description {
  color: #89909e;
  word-break: break-all;
  white-space: pre-wrap;
}

.feedback-wrapper .feedback-body .module .module-question {
  margin: 0.133333rem 0;
}

.feedback-wrapper .feedback-body .module .module-question .question-title {
  font-size: 0.373333rem;
  color: #292c32;
  font-weight: bold;
}

.feedback-wrapper .feedback-body .module .module-question .required {
  margin-left: 0.133333rem;
  font-size: 0.373333rem;
  color: #f56c6c;
}

.feedback-wrapper .feedback-body .module .module-question .question-description {
  margin: 0.133333rem 0;
  color: #89909e;
  word-break: break-all;
  white-space: pre-wrap;
}

.feedback-wrapper .feedback-body .module .module-question .option-inline {
  margin-bottom: 0.213333rem;
  margin-right: 0.533333rem;
}

.feedback-wrapper .feedback-body .module .module-question .interviewResult {
  margin-top: 0.133333rem;
  font-size: 0.373333rem;
  padding: 0.133333rem;
}

.feedback-wrapper .feedback-body .module .module-question .feedbackContent /deep/ textarea {
  margin-top: 0.2rem;
  border: 0.026667rem solid #c8c9cc;
  border-radius: 0.106667rem;
  padding: 0.266667rem;
  transform: translateX(0.8);
  transform: translateX(10rpx);
  box-sizing: border-box;
}

.feedback-wrapper .feedback-body .module .module-question .questionAnswer /deep/ textarea {
  margin-top: 0.2rem;
  border: 0.026667rem solid #c8c9cc;
  border-radius: 0.106667rem;
  padding: 0.266667rem;
  transform: translateX(0.8);
  transform: translateX(10rpx);
  box-sizing: border-box;
}

.feedback-wrapper .feedback-body .module .module-question .questionAnswer .van-cell {
  padding: 0.133333rem;
}

.feedback-wrapper .feedback-body .module .module-question .questionAnswer .van-checkbox-group .van-checkbox {
  margin-top: 0.266667rem;
}

.feedback-wrapper .feedback-body .module .module-question .questionAnswer .van-radio-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.feedback-wrapper .feedback-footer {
  padding: 0.266667rem 0.64rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f3f4f6;
  height: 1.546667rem;
}

.feedback-wrapper .feedback-footer .van-button {
  height: 0.96rem;
  flex: 1;
}

.feedback-wrapper .feedback-footer .van-button:first-child {
  margin-right: 0.533333rem;
}