.price-main {
  color: #333333;
  background: #ffffff;
  background-image: url(~@/assets/price/h5_price_banner_bg@2x.png);
  background-size: 100% 14.4rem;
  background-repeat: no-repeat;
}

.price-main .price-title {
  font-size: 0.746667rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-align: center;
  margin-bottom: 0.426667rem;
  padding-top: 1.866667rem;
}

.price-main .tip {
  font-size: 0.426667rem;
  color: #333333;
  line-height: 0.853333rem;
  text-align: center;
  margin-bottom: 1.28rem;
}

.price-main .price-edition {
  margin-bottom: 1.6rem;
}

.price-main .price-edition .edition {
  background: #ffffff;
  box-shadow: 0 0.16rem 0.533333rem 0.133333rem rgba(40,120,255,0.1),0 0.426667rem 0.64rem 0.053333rem rgba(0,0,0,0.05);
  border-radius: 0.106667rem;
  margin: 0 0.266667rem;
  padding-bottom: 0.533333rem;
  -webkit-transition: all ease 300ms;
  -o-transition: all ease 300ms;
  transition: all ease 300ms;
}

.price-main .price-edition .edition .edition-header {
  color: #ffffff;
  height: 5.013333rem;
  border-radius: 0.106667rem 0.106667rem 0 0;
  position: relative;
}

.price-main .price-edition .edition .edition-header .edition-title {
  font-size: 0.853333rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.066667rem;
  margin-bottom: 0.373333rem;
  padding: 0 0.8rem;
  padding-top: 0.853333rem;
  text-align: center;
}

.price-main .price-edition .edition .edition-header .edition-tip {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.64rem;
  padding: 0 0.8rem;
}

.price-main .price-edition .edition .edition-header .edition-jiaobiao {
  position: absolute;
  top: -0.026667rem;
  right: -0.026667rem;
}

.price-main .price-edition .edition .edition-action {
  text-align: center;
}

.price-main .price-edition .edition .edition-action .price {
  height: 1.28rem;
  font-size: 0.426667rem;
  font-weight: 400;
  color: #333333;
  line-height: 1.28rem;
  margin-top: 0.853333rem;
  margin-bottom: 0.32rem;
}

.price-main .price-edition .edition .edition-action .price .eprice {
  font-size: 0.853333rem;
  font-weight: 600;
}

.price-main .price-edition .edition .edition-action .price-tip {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.533333rem;
  margin-bottom: 0.64rem;
}

.price-main .price-edition .edition .edition-action .pirce-btn {
  width: 5.333333rem;
  height: 1.28rem;
  cursor: pointer;
  background: linear-gradient(270deg, #36c3ff 0%, #1990ff 100%);
  border-radius: 0.64rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin: auto;
}

.price-main .price-edition .edition .edition-action .pirce-btn img {
  margin-left: 0.213333rem;
}

.price-main .price-edition .edition .edition-action .line {
  height: 0.026667rem;
  margin: 1.066667rem 0.8rem 0.64rem 0.8rem;
  background: #eeeeee;
}

.price-main .price-edition .edition .edition-content {
  margin-bottom: 0.426667rem;
}

.price-main .price-edition .edition .edition-content .item {
  display: flex;
  justify-content: space-between;
  line-height: 0.64rem;
  font-size: 0.373333rem;
  font-weight: 400;
  padding: 0 0.266667rem 0 0.266667rem;
}

.price-main .price-edition .edition .edition-content .item .label {
  color: #555555;
  text-align: left;
  white-space: nowrap;
  margin-right: 0.266667rem;
  line-height: 0.64rem;
}

.price-main .price-edition .edition .edition-content .item .value {
  color: #333333;
  text-align: right;
  line-height: 0.64rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-main .price-edition .edition .edition-content .item+.item {
  margin-top: 0.266667rem;
}

.price-main .price-edition .edition .edition-explain {
  background: #fafafa;
  border-radius: 0.053333rem;
  margin: 0 0.266667rem;
}

.price-main .price-edition .edition .edition-explain .ee-title {
  font-size: 0.373333rem;
  font-weight: 600;
  color: #666666;
  line-height: 0.533333rem;
  padding: 0.32rem 0.4rem 0.133333rem 0.4rem;
}

.price-main .price-edition .edition .edition-explain .ee-info {
  font-size: 0.32rem;
  color: #666666;
  line-height: 0.533333rem;
  padding: 0 0.4rem 0.133333rem 0.4rem;
}

.price-main .price-edition .edition .edition-other {
  text-align: center;
  cursor: pointer;
  font-size: 0.32rem;
  font-weight: 400;
  color: #1990ff;
  line-height: 0.533333rem;
  margin-bottom: 0.853333rem;
}

.price-main .price-edition .edition+.edition {
  margin-top: 0.533333rem;
}

.price-main .show-edition {
  width: 100%;
  height: 1.6rem;
  background: #e8f3ff;
  border-radius: 0.106667rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.price-main .show-edition img {
  margin-right: 0.213333rem;
}