/deep/.el-drawer__close-btn {
  outline: none;
}

.drawer-title {
  padding: 0.32rem 0.64rem 0.32rem 0.64rem;
  font-size: 0.64rem;
  font-weight: bold;
  color: #121316;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6rem;
  line-height: 1.6rem;
}

/deep/.el-drawer__body {
  overflow-y: auto;
  overflow-x: hidden;
}