.online-test .module-radio {
  background: #f2f2f2;
  width: 100%;
  height: 1.28rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.online-test .module-radio .module-type {
  text-align: center;
  width: 2.4rem;
  height: 0.746667rem;
  line-height: 0.746667rem;
  color: #6d7278;
  border-radius: 0.133333rem;
  border: 0.026667rem solid #dddddd;
  cursor: pointer;
  background: #f6f7f8;
}

.online-test .module-radio .active {
  color: #1890ff;
  border: 0.026667rem solid #1890ff;
  font-weight: 600;
}

.online-test .module-radio .module-type:not(:first-child) {
  margin-left: 0.64rem;
}